import { useUSDAToken } from 'hooks/useToken'
import { useSupportedTokens } from 'hooks/useToken'
import { useUserRedeemRequests } from 'hooks/useUser'

import Item from './Item'

import styles from './styles.module.scss'

const RedeemRequests = () => {
  const usdaToken = useUSDAToken()
  const tokens = useSupportedTokens()
  const redeemRequests = useUserRedeemRequests()

  if (redeemRequests.length === 0) {
    return <div className={styles.noData}>No Redeem Requests</div>
  }

  return (
    <div className={styles.list}>
      {redeemRequests.map(req => {
        const collateralToken = tokens.filter(t => !!t).find(t => t.address.toLowerCase() === req.details.collateral_asset.toLowerCase())
        if (!collateralToken) {
          return null
        }

        return (
          <Item
            key={req.request_id}
            item={req}
            collateralToken={collateralToken}
            usdaToken={usdaToken}
          />
        )
      })}
    </div>
  )
}

export default RedeemRequests
