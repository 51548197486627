import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface UserState {
  authToken: string | null
  balanceNative: string
  details: any
}

export const initialState: UserState = {
  authToken: null,
  balanceNative: '0',
  details: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthToken(state, action: PayloadAction<string | null>) {
      state.authToken = action.payload
      if (action.payload === null) {
        state.balanceNative = '0'
        state.details = null
      }
    },
    setUserDetails(state, action: PayloadAction<any>) {
      state.details = action.payload
    },
    setBalanceNative(state, action: PayloadAction<string>) {
      state.balanceNative = action.payload
    },
  },
})

export const {
  setAuthToken,
  setUserDetails,
  setBalanceNative,
} = userSlice.actions

export default persistReducer(
  {
    key: 'user',
    storage,
    whitelist: ['authToken'],
  },
  userSlice.reducer,
)
