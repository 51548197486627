import { useActiveChainId } from 'state/network/hooks'
import {
  useUSDAToken,
  useSupportedTokens,
} from 'hooks/useToken'

import { isSupportedChain } from 'web3/utils'

import Tabs from 'components/Tabs'
import CardUi from 'components/UI/CardUi'
import MainContainerUi from 'components/UI/MainContainerUi'
import UnsupportedNetwork from 'components/UnsupportedNetwork'

import CurveForm from './CurveForm/Index'
import UniswapForm from './UniswapForm'

import styles from './styles.module.scss'

const Swap = () => {
  const chainId = useActiveChainId()
  const usdaToken = useUSDAToken()
  const supportedTokens = useSupportedTokens()

  const tabData = [
    {
      title: 'Uniswap',
      Component: UniswapForm,
      props: {
        usdaToken,
        supportedTokens,
      },
    },
    {
      title: 'Curve',
      Component: CurveForm,
      props: {
        usdaToken,
        supportedTokens,
      },
    },
  ]

  return (
    <MainContainerUi>
      <h1>Swap</h1>
      <div className={styles.wrapper}>
        {isSupportedChain(chainId) ? (
          <CardUi>
            <div className={styles.tabWrapper}>
              <Tabs tabs={tabData} activeTabBorderRadius={18} padding={0} />
            </div>
          </CardUi>
        ) : (
          <UnsupportedNetwork />
        )}
      </div>
    </MainContainerUi>
  )
}

export default Swap