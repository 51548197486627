import { useState, useEffect, useCallback } from 'react'

import StatsService from 'API/StatsService'

import type {
  Interval,
  APRChartData,
  SeriesData,
  ExchangeFundingRateChartData,
  BTCMarginStat,
  BTCFundingRateStats,
  USDaChartData,
} from '@types'

export function useBTCMarginRateStats() {
  const [data, setData] = useState<BTCFundingRateStats>({oiFundingRate: '0.0', volumeFundingRate: '0.0'})

  useEffect(() => {
    async function fetchData() {
      const data = await StatsService.fetchBTCFundingRateStatistics()
      setData(data)
    }

    fetchData()
  }, [])

  return data
}

export function useBTCMarginStats() {
  const [data, setData] = useState<BTCMarginStat[]>([])

  useEffect(() => {
    async function fetchData() {
      const data = await StatsService.fetchBTCMarginStatistics()
      setData(data)
    }

    fetchData()
  }, [])

  return data
}

export function useAPRChartData() {
  const [data, setData] = useState<APRChartData[]>([])

  useEffect(() => {
    async function fetchData() {
      const data = await StatsService.fetchAprChartData()
      setData(data)
    }

    fetchData()
  }, [])

  return data
}

export function useUSDaSupplyChartData() {
  const [data, setData] = useState<USDaChartData[]>([])

  useEffect(() => {
    async function fetchData() {
      const data = await StatsService.fetchUSDaSupplyChartData()
      setData(data)
    }

    fetchData()
  }, [])

  return data
}

export function useUSDaPriceChartData() {
  const [data, setData] = useState<USDaChartData[]>([])

  useEffect(() => {
    async function fetchData() {
      const data = await StatsService.fetchUSDaPriceChartData()
      setData(data)
    }

    fetchData()
  }, [])

  return data
}

export function useReserveFundChartData() {
  const [data, setData] = useState<USDaChartData[]>([])

  useEffect(() => {
    async function fetchData() {
      const data = await StatsService.fetchReserveFundChartData()
      setData(data)
    }

    fetchData()
  }, [])

  return data
}

export function useOiWeightData(interval: Interval = '4h') {
  const [data, setData] = useState<SeriesData[]>([])

  const fetchOiWeightData = useCallback(async () => {
    const data = await StatsService.fetchOiWeightChartData(interval)
    setData(data)
  }, [interval])

  useEffect(() => {
    fetchOiWeightData()
  }, [fetchOiWeightData])

  return data
}

export function useVolumeWeightData(interval: Interval = '4h') {
  const [data, setData] = useState<SeriesData[]>([])

  const fetchVolumeWeightData = useCallback(async () => {
    const data = await StatsService.fetchVolumeWeightChartData(interval)
    setData(data)
  }, [interval])

  useEffect(() => {
    fetchVolumeWeightData()
  }, [fetchVolumeWeightData])

  return data
}

export function useExchangeRates() {
  const [data, setData] = useState<ExchangeFundingRateChartData>({})

  const fetchExchangeRates = useCallback(async () => {
    const data = await StatsService.fetchExchangeRatesChartData()
    setData(data)
  }, [])

  useEffect(() => {
    fetchExchangeRates()
  }, [fetchExchangeRates])

  return data
}