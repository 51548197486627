import { useUSDaSupplyChartData } from 'hooks/useStats'

import CardUi from 'components/UI/CardUi'
import SupplyChart from 'components/Charts/SupplyChart'

import styles from '../styles.module.scss'

const USDaSupplyChart = () => {
  const data = useUSDaSupplyChartData()

  return (
    <div className={styles.supplyWrapper}>
      <CardUi>
        <p className={styles.statHeader}>USDa Supply</p>
        <SupplyChart data={data} />
      </CardUi>
    </div>
  )
}

export default USDaSupplyChart
