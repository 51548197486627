import { useState, useCallback, ChangeEvent } from 'react'
import { useUserDetails } from 'state/user/hooks'

import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import SuccessUi from 'components/UI/SuccessUi'
import SendNewConfirmationCode from 'components/SendNewConfirmationCode'
import SendCurrentConfirmationCode from 'components/SendCurrentConfirmationCode'

import useForm from 'hooks/useForm'
import UserService from 'API/UserService'
import changeEmailValidator from 'validators/changeEmailValidator'

const ChangeEmail = () => {
  const userDetails = useUserDetails()
  const initialFormState = {
    newEmail: '',
    newEmailCode: '',
    currentEmailCode: '',
    code: '',
  }

  const { values, errors, handleChange, validate } = useForm(initialFormState, changeEmailValidator)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e)
    setError(null)
  }, [handleChange])

  const validateForm = () => {
    const isValid = validate()
    if (!isValid) {
      setError('Please fix the errors in the form.')
    }
    return isValid
  }

  const handleSubmit = async () => {
    if (!validateForm()) return

    setIsLoading(true)

    try {
      const { newEmail, newEmailCode, currentEmailCode, code } = values
      const response = await UserService.changeEmail({
        currentEmailConfirmationCode: currentEmailCode,
        newEmailConfirmationCode: newEmailCode,
        newEmail,
        code: userDetails.twofa_enabled ? code : undefined,
      })

      if (response.status === 'success') {
        setIsSuccess(true)
      } else {
        setError('Failed to change email')
      }
    } catch (err) {
      console.error(err)
      setError(`Failed to change email ${JSON.stringify(err)}`)
    } finally {
      setIsLoading(false)
    }
  }

  if (isSuccess) {
    return (
      <SuccessUi
        text="Your email has been successfully changed."
        link="/dashboard"
        linkText=" "
      />
    )
  }

  return (
    <div>
      <InputUi
        label="Please enter your new Email"
        name="newEmail"
        value={values.newEmail}
        type="email"
        placeholder="New Email"
        error={errors.newEmail}
        onChange={handleInputChange}
      />
      <InputUi
        label="New Email Verification Code"
        name="newEmailCode"
        value={values.newEmailCode}
        type="text"
        placeholder="New email verification code"
        error={errors.newEmailCode}
        onChange={handleInputChange}
      />
      <SendNewConfirmationCode email={values.newEmail} />
      <InputUi
        label={`A verification code will be sent to ${userDetails.email}`}
        name="currentEmailCode"
        value={values.currentEmailCode}
        type="text"
        placeholder="Current email verification code"
        error={errors.currentEmailCode}
        onChange={handleInputChange}
      />
      <SendCurrentConfirmationCode />
      {userDetails.twofa_enabled && (
        <InputUi
          label="Google 2FA Code"
          name="code"
          value={values.code}
          type="text"
          placeholder="Google 2FA code"
          error={errors.code}
          onChange={handleInputChange}
        />
      )}
      {error && <ErrorBannerUi text={error} />}
      <SubmitBtnUi
        text="Submit"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={handleSubmit}
      />
    </div>
  )
}

export default ChangeEmail
