import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'
import RedeemForm from './RedeemForm'
import styles from './styles.module.scss'
import { useSupportedTokens, useUSDAToken } from 'hooks/useToken'
import { useUserRedeemRequests } from 'hooks/useUser'
import RedeemStats from './RedeemStats'

const Redeem = () => {
  const usdaToken = useUSDAToken()
  const tokens = useSupportedTokens()
  const redeemRequests = useUserRedeemRequests()

  const foundRequest = redeemRequests.find(
    (req) => req.status === 'waiting_deposit' || req.status === 'deposited',
  )

  if (!foundRequest) {
    return (
      <MainContainerUi>
        <h1>Redeem</h1>
        <div className={styles.wrapper}>
          <CardUi>
            <div className={styles.formWrapper}>
              <RedeemForm />
            </div>
          </CardUi>
        </div>
      </MainContainerUi>
    )
  }

  const collateralToken = tokens.filter(t => !!t).find(
    t => t.address.toLowerCase() === foundRequest.details.collateral_asset.toLowerCase(),
  )

  if (!collateralToken) {
    return null
  }

  return (
    <MainContainerUi>
      <h1>Redeem</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.formWrapper}>
            <RedeemStats
              key={foundRequest.request_id}
              item={foundRequest}
              collateralToken={collateralToken}
              usdaToken={usdaToken}
            />
          </div>
        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default Redeem
