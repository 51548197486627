const changeNotificationEmailValidator = {
  email: {
    required: true,
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: 'Invalid email format',
  },
  confirmationCode: {
    required: true,
    errorMessage: 'New email verification code is required',
  },
}

export default changeNotificationEmailValidator