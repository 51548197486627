import { useState, useCallback, ChangeEvent } from 'react'
import { useUserDetails } from 'state/user/hooks'

import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import SuccessUi from 'components/UI/SuccessUi'
import SendNotificationEmailCode from 'components/SendNotificationEmailCode'

import useForm from 'hooks/useForm'
import UserService from 'API/UserService'
import changeNotificationEmailValidator from 'validators/changeNotificationEmailValidator'

const ChangeNotificationEmail = () => {
  const userDetails = useUserDetails()
  const initialFormState = {
    email: '',
    confirmationCode: '',
    code: '',
  }

  const { values, errors, handleChange, validate } = useForm(initialFormState, changeNotificationEmailValidator)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e)
    setError(null)
  }, [handleChange])

  const validateForm = () => {
    const isValid = validate()
    if (!isValid) {
      setError('Please fix the errors in the form.')
    }
    return isValid
  }

  const handleSubmit = async () => {
    if (!validateForm()) return

    setIsLoading(true)

    try {
      const { email, confirmationCode, code } = values
      const response = await UserService.changeNotificationEmail({
        email,
        confirmationCode,
        code: userDetails.twofa_enabled ? code : undefined,
      })

      if (response.status === 'success') {
        setIsSuccess(true)
      } else {
        setError('Failed to change email')
      }
    } catch (err) {
      console.error(err)
      setError('Failed to change email')
    } finally {
      setIsLoading(false)
    }
  }

  if (isSuccess) {
    return (
      <SuccessUi
        text="Your email has been successfully added."
        link="/dashboard"
        linkText=" "
      />
    )
  }

  return (
    <div>
      <InputUi
        label="Please enter your new Email"
        name="email"
        value={values.email}
        type="email"
        placeholder="Email"
        error={errors.email}
        onChange={handleInputChange}
      />
      <SendNotificationEmailCode email={values.email} />
      <InputUi
        label="Email Verification Code"
        name="confirmationCode"
        value={values.confirmationCode}
        type="text"
        placeholder="Email verification code"
        error={errors.confirmationCode}
        onChange={handleInputChange}
      />

      {userDetails.twofa_enabled && (
        <InputUi
          label="Google 2FA Code"
          name="code"
          value={values.code}
          type="text"
          placeholder="Google 2FA code"
          error={errors.code}
          onChange={handleInputChange}
        />
      )}
      {error && <ErrorBannerUi text={error} />}
      <SubmitBtnUi
        text="Submit"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={handleSubmit}
      />
    </div>
  )
}

export default ChangeNotificationEmail
