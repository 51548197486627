const resetPasswordValidator = {
  newPassword: {
    required: true,
    minLength: 6,
    errorMessage: 'Password must be at least 6 characters long',
  },
  confirmPassword: {
    required: true,
    custom: (values: any) => values.newPassword === values.confirmPassword,
    errorMessage: 'Passwords must match',
  },
}

export default resetPasswordValidator