import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'

import MintForm from './MintForm'

import styles from './styles.module.scss'

const Mint = () => {
  return (
    <MainContainerUi>
      <h1>Mint</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.formWrapper}>
            <MintForm />
          </div>
        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default Mint