import { USDA_TOKEN, USDT_TOKEN, USDC_TOKEN, DAI_TOKEN, AEGIS_MINTING_ADDRESS, HOLDING_REWARDS_ADDRESS } from 'constants/addresses'
import { ChainId } from 'web3/chains'

export const getTokenNameByAddress = (address: string | undefined, chainId: ChainId): string | undefined => {
  if (!address) return undefined

  const tokens = [USDA_TOKEN, USDT_TOKEN, USDC_TOKEN, DAI_TOKEN]

  for (const tokenGroup of tokens) {
    const token = tokenGroup[chainId]
    if (token && token.address && token.address.toLowerCase() === address.toLowerCase()) {
      return token.name
    }
  }

  return undefined
}

export const getTokenSymbolByAddress = (address: string | undefined, chainId: ChainId): string | undefined => {
  if (!address) return undefined

  const tokens = [USDA_TOKEN, USDT_TOKEN, USDC_TOKEN, DAI_TOKEN]

  for (const tokenGroup of tokens) {
    const token = tokenGroup[chainId]
    if (token && token.address && token.address.toLowerCase() === address.toLowerCase()) {
      return token.symbol
    }
  }

  return undefined
}

export const getTokenDecimalsByAddress = (address: string | undefined, chainId: ChainId): number | undefined => {
  if (!address) return undefined

  const tokens = [USDA_TOKEN, USDT_TOKEN, USDC_TOKEN, DAI_TOKEN]

  for (const tokenGroup of tokens) {
    const token = tokenGroup[chainId]
    if (token && token.address && token.address.toLowerCase() === address.toLowerCase()) {
      return token.decimals
    }
  }

  return undefined
}

export const getMinterAddress = (chainId: ChainId): string | undefined => {
  return AEGIS_MINTING_ADDRESS[chainId]
}

export const getRewardAddress = (chainId: ChainId): string | undefined => {
  return HOLDING_REWARDS_ADDRESS[chainId]
}