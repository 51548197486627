import { useState, ChangeEvent } from 'react'

import UserService from 'API/UserService'

import BackLinkUi from 'components/UI/BackLinkUi'
import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import Stepper from 'components/UI/Stepper'

import styles from './styles.module.scss'

interface Props {
  onNextStep: () => void;
  onPrevStep: () => void;
}

const Step4 = ({ onPrevStep, onNextStep }: Props) => {
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
    setError(null)
  }

  const handleSubmit = async () => {
    if (!code) {
      setError('Code cannot be empty')
      return
    }

    setIsLoading(true)

    try {
      const response = await UserService.enable2Fa({ code })

      if (response.status === 'success') {
        onNextStep()
      } else {
        setError('Invalid code')
      }
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      <Stepper totalSteps={4} currentStep={4} />
      <h6>Verify Google Authenticator account</h6>
      <div className={styles.formWrapper}>
        <InputUi
          label="Google 2FA Code"
          name="code"
          value={code}
          type="text"
          placeholder="Enter Authenticator verification code"
          error={error}
          onChange={handleCodeChange}
        />
        <SubmitBtnUi onClick={handleSubmit} text="Next" isLoading={isLoading} disabled={isLoading} />
        <BackLinkUi onClick={onPrevStep} text="Back" />
      </div>
    </div>
  )
}

export default Step4
