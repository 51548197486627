import React, { useState } from 'react'
import styles from './styles.module.scss'
import COPY_ICON_URL from 'assets/images/icons/copy.svg'

interface Props {
  text: string;
}

const CopierUi = ({ text }: Props) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    })
  }

  return (
    <div className={styles.wrapper} onClick={handleCopy}>
      <div className={styles.text}>{text}</div>
      <div className={styles.imgWrapper}>
        <img src={COPY_ICON_URL} alt="" />
      </div>

      {copied && <div className={styles.copied}>Copied</div>}
    </div>
  )
}

export default CopierUi
