import api from './api'

const TestingService = {
  fetchRedeemRequests: async () => {
    const { data } = await api.get('/testing/redeem-requests')

    return data
  },
  fetchHoldingSnapshots: async () => {
    const { data } = await api.get('/testing/holding-snapshots')

    return data
  },
  depositIncome: async (sender: string, snapshotId: string, asset: string, amount: string) => {
    const { data } = await api.post('/testing/deposit-income', { sender, snapshot_id: snapshotId, collateral_asset: asset, collateral_amount: amount })

    return data
  },
  finalizeHoldingSnapshot: async (id: string) => {
    const { data } = await api.post('/testing/finalize-holding-snapshot', { snapshot_id: id })

    return data
  },
  getTestETH: async (address: string) => {
    const { data } = await api.post('/testing/get-eth', { address })

    return data
  },
}

export default TestingService
