import { Interface } from '@ethersproject/abi'

import CURVE_MATH_ABI from './curveMath.json'

const CURVE_MATH_INTERFACE = new Interface(CURVE_MATH_ABI)

export {
  CURVE_MATH_ABI,
  CURVE_MATH_INTERFACE,
}
