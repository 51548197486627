import { useState, useRef } from 'react'
import cx from 'classnames'

import { useOusideClick } from 'hooks/useOutsideClick'
import { Token } from 'entities/token'

import TokenButton from 'components/UI/TokenButton'

import styles from './styles.module.scss'

interface Props {
  selected: Token
  tokens: Token[]
  onSelect: (token: Token) => void
}

const TokenSelect = ({ selected, tokens, onSelect }: Props) => {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  useOusideClick(ref, () => setOpen(false))

  const handleSelect = (token: Token) => {
    onSelect(token)
    setOpen(false)
  }

  return (
    <div ref={ref} className={cx(styles.select, {[styles.open]: open})}>
      <div className={styles.selected} onClick={() => setOpen(!open)}>
        <TokenButton token={selected} selectable />
      </div>
      <div className={styles.dropdown}>
        {tokens.map(token => (
          <div key={token.symbol} className={styles.option} onClick={() => handleSelect(token)}>
            <TokenButton token={token} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default TokenSelect
