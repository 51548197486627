import { useNavigate } from 'react-router-dom'

import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'
import ConnectButtons from 'components/ConnectButtons'

import styles from './styles.module.scss'

const ConnectWallet = () => {
  const navigate = useNavigate()

  const handleWalletAuthSuccess = () => {
    navigate('/dashboard')
  }

  return (
    <MainContainerUi>
      <h1>Connect</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.formWrapper}>
            <div className={styles.connectBtns}>
              <ConnectButtons onConnect={handleWalletAuthSuccess} />
            </div>
          </div>
        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default ConnectWallet