import styles from './styles.module.scss'

interface Props {
  text: string
}

const ErrorBannerUi = ({ text }: Props) => {
  return (
    <div className={styles.wrapper}>
      {text}
    </div>
  )
}

export default ErrorBannerUi
