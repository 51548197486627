import { Interface } from '@ethersproject/abi'

import CURVE_FACTORY_ABI from './curveFactory.json'

const CURVE_FACTORY_INTERFACE = new Interface(CURVE_FACTORY_ABI)

export {
  CURVE_FACTORY_ABI,
  CURVE_FACTORY_INTERFACE,
}
