// import { NavLink } from 'react-router-dom'

import { useApplyForRewards, useUserHoldingSnapshotsTotals } from 'hooks/useUser'
import { useUserDetails, useUpdateUserDetails } from 'state/user/hooks'

import CardUi from 'components/UI/CardUi'
import TooltipUi from 'components/UI/TooltipUi'

import ADD_ICON_URL from 'assets/images/icons/add.svg'

import { formatUSDa } from 'utils/formatters'

import styles from './styles.module.scss'
import { useSignClaimRewardsRequestMessage } from 'hooks/useSignature'
import ClaimingService from 'API/ClaimingService'
import { useState } from 'react'
import { useClaimRewards } from 'hooks/useClaimRewards'
import { BigNumber } from 'ethers'

interface Props {
  hintText: string
  title: string
  description: string
}

const RewardCard = ({ hintText, title, description }: Props) => {
  const signClaimRewardsRequestMessage = useSignClaimRewardsRequestMessage()
  const { onClaimRewards } = useClaimRewards()
  const snapshotsTotals = useUserHoldingSnapshotsTotals()

  const {processing, onApplyForRewards} = useApplyForRewards()
  const userDetails = useUserDetails()
  const updateUserDetails = useUpdateUserDetails()

  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleApplyForRewards = async () => {
    await onApplyForRewards()
    updateUserDetails()
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const signature = await signClaimRewardsRequestMessage()

      const resp = await ClaimingService.claimRewards(signature)

      console.log(resp)


      await onClaimRewards(resp.data.snapshot_ids, resp.data.amounts, resp.data.signature)
    } catch (err: any) {
      console.error(`Error claiming rewards ${err}`)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CardUi>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <TooltipUi text={hintText} />
          <p className={styles.title}>{title}</p>
        </div>
        <p className={styles.description}>{!userDetails?.applied_for_rewards ? 'Add your wallet to receive rewards every seven days' : `Total awards received: ${formatUSDa(snapshotsTotals.total_claimed)} USDa`}</p>
        {!userDetails?.applied_for_rewards ? (
          <button className={styles.linkBtn} disabled={processing} onClick={handleApplyForRewards}>
            <div className={styles.iconWrapper}>
              <img src={ADD_ICON_URL} alt="" />
            </div>
            <p>ADD WALLET</p>
          </button>
        ) : (
          <button className={styles.linkBtn} disabled={!!error || isLoading || BigNumber.from(snapshotsTotals.total_unclaimed).eq(0)} onClick={handleSubmit}>
            <p>{`Claim ${formatUSDa(snapshotsTotals.total_unclaimed)} USDa`}</p>
          </button>
        )}
        {/* <NavLink to="/claim">
          <div className={styles.linkBtn}>
            <div className={styles.iconWrapper}>
              <img src={ADD_ICON_URL} alt="" />
            </div>
            <p>Add wallet</p>
          </div>
        </NavLink> */}
      </div>
    </CardUi>

  )
}

export default RewardCard
