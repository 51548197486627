import type { AddEthereumChainParameter } from '@web3-react/types'

import ETHEREUM_ICON from 'assets/images/networks/ethereum-logo.png'

export enum ChainId {
  MAINNET = 1,
  SEPOLIA = 11155111,
  AEGISNET = 219865,
  LOCAL = 1337,
}

export const DEFAULT_CHAIN_ID = ChainId.MAINNET

export const ETHEREUM_CURRENCY: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
}

export interface BasicChainInformation {
  id: number
  name: string
  icon?: string
  urls: string[]
  available: boolean
  testnet: boolean
}

export interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

export type ChainInfo = BasicChainInformation | ExtendedChainInformation

function isExtendedChainInformation(
  chainInformation: ChainInfo,
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: ChainId): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

type ChainConfig = { [key in ChainId]: ChainInfo }

export const CHAINS: ChainConfig = {
  [ChainId.MAINNET]: {
    id: ChainId.MAINNET,
    name: 'Ethereum',
    icon: ETHEREUM_ICON,
    urls: ['https://rpc.ankr.com/eth', 'https://eth-mainnet.public.blastapi.io'],
    nativeCurrency: ETHEREUM_CURRENCY,
    blockExplorerUrls: ['https://etherscan.io'],
    available: false,
    testnet: false,
  },
  [ChainId.SEPOLIA]: {
    id: ChainId.SEPOLIA,
    name: 'Sepolia',
    icon: ETHEREUM_ICON,
    urls: [
      'https://rpc.sepolia.org/',
      'https://rpc.sepolia.org/',
      'https://rpc2.sepolia.org/',
      'https://rpc.sepolia.online/',
      'https://www.sepoliarpc.space/',
      'https://rpc-sepolia.rockx.com/',
      'https://rpc.bordel.wtf/sepolia',
    ],
    nativeCurrency: ETHEREUM_CURRENCY,
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
    available: false,
    testnet: true,
  },
  [ChainId.AEGISNET]: {
    id: ChainId.AEGISNET,
    name: 'Aegis Testnet',
    icon: ETHEREUM_ICON,
    urls: [
      'https://rpc-aegis.devlab.group',
    ],
    nativeCurrency: ETHEREUM_CURRENCY,
    blockExplorerUrls: ['https://rpc-aegis.devlab.group'],
    available: true,
    testnet: true,
  },
  [ChainId.LOCAL]: {
    id: ChainId.LOCAL,
    name: 'Local',
    icon: ETHEREUM_ICON,
    urls: [
      'http://127.0.0.1:8545/',
    ],
    nativeCurrency: ETHEREUM_CURRENCY,
    blockExplorerUrls: [],
    available: process.env.REACT_APP_WEB3_LOCAL === 'true',
    testnet: true,
  },
}


export const ALL_SUPPORTED_CHAIN_IDS: ChainId[] = Object.values(ChainId).filter(
  id => typeof id === 'number',
) as ChainId[]