import { BigNumberish } from '@ethersproject/bignumber'
import { formatUnits } from 'ethers/lib/utils'

export function formatNumber(input: BigNumberish, units = 6, digits = 6) {
  let number
  if (typeof input === 'number') {
    number = input
  } else {
    number = parseFloat(formatUnits(input, units))
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(number)
}

export function formatUSDa(input: BigNumberish) {
  return formatNumber(input, 18)
}
