import { useState, useMemo, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { formatUnits } from 'ethers/lib/utils'

import MintingService from 'API/MintingService'

import {
  useUSDAToken,
  useSupportedTokens,
  useTokenPrices,
} from 'hooks/useToken'
import { useTokenBalance } from 'hooks/useBalance'

import { ZeroPrice } from '@entities'

import PaymentInput from 'components/PaymentInput'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import LoadingBannerUi from 'components/UI/LoadingBannerUi'

import { truncateFloatString } from 'utils/formatters'

import styles from './styles.module.scss'
import SuccessUi from 'components/UI/SuccessUi'

const RedeemForm = () => {
  const { provider } = useWeb3React()

  const usdaToken = useUSDAToken()
  const supportedTokens = useSupportedTokens()
  const tokenPrices = useTokenPrices()

  const [outputToken, setOutputToken] = useState(supportedTokens[0])
  const inputBalance = useTokenBalance(usdaToken)
  const outputBalance = useTokenBalance(outputToken)

  const [inputAmount, setInputAmount] = useState('')
  const [outputAmount, setOutputAmount] = useState('')

  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  useEffect(() => {
    if (!outputToken.address) {
      setOutputToken(supportedTokens[0])
    }
  }, [JSON.stringify(supportedTokens), outputToken.address])

  const price = useMemo(() => {
    if (!outputToken.symbol) {
      return ZeroPrice
    }

    return tokenPrices[outputToken.symbol]
  }, [tokenPrices, outputToken.symbol])

  const canRedeem = useMemo(() => {
    return inputAmount && inputAmount !== '0' && !error
  }, [inputAmount, error])

  const handleInputChange = (name: string, value: string) => {
    if (!value) {
      setInputAmount('')
      setOutputAmount('')
      setError(null)
      return
    }

    let isEnoughBalance = false
    if (name === 'inputAmount') {
      setInputAmount(value)
      const calculatedOutput = usdaToken.parseUnits(value).mul(price.invert().asBigNumber)
      const outputAmount = truncateFloatString(formatUnits(calculatedOutput, usdaToken.decimals + price.decimals), outputToken.decimals)
      setOutputAmount(outputAmount)
      isEnoughBalance = outputToken.parseUnits(outputAmount).lte(inputBalance)
    } else if (name === 'outputAmount') {
      setOutputAmount(value)
      const calculatedInput = outputToken.parseUnits(value).mul(price.asBigNumber)
      const inputAmount = truncateFloatString(formatUnits(calculatedInput, outputToken.decimals + price.decimals), usdaToken.decimals)
      setInputAmount(inputAmount)
      isEnoughBalance = outputToken.parseUnits(value).lte(inputBalance)
    }

    if (!isEnoughBalance) {
      setError('Balance is lower')
    } else {
      setError(null)
    }
  }

  const handleSubmit = async () => {
    if (!provider || !canRedeem) {
      return
    }

    setIsLoading(true)
    const amount = usdaToken.parseUnits(inputAmount)
    try {
      // Sign sending data and obtain order and signature from the server
      setLoadingText('Please sign your order via your wallet')
      const signature = await provider.getSigner().signMessage(''.concat(outputToken.address, amount.toString()))

      setLoadingText('Creating redeem request...')
      await MintingService.requestRedeem(outputToken.address, amount.toString(), signature)

      setIsSuccess(true)
      setInputAmount('')
      setOutputAmount('')
    } catch (error) {
      console.error(`Error submitting redeem request ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      {isLoading && <LoadingBannerUi text={loadingText} />}
      {isSuccess ?
        <SuccessUi
          text="Your request has been sent successfully. Monitor the status of your application and after a successful deposit from the platform administration, click on the Redeem button on the Dashboard page"
          link="/dashboard"
          linkText="Back to dashboard"
        />
        :
        <>
          <PaymentInput
            name="inputAmount"
            value={inputAmount}
            title="You pay"
            selectedToken={usdaToken}
            tokens={[usdaToken]}
            balance={inputBalance}
            placeholder="0"
            isBaseCurrency={true}
            error={error}
            onChange={handleInputChange}
          />
          <PaymentInput
            name="outputAmount"
            value={outputAmount}
            title="You receive"
            selectedToken={outputToken}
            tokens={supportedTokens}
            balance={outputBalance}
            placeholder="0"
            onChange={handleInputChange}
            onTokenSelect={setOutputToken}
          />
          <div className={styles.paymentInfo}>
            <div className={styles.price}>
          1 <span className={styles.currency}>{outputToken.symbol}</span>
              {' = '}
              {price.format()}
              {' '}
              <span className={styles.currency}>USDa</span></div>
            <div className={styles.blockchainInfo}>
              <div className={styles.blockchainInfoItem}>
                <div className={styles.title}>Max. slippage</div>
                <div className={styles.value}>0.5%</div>
              </div>
              <div className={styles.blockchainInfoItem}>
                <div className={styles.title}>Network Fee</div>
                <div className={styles.value}>0.003 ETH</div>
              </div>
            </div>
          </div>
          <SubmitBtnUi
            text="Redeem"
            isLoading={isLoading}
            disabled={!canRedeem}
            onClick={handleSubmit}
          />
        </>
      }
    </div>
  )
}

export default RedeemForm