import React from 'react'
import styles from './styles.module.scss'

interface StepperProps {
  totalSteps: number
  currentStep: number
}

const Stepper: React.FC<StepperProps> = ({ totalSteps, currentStep }) => {
  const steps = Array.from({ length: totalSteps }, (_, i) => i + 1)

  return (
    <div className={styles.wrapper}>
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <div
            className={`${styles.step} ${step <= currentStep ? styles.active : ''}`}
          >
            {step}
          </div>
          {index < totalSteps - 1 && (
            <div
              className={`${styles.spacer} ${index < currentStep ? styles.active : ''}`}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Stepper