import { useState, useCallback, useMemo, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { parseUnits } from 'ethers/lib/utils'

import MintingService from 'API/MintingService'
import { useActiveChainId } from 'state/network/hooks'
import { Price, ZeroPrice, Token, TokenSymbol } from '@entities'

import {
  AEGIS_MINTING_ADDRESS,
  USDT_TOKEN,
  USDC_TOKEN,
  DAI_TOKEN,
  USDA_TOKEN,
} from '@constants'
import { ERC20_ABI } from 'constants/abis'

import { getContract } from 'web3/utils'

import useInterval from './useInterval'
import { useERC20Token } from './useContract'

export function useUSDAToken() {
  const chainId = useActiveChainId()

  return useMemo(() => USDA_TOKEN[chainId], [chainId])
}

export function useSupportedTokens() {
  const chainId = useActiveChainId()

  return useMemo(() => ([
    USDT_TOKEN[chainId],
    USDC_TOKEN[chainId],
    DAI_TOKEN[chainId],
  ]), [chainId])
}

export function useTokenPrices() {
  const [prices, setPrices] = useState<{ [key in string]: Price }>({
    [TokenSymbol.USDT]: ZeroPrice,
    [TokenSymbol.USDC]: ZeroPrice,
    [TokenSymbol.DAI]: ZeroPrice,
  })

  const fetchPrices = useCallback(async () => {
    const resp = await MintingService.tokenPrices()

    setPrices(
      Object.keys(resp.data).reduce((accum: any, key: string) => ({
        ...accum,
        [key]: Price.from(resp.data[key]),
      }), {}),
    )
  }, [])

  useInterval(fetchPrices, 3000)

  return prices
}

export function useGetTokenMintingAllowance(token: Token) {
  const { account } = useWeb3React()
  const contract = useERC20Token(token.address)
  const chainId = useActiveChainId()

  return useCallback(async () => {
    if (!contract || !account) {
      return BigNumber.from(0)
    }

    return contract.allowance(account, AEGIS_MINTING_ADDRESS[chainId])
  }, [chainId, account, contract])
}

export function useApproveTokenMinting(token: Token) {
  const contract = useERC20Token(token.address)
  const chainId = useActiveChainId()
  const [processing, setProcessing] = useState(false)

  const onApprove = useCallback(async () => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.approve(AEGIS_MINTING_ADDRESS[chainId], MaxUint256)
      const receipt = await tx.wait()
      return receipt
    } catch (error) {
      console.error(`Error approving token spending ${error}`)
      throw error
    } finally {
      setProcessing(false)
    }
  }, [contract, chainId])

  return { processing, onApprove }
}

export function useApproveERC20Minting() {
  const { provider, account } = useWeb3React()
  const chainId = useActiveChainId()
  const [processing, setProcessing] = useState(false)

  const onApprove = useCallback(async (tokenAddress: string) => {
    if (!provider || !account) {
      return
    }

    const contract = getContract(tokenAddress, ERC20_ABI, provider, account)

    setProcessing(true)
    try {
      const tx = await contract.approve(AEGIS_MINTING_ADDRESS[chainId], MaxUint256)
      const receipt = await tx.wait()
      return receipt
    } catch (error) {
      console.error(`Error approving token spending ${error}`)
      throw error
    } finally {
      setProcessing(false)
    }
  }, [provider, account, chainId])

  return { processing, onApprove }
}

export function useApproveToken(token: Token) {
  const contract = useERC20Token(token.address)
  const [processing, setProcessing] = useState(false)

  const onApproveToken = useCallback(async (spender: string) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.approve(spender, MaxUint256)
      await tx.wait()
    } catch (err) {
      console.error('Error approving token', err)
    } finally {
      setProcessing(false)
    }
  }, [contract])

  return { processing, onApproveToken }
}

export function useGetERC20MintingAllowance() {
  const { account, provider } = useWeb3React()
  const chainId = useActiveChainId()

  return useCallback(async (tokenAddress: string) => {
    if (!provider || !account) {
      return BigNumber.from(0)
    }

    const contract = getContract(tokenAddress, ERC20_ABI, provider, account)

    return contract.allowance(account, AEGIS_MINTING_ADDRESS[chainId])
  }, [chainId, account, provider])
}

export function useTokenApprovalRequired(token: Token, spender: string, amount: string) {
  const { account } = useWeb3React()
  const contract = useERC20Token(token.address)
  const [approvalRequired, setApprovalRequired] = useState(true)

  const updateApprovalRequired = useCallback(async () => {
    if (!account || !contract || !amount) {
      setApprovalRequired(true)
      return
    }

    const allowance = await contract.allowance(account, spender)
    setApprovalRequired(allowance.lt(token.parseUnits(amount)))
  }, [account, contract, amount, spender])

  useEffect(() => {
    updateApprovalRequired()
  }, [updateApprovalRequired])

  return { approvalRequired, updateApprovalRequired }
}
