import { useState, useEffect } from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import CommonService from 'API/CommonService'

import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'
import SelectUi from 'components/UI/SelectUi' // Импортируем кастомный селект

import COPY_ICON_URL from 'assets/images/icons/copy.svg'

import exchanges from './exchanges'
import styles from './styles.module.scss'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'

const OpenExchangeRequests = () => {
  const [selectedExchange, setSelectedExchange] = useState('binance')
  const [selectedEndpoint, setSelectedEndpoint] = useState(exchanges.binance.endpoints[0])
  const [curlCmd, setCurlCmd] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    setSelectedEndpoint(exchanges[selectedExchange].endpoints[0])
  }, [selectedExchange])

  const handleGenerateCurlCmd = async () => {
    setError('')
    setCurlCmd('')
    try {
      const cmdString = await CommonService.generateExchangeDataRequest(selectedExchange, selectedEndpoint)
      setCurlCmd(cmdString)
    } catch (error) {
      setError((error as any).message)
    }
  }

  return (
    <MainContainerUi>
      <h1>Exchange Requests</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.form}>
            <SelectUi
              items={Object.keys(exchanges)}
              onSelect={setSelectedExchange}
              renderItem={(item) => <span>{exchanges[item].name}</span>}
              placeholder='Select exchange'
            />

            <SelectUi
              items={exchanges[selectedExchange].endpoints}
              onSelect={setSelectedEndpoint}
              renderItem={(item) => <span>{item}</span>}
              placeholder='Select endpoint'
            />

            <button className={styles.submitBtn} onClick={handleGenerateCurlCmd}>
              Generate
            </button>
          </div>
          {error ? (
            <div className={styles.error}>
              {error}
            </div>
          ) : null}
          {curlCmd ? (
            <div className={styles.dataWrapper}>
              <div className={styles.headerWrapper}>
                <p className={styles.header}>Reponse:</p>
                <CopyToClipboard text={curlCmd}>
                  <div className={styles.copyWrapper}>
                    <div className={styles.iconWrapper}>
                      <img src={COPY_ICON_URL} alt="" />
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
              <div className={styles.response}>
                <pre>
                  {curlCmd}
                </pre>
              </div>
            </div>
          ) : null}

        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default OpenExchangeRequests
