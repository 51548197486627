import React, { useState, useEffect, useMemo } from 'react'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import type { ExchangeFundingRateChartData } from '@types'

import styles from './styles.module.scss'

interface DataPoint {
  t: number;
  c: string;
}

interface ExchangeRatesChartProps {
  data: ExchangeFundingRateChartData
}

const ExchangeRatesChart: React.FC<ExchangeRatesChartProps> = ({ data }) => {
  const [key, setKey] = useState<number>(0)

  useEffect(() => {
    setKey(prevKey => prevKey + 1)
  }, [data])

  const series = useMemo(() => {
    if (Object.keys(data).length === 0) {
      return []
    }

    return Object.keys(data).map((key) => ({
      name: key,
      data: data[key].map(point => [point.time * 1000, point.rate]),
      tooltip: {
        valueDecimals: 5,
        shared: true,
        crosshairs: true,
      },
      marker: {
        symbol: 'none',
      },
    }))
  }, [data])

  const options = useMemo<Highcharts.Options>(() => ({
    chart: {
      zoomType: 'x',
      backgroundColor: undefined,
    },
    title: {
      text: undefined,
    },
    series: series as any,
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          color: '#8B8B8B',
        },
        formatter: function() {
          return Highcharts.dateFormat('%b %e', Math.round(this.value as number / 1000) as any)
        },
      },
      lineColor: '#8B8B8B',
      tickColor: '#8B8B8B',
    },
    yAxis: {
      title: {
        text: 'Funding rate',
        style: {
          color: '#8B8B8B',
        },
      },
      labels: {
        style: {
          color: '#8B8B8B',
        },
      },
      lineColor: '#8B8B8B',
      tickColor: '#8B8B8B',
      gridLineColor: '#8B8B8B',
      plotLines: [{
        value: 0,
        color: '#0071A1',
        width: 1,
        zIndex: 5,
      }],
      gridLineWidth: 0,
    },
    tooltip: {
      shared: true,
      valueDecimals: 6,
      crosshairs: true,
      backgroundColor: '#3C404B',
      style: {
        color: '#FFFFFF',
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            // marker: {
            enabled: false,
            // },
          },
        },
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
      backgroundColor: 'none',
      borderWidth: 0,
      itemStyle: {
        color: 'white',
        fontSize: '12px',
      },
    },
    credits: {
      enabled: false,
    },
  }), [series])

  return (
    <div className={styles.wrapper}>
      {Object.keys(data).length === 0 ? (
        <div>No data available</div>
      ) : (
        <HighchartsReact
          key={key}
          highcharts={Highcharts}
          options={options}
        />
      )}
    </div>
  )
}

export default React.memo(ExchangeRatesChart)
