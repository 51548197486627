import { useState, useEffect, useRef } from 'react'
import cx from 'classnames'

import { CHAINS } from 'web3/chains'
import { useOusideClick } from 'hooks/useOutsideClick'
import { useActiveNetwork, useSwitchNetwork } from 'state/network/hooks'

import Option from './Option'

import styles from './styles.module.scss'

const SelectNetwork = () => {
  const activeNetwork = useActiveNetwork()
  const switchNetwork = useSwitchNetwork()
  const [showOptions, setShowOptions] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOusideClick(ref, () => setShowOptions(false))

  const availableNetworks = Object.values(CHAINS).filter(network => network.available)

  const handleActivate = async (id: number) => {
    await switchNetwork(id)
    setShowOptions(false)
  }

  return (
    <div ref={ref} className={cx(styles.wrapper, {[styles.wrongNetwork]: !activeNetwork})}>
      <a onClick={() => setShowOptions(prev => !prev)}>
        {activeNetwork ? (
          <span className={styles.validNetwork}>Network: {activeNetwork.name}</span>
        ) : (
          <span className={styles.invalidNetwork}>Switch Network</span>
        )}
      </a>
      {showOptions && (
        <div className={styles.menu}>
          {availableNetworks.map(network => (
            <Option
              key={network.id}
              network={network}
              activate={() => handleActivate(network.id)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default SelectNetwork
