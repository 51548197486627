import api from './api'

const MintingService = {
  mint: async (asset: string, amount: string, signature: string) => {
    const { data } = await api.post('/minting/mint', { collateral_asset: asset, collateral_amount: amount, signature })

    return data
  },
  redeem: async (requestId: string, signature: string) => {
    const { data } = await api.post('/minting/redeem', { request_id: requestId, signature })

    return data
  },
  requestRedeem: async (asset: string, usdaAmount: string, signature: string) => {
    const { data } = await api.post('/minting/request-redeem', { collateral_asset: asset, usda_amount: usdaAmount, signature })

    return data
  },
  tokenPrices: async () => {
    const { data } = await api.get('/minting/token-prices')

    return data
  },
}

export default MintingService
