import ConnectButtons from 'components/ConnectButtons'

import StepWrapper from '../StepWrapper'

interface Props {
  onNextStep: () => void
}

const Step1 = ({ onNextStep }: Props) => {
  return (
    <StepWrapper title="Connect your wallet" description="For initial authorization in the project, please connect your wallet" totalSteps={3} currentStep={1}>
      <ConnectButtons onConnect={onNextStep} />
    </StepWrapper>
  )
}

export default Step1
