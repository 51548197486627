import axios from 'axios'

import router from 'router'
import store from 'state'
import { setAuthToken } from 'state/user/reducer'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
})

api.interceptors.request.use((config) => {
  const isLoginOrRegister = config.url?.endsWith('/sign-in') || config.url?.endsWith('/sign-up')

  if (!isLoginOrRegister) {
    const token = store.getState().user.authToken
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

api.interceptors.response.use(response => {
  return response
}, error => {
  if (router.state.location.pathname === '/sign-up' && router.state.location.search && (error.response.status === 401 || error.response.status === 403)) {
    return
  }

  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    store.dispatch(setAuthToken(null))
    router.navigate('/wallet-sign-in', { replace: true })
  }

  return Promise.reject(error.response.data.message || error.response.data.errors)
})

export default api
