import { useState } from 'react'

import type { Interval } from '@types'
import { INTERVALS } from 'constants/charts'

import { useOiWeightData } from 'hooks/useStats'

import CardUi from 'components/UI/CardUi'
import WeightChart from 'components/Charts/WeightChart'
import ChartIntervalSelector from 'components/ChartIntervalSelector'

import styles from '../styles.module.scss'

const BTCOIFundingRateChart = () => {
  const [interval, setInterval] = useState<Interval>('4h')

  const oiWeightData = useOiWeightData(interval)

  return (
    <div className={styles.highchartsWrapper}>
      <CardUi>
        <div className={styles.headerWrapper}>
          <p className={styles.statHeader}>BTC OI-Weighted Funding Rate</p>
          <ChartIntervalSelector
            intervals={INTERVALS}
            selectedInterval={interval}
            onIntervalChange={setInterval}
          />
        </div>

        <WeightChart key={`oi-${interval}`} data={oiWeightData} />
      </CardUi>
    </div>
  )
}

export default BTCOIFundingRateChart
