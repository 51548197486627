import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  apr: number
}

export const initialState: State = {
  apr: 0,
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setApr(state, action: PayloadAction<number>) {
      state.apr = action.payload
    },
  },
})

export const {
  setApr,
} = statsSlice.actions

export default statsSlice.reducer
