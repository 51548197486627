import UserUpdater from './user/updater'
import StatsUpdater from './stats/updater'
// import WalletUpdater from './wallet/updater'

const Updater = () => (
  <>
    <UserUpdater />
    <StatsUpdater />
    {/* <WalletUpdater /> */}
  </>
)

export default Updater
