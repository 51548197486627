import { BigNumberish } from '@ethersproject/bignumber'
import { parseUnits, formatUnits } from 'ethers/lib/utils'

import type { TokenSymbol } from './types'

export class Token {
  readonly decimals: number
  readonly address: string
  readonly symbol: TokenSymbol
  readonly name: string

  constructor(address: string, decimals: number, symbol: TokenSymbol, name: string) {
    this.address = address
    this.decimals = decimals
    this.symbol = symbol
    this.name = name
  }

  parseUnits(value: string, decimals = 0) {
    return parseUnits(value, decimals ? decimals : this.decimals)
  }

  formatUnits(value: BigNumberish, decimals = 0) {
    return formatUnits(value, decimals ? decimals : this.decimals)
  }

  sortsBefore(other: Token): boolean {
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}