import CardUi from 'components/UI/CardUi'

import styles from './styles.module.scss'

const UnsupportedNetwork = () => (
  <CardUi>
    <div className={styles.wrapper}>
      <span>You are using unsupported network.</span>
      <span>Please, switch it in your wallet</span>
    </div>
  </CardUi>
)

export default UnsupportedNetwork
