import { useState, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'

import AuthService from 'API/AuthService'
import { setAuthToken, setUserDetails } from 'state/user/reducer'
import useForm from 'hooks/useForm'

import InputUi from 'components/UI/InputUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import TwoFactorVerification from 'components/TwoFactorVerification'

import styles from './styles.module.scss'
import signInValidator from 'validators/signInValidator'

const CredsAuth = ({ onSuccess }: { onSuccess: () => void }) => {
  const initialFormState = {
    auth_type: 'email',
    email: '',
    password: '',
    code: '',
  }

  const { values, errors, handleChange, validate } = useForm(initialFormState, signInValidator)
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [twoFaEnabled, setTwoFaEnabled] = useState<boolean>(false)
  const [twoFaCode, setTwoFaCode] = useState<string>('')

  const dispatch = useDispatch()

  const handleSubmit = async () => {
    if (!validate()) return

    setIsLoading(true)

    try {
      const responsePrecheck = await AuthService.signInPrecheck({
        auth_type: 'email',
        email: values.email,
        password: values.password,
      })

      if (responsePrecheck.data.twofa_enabled) {
        setTwoFaEnabled(true)
      } else {
        await signIn(values.email, values.password)
      }
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    } finally {
      setIsLoading(false)
    }
  }

  const signIn = async (email: string, password: string, code?: string) => {
    try {
      const responseSignIn = await AuthService.signIn({
        auth_type: 'email',
        email,
        password,
        code,
      })

      dispatch(setAuthToken(responseSignIn.data.token))
      dispatch(setUserDetails(responseSignIn.data.user))
      onSuccess()
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    }
  }

  const handleTwoFaSubmit = async () => {
    await signIn(values.email, values.password, twoFaCode)
  }

  const handleTwoFaCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTwoFaCode(event.target.value)
  }

  return (
    <div className={styles.formWrapper}>
      {!twoFaEnabled ? (
        <>
          <InputUi
            label="Email"
            name="email"
            type="email"
            value={values.email}
            error={errors.email}
            onChange={handleChange}
          />
          <InputUi
            label="Password"
            name="password"
            type="password"
            value={values.password}
            error={errors.password}
            onChange={handleChange}
          />
          {error && <ErrorBannerUi text={error} />}
          <SubmitBtnUi text="Sign In" isLoading={isLoading} disabled={isLoading} onClick={handleSubmit} />
        </>
      ) : (
        <TwoFactorVerification
          twoFaCode={twoFaCode}
          handleTwoFaCodeChange={handleTwoFaCodeChange}
          handleTwoFaSubmit={handleTwoFaSubmit}
          isLoading={isLoading}
          error={error}
        />
      )}
    </div>
  )
}

export default CredsAuth
