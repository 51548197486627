import { useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import MintingService from 'API/MintingService'

import { useRedeem } from 'hooks/useMinting'
import { useGetTokenMintingAllowance, useApproveTokenMinting } from 'hooks/useToken'
import { useTokenBalance } from 'hooks/useBalance'

import { Token, Price } from '@entities'
import { type RedeemRequest, RedeemRequestStatus } from '@types'

import styles from './styles.module.scss'
import CardUi from 'components/UI/CardUi'

interface Props {
  item: RedeemRequest
  collateralToken: Token
  usdaToken: Token
}

const IsRedeemed = ({ item, collateralToken, usdaToken }: Props) => {
  const { provider } = useWeb3React()

  const { processing: processingApprove, onApprove } = useApproveTokenMinting(usdaToken)
  const { processing: processingRedeem, onRedeem } = useRedeem()
  const usdaBalance = useTokenBalance(usdaToken)
  const getUSDaTokenMintingAllowance = useGetTokenMintingAllowance(usdaToken)

  const [isLoading, setIsloading] = useState(false)

  const canRedeem = useMemo(() => {
    return item.status === RedeemRequestStatus.DEPOSITED && usdaBalance.gte(item.details.usda_amount) && !(processingApprove || processingRedeem)
  }, [processingRedeem, processingApprove, usdaBalance, item.status, item.details.usda_amount])

  const handleRedeem = async () => {
    if (!provider || !canRedeem) {
      return
    }

    setIsloading(true)

    try {
      const signature = await provider.getSigner().signMessage(item.request_id)

      const resp = await MintingService.redeem(item.request_id, signature)

      const allowance = await getUSDaTokenMintingAllowance()
      if (allowance.lt(resp.data.order.usda_amount)) {
        await onApprove()
      }

      await onRedeem(resp.data.order, resp.data.signature)
    } catch (error) {
      console.error(`Error processing redeem ${error}`)
    } finally {
      setIsloading(false)
    }
  }

  return (
    <CardUi>
      <div className={styles.wrapper}>
        <div className={styles.leftSection}>
          <div className={styles.textContainer}>
            <p className={styles.title}>Redeem Status: Deposited</p>
            <p className={styles.description}>{`Your redeem request ${item.request_id} is now ready for execution. Please proceed with the next step to complete the process.`}</p>
          </div>
        </div>
        <button className={styles.link} disabled={!canRedeem || isLoading} onClick={handleRedeem}>
          { isLoading ? 'Loading...' : 'Redeem'}
        </button>
      </div>
    </CardUi>
  )
}

export default IsRedeemed
