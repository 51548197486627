import { useCallback } from 'react'

import CommonService from 'API/CommonService'

import { useAppDispatch, useAppSelector } from '../../state'
import {
  setApr,
} from './reducer'

export function useUpdateStats() {
  const dispatch = useAppDispatch()

  return useCallback(async () => {
    const data = await CommonService.fetchProjectStats()
    dispatch(setApr(data.apr))
  }, [dispatch])
}

export function useApr() {
  return useAppSelector(state => state.stats.apr)
}
