import cx from 'classnames'

import SnapshotWeekItem from 'components/SnapshotWeekitem'
import CardUi from 'components/UI/CardUi'

import type { UserHoldingSnapshot } from '@types'

import styles from './styles.module.scss'

interface Props {
  totalPages: number
  currentPage: number
  snapshots: UserHoldingSnapshot[]
  onNextPage: () => void
  onPrevPage: () => void
}

const SnapshotsList = ({ totalPages, currentPage, snapshots, onNextPage, onPrevPage }: Props) => {
  if (totalPages === 0) {
    return null
  }

  return (
    <div className={styles.snapshots}>
      <div className={styles.list}>
        {snapshots.map(snap => (
          <CardUi key={snap.snapshot_id}>
            <SnapshotWeekItem snap={snap} />
          </CardUi>
        ))}
      </div>
      {totalPages > 1 ? (
        <div className={styles.pagination}>
          <button
            className={styles['nav-btn']}
            disabled={currentPage === 1}
            onClick={onPrevPage}
          >
            Prev
          </button>
          <div className={styles.pages}>
            {`${currentPage}/${totalPages}`}
          </div>
          <button
            className={styles['nav-btn']}
            disabled={currentPage === totalPages}
            onClick={onNextPage}
          >
            Next
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default SnapshotsList
