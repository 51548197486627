import type { BTCMarginStat } from '@types'

import styles from './styles.module.scss'

interface Props {
  data: BTCMarginStat[]
}

const TokenMarginedTable = ({ data }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {data.map((item) => (
            <div key={item.exchange} className={styles.tableHeaderCell}>
              {item.exchange}
            </div>
          ))}
        </div>

        <div className={styles.tableRow}>
          {data.map((item) => (
            <div key={item.exchange} className={styles.tableCell}>
              {item.fundingRate.toFixed(6)}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.mobileTable}>
        {data.map((item) => (
          <div key={item.exchange} className={styles.mobileRow}>
            <span className={styles.exchangeName}>{item.exchange}</span>
            <span className={styles.fundingRate}>{item.fundingRate.toFixed(6)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TokenMarginedTable
