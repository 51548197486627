import React, { useState, useEffect } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import useForm from 'hooks/useForm'
import AuthService from 'API/AuthService'
import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'

import styles from './styles.module.scss'
import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'

import resetPasswordValidator from 'validators/resetPasswordValidator'


const ResetPassword = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const codeFromUrl = query.get('code') || ''

  const initialFormState = { newPassword: '', confirmPassword: '' }
  const { values, errors, handleChange, validate } = useForm(initialFormState, resetPasswordValidator)
  const [code, setCode] = useState<string>(codeFromUrl)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  useEffect(() => {
    if (!code) {
      setError('Code is required for resetting the password')
    }
  }, [code])

  const handleSubmit = async () => {
    if (!validate()) return

    setIsLoading(true)

    try {
      const response = await AuthService.resetPassword({ code, password: values.newPassword })
      console.log('Reset Password response:', response)
      setIsSuccess(true)
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <MainContainerUi>
      <h1>Reset Password</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.formWrapper}>
            {error && !isSuccess && <ErrorBannerUi text={error} />}
            {!isSuccess ? (
              <>
                <h5>Password recovery</h5>
                <p className={styles.description}>Enter your new password that you will use when logging in.</p>
                <InputUi
                  label="New Password"
                  name="newPassword"
                  type="password"
                  value={values.newPassword}
                  error={errors.newPassword}
                  onChange={handleChange}
                  disabled={isLoading}
                />
                <InputUi
                  label="Confirm new password"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={handleChange}
                  disabled={isLoading}
                />
                <SubmitBtnUi text="Reset Password" isLoading={isLoading} disabled={isLoading} onClick={handleSubmit} />
              </>
            ) : (<>
              <h5>Password recovery</h5>
              <p className={styles.description}>You have successfully changed your password! You can now sign in</p>
              <NavLink className={styles.btnLink} to="/sign-in">Sign in</NavLink>
            </>

            )}
          </div>
        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default ResetPassword
