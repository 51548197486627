import { useState, ReactNode, useMemo, ElementType } from 'react'
import cx from 'classnames'

import DownloadButton from 'components/DownloadButton'
import styles from './styles.module.scss'

interface Tab {
  title: string
  Component: ElementType
  props: object
}

interface Props {
  tabs: Tab[]
  activeTabBorderRadius?: number
  isExportButtons?: boolean
  padding?: number
  activeTab?: number
  onTabChange?: (index: number) => void
}

const Tabs = ({
  tabs,
  activeTabBorderRadius = 14,
  isExportButtons = false,
  padding = 24,
  activeTab: controlledActiveTab,
  onTabChange,
}: Props) => {
  const [internalActiveTab, setInternalActiveTab] = useState(0)

  const activeTab = controlledActiveTab !== undefined ? controlledActiveTab : internalActiveTab

  const exportType = useMemo(() => {
    const tabTitle = tabs[activeTab].title.toLowerCase()
    return tabTitle as 'all' | 'mint' | 'redeem' | 'claim'
  }, [activeTab, tabs])

  const handleTabClick = (index: number) => {
    if (onTabChange) {
      onTabChange(index)
    } else {
      setInternalActiveTab(index)
    }
  }

  const Component = tabs[activeTab].Component

  return (
    <div className={styles.wrapper} style={{ padding: `${padding}px` }}>
      {isExportButtons && (
        <div className={styles.actionBtns}>
          <DownloadButton
            exportType={exportType}
          />
        </div>
      )}

      <div className={styles.tabList}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={cx(styles.tab, { [styles.active]: activeTab === index })}
            style={activeTab === index ? { borderRadius: `${activeTabBorderRadius}px` } : {}}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>
        <Component {...tabs[activeTab].props} />
      </div>
    </div>
  )
}

export default Tabs
