import QRCode from 'qrcode.react'

import BackLinkUi from 'components/UI/BackLinkUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import CopierUi from 'components/UI/CopierUi'

import styles from './styles.module.scss'
import Stepper from 'components/UI/Stepper'

interface Props {
  qrCodeUrl: string | null;
  secret: string | null;
  onNextStep: () => void;
  onPrevStep: () => void;
}

const Step2 = ({ onPrevStep, onNextStep, qrCodeUrl, secret }: Props) => (
  <div className={styles.wrapper}>
    <Stepper totalSteps={4} currentStep={2} />
    <h6>Set up your Google Authenticator account</h6>
    <div className={styles.qrCodeWrapper}>
      {qrCodeUrl && <QRCode value={qrCodeUrl} />}
    </div>
    {secret && <CopierUi text={secret} />}
    <div className={styles.description}>Scan this QR code using your phone camera</div>
    <SubmitBtnUi onClick={onNextStep} text="Next" />
    <BackLinkUi onClick={onPrevStep} text='Back' />
  </div>
)

export default Step2
