import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import styles from './styles.module.scss'

const generateMonthlyData = () => {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ]
  const data = []
  let value = 0

  for (let i = 0; i < months.length; i++) {
    value += Math.random() * 10
    if (value > 100) value = 100
    data.push({
      x: months[i],
      y: parseFloat(value.toFixed(2)),
    })
  }

  return data
}

const OpenPositionChart = () => {
  const [series] = useState([
    {
      name: 'Binance',
      data: generateMonthlyData(),
    },
    {
      name: 'ByBit',
      data: generateMonthlyData(),
    },
    {
      name: 'OKX',
      data: generateMonthlyData(),
    },
    {
      name: 'BitGet',
      data: generateMonthlyData(),
    },
    {
      name: 'Derbit',
      data: generateMonthlyData(),
    },
  ])

  const options: ApexCharts.ApexOptions = {
    chart: {
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      type: 'line',
      stacked: false,
      height: 400,
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#4DD1BE', '#DC9A1C', '#EC362C', '#2378CC', '#725AD7'],
    stroke: {
      width: 2,
    },
    grid: {
      show: true,
      borderColor: '#959595',
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    // markers: {
    //   size: 0,
    // },
    legend: {
      show: true,
      fontSize: '14px',
      offsetY: 8,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        strokeWidth: 0,
      },
      itemMargin: {
        horizontal: 20,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8'],
        },
        formatter: function (val) {
          return val.toFixed(2) + '%'
        },
      },
      title: {
        text: 'Open Positions (in %)',
        style: {
          color: '#fff',
        },
      },
      max: 100,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 0, 0],
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
      },
      labels: {
        show: true,
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
      },
    },
    tooltip: {
      marker: {
        show: false,
      },
      shared: false,
      y: {
        formatter: function (val) {
          return val.toFixed(2) + '%'
        },
      },
    },
  }

  return (
    <div className={styles.wrapper}>
      <Chart options={options} series={series} type="area" height={400} />
    </div>
  )
}

export default OpenPositionChart
