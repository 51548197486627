import { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useIsUserAuthorized, useIsUserMinter } from 'state/user/hooks'

import ConnectButton from 'components/ConnectButton'
import MainContainerUi from 'components/UI/MainContainerUi'

import LOGO_ICON_URL from 'assets/images/icons/logo.svg'
import MENU_ICON_URL from 'assets/images/icons/menu.svg'

import styles from './styles.module.scss'
import SelectNetwork from 'components/SelectNetwork'
import RedeemBanner from 'components/RedeemBanner'

const Header = () => {
  const isUserAuthorized = useIsUserAuthorized()
  const isUserMinter = useIsUserMinter()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownOpen])

  return (
    <MainContainerUi>
      {isUserAuthorized ? <SelectNetwork /> : null}
      <div className={styles.desktopWrapper}>
        <div className={styles.leftSection}>
          <div className={styles.logoWrapper}>
            <NavLink to="/"><img src={LOGO_ICON_URL} alt="Logo" /></NavLink>
          </div>
          <div className={styles.linkItems}>
            <NavLink
              to="/dashboard"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.linkItem}>Dashboard</p>
            </NavLink>

            <NavLink
              to="/transparency"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.linkItem}>Transparency</p>
            </NavLink>

            <NavLink
              to="/swap"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.linkItem}>Swap</p>
            </NavLink>

            {isUserAuthorized && isUserMinter && (
              <>
                <NavLink
                  to="/mint"
                  className={({ isActive, isPending }) =>
                    isPending ? styles['pending'] : isActive ? styles['active'] : ''
                  }
                >
                  <p className={styles.linkItem}>Mint</p>
                </NavLink>

                <NavLink
                  to="/redeem"
                  className={({ isActive, isPending }) =>
                    isPending ? styles['pending'] : isActive ? styles['active'] : ''
                  }
                >
                  <p className={styles.linkItem}>Redeem</p>
                </NavLink>
              </>
            )}

            <NavLink
              to="/claim"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.linkItem}>Claim</p>
            </NavLink>
          </div>
        </div>
        <div className={styles.rightSection}>
          <ConnectButton />
        </div>
      </div>

      <div className={styles.mobileWrapper}>
        <div className={styles.leftSection}>
          <div className={styles.logoWrapper}>
            <NavLink to="/"><img src={LOGO_ICON_URL} alt="Logo" /></NavLink>
          </div>
        </div>
        <div className={styles.rightSection}>
          <ConnectButton />
          <div
            onClick={toggleDropdown}
            className={`${styles.headerBtn} ${styles.menuItem}`}
            ref={dropdownRef}
          >
            <div className={styles.iconWrapper}>
              <img src={MENU_ICON_URL} alt="Menu" />
            </div>
            {dropdownOpen && (
              <div className={styles.dropdownMenu}>
                <div className={styles.droplinkItems}>
                  <NavLink to="/dashboard">
                    <p className={styles.linkItem}>Dashboard</p>
                  </NavLink>

                  <NavLink to="/transparency">
                    <p className={styles.linkItem}>Transparency</p>
                  </NavLink>

                  <NavLink to="/swap">
                    <p className={styles.linkItem}>Swap</p>
                  </NavLink>

                  {isUserAuthorized && isUserMinter && (
                    <>
                      <NavLink to="/mint">
                        <p className={styles.linkItem}>Mint</p>
                      </NavLink>

                      <NavLink to="/redeem">
                        <p className={styles.linkItem}>Redeem</p>
                      </NavLink>
                    </>
                  )}

                  <NavLink to="/claim">
                    <p className={styles.linkItem}>Claim</p>
                  </NavLink>

                  <NavLink to="/settings">
                    <p className={styles.linkItem}>Settings</p>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <RedeemBanner />
    </MainContainerUi>
  )
}

export default Header
