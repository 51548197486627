import { useNavigate } from 'react-router-dom'

import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'
import WalletAuth from 'components/WalletAuth'

import LOGO_ICON_URL from 'assets/images/icons/logo.svg'

import styles from './styles.module.scss'

const WalletSignIn = () => {
  const navigate = useNavigate()

  const handleSuccess = () => {
    navigate('/dashboard')
  }

  return (
    <MainContainerUi>
      <h1>Sign In</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.formWrapper}>
            <div className={styles.logoWrapper}>
              <img src={LOGO_ICON_URL} alt="aegis" />
            </div>
            <h2>Aegis</h2>
            <p className={styles.description}>The frontier of digital money</p>
            <WalletAuth onSuccess={handleSuccess} />
          </div>
        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default WalletSignIn