import { useMemo } from 'react'

import { ConnectionType, useGetConnection } from 'web3/connection'
import { useSelectedWallet } from 'state/wallet/hooks'

export default function useOrderedConnections() {
  const selectedWallet = useSelectedWallet()
  const getConnection = useGetConnection()

  return useMemo(() => {
    const orderedConnectionTypes: ConnectionType[] = [
      ConnectionType.GNOSIS_SAFE,
      ConnectionType.INJECTED,
      ConnectionType.WALLET_CONNECT,
      ConnectionType.COINBASE_WALLET,
      ConnectionType.NETWORK,
    ]

    return orderedConnectionTypes.map(connectionType => getConnection(connectionType))
  }, [getConnection, selectedWallet])
}
