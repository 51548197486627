import { Interface } from '@ethersproject/abi'

import CURVE_PLAIN_POOL_ABI from './curvePlainPool.json'

const CURVE_PLAIN_POOL_INTERFACE = new Interface(CURVE_PLAIN_POOL_ABI)

export {
  CURVE_PLAIN_POOL_ABI,
  CURVE_PLAIN_POOL_INTERFACE,
}
