import { defaultAbiCoder } from '@ethersproject/abi'
import { getCreate2Address } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { keccak256 } from '@ethersproject/solidity'

import { Token } from '../entities'

export function computeUniswapPoolAddress({
  factoryAddress,
  tokenA,
  tokenB,
  fee,
}: {
  factoryAddress?: string
  tokenA: Token
  tokenB: Token
  fee: number
}): string {
  if (!factoryAddress) {
    return AddressZero
  }

  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
  const salt = keccak256(
    ['bytes'],
    [defaultAbiCoder.encode(['address', 'address', 'uint24'], [token0.address, token1.address, fee])],
  )
  const initCodeHash = '0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54'

  return getCreate2Address(factoryAddress, salt, initCodeHash)
}

export function computeCurvePoolAddressKey(tokenA: Token, tokenB: Token) {
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

  return `${token0.address}-${token1.address}`
}
