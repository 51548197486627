import TWITTER_ICON_URL from 'assets/images/icons/socials/twitter.svg'
import GITHUB_ICON_URL from 'assets/images/icons/socials/github.svg'
import MIRROR_ICON_URL from 'assets/images/icons/socials/mirror.svg'
import LINKEDIN_ICON_URL from 'assets/images/icons/socials/linkedin.svg'

export interface Social {
  name: string
  iconUrl: string
  url: string
}

const SOCIALS: Social[] = [{
  name: 'X',
  iconUrl: TWITTER_ICON_URL,
  url: 'https://x.com/aegis_im',
},{
  name: 'Mirror',
  iconUrl: MIRROR_ICON_URL,
  url: 'https://mirror.xyz/0xd1E65ecFafa7CCDb0846Aa05888810EaaF10347B',
},
{
  name: 'LinkedIn',
  iconUrl: LINKEDIN_ICON_URL,
  url: 'https://www.linkedin.com/company/aegis-im/',
},{
  name: 'GitHub',
  iconUrl: GITHUB_ICON_URL,
  url: '/',
}]

export default SOCIALS