import MainContainerUi from 'components/UI/MainContainerUi'

import CanClaim from './CanClaim'

import styles from './styles.module.scss'

const Claim = () => {
  return (
    <MainContainerUi>
      <h1>Claim</h1>
      <div className={styles.wrapper}>
        <CanClaim />
      </div>
    </MainContainerUi>
  )
}

export default Claim