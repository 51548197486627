import { ChangeEvent, useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import cx from 'classnames'

import { Token } from 'entities/token'

import TokenButton from 'components/UI/TokenButton'
import TokenSelect from 'components/TokenSelect'

import ARROW_DOWN_ICON_URL from 'assets/images/icons/arrow-down.svg'

import styles from './styles.module.scss'

interface Props {
  name: string
  value: string
  type?: string
  disabled?: boolean
  autoComplete?: string
  placeholder?: string
  error?: string | null
  title: string
  selectedToken: Token
  tokens: Token[]
  balance: BigNumber
  isBaseCurrency?: boolean
  onChange: (name: string, value: string) => void
  onTokenSelect?: (token: Token) => void
  onSwitchDirection?: () => void
}

const PaymentInput = ({
  name,
  value,
  type = 'text',
  disabled = false,
  autoComplete = 'off',
  placeholder = '',
  error = null,
  title,
  selectedToken,
  tokens,
  balance,
  isBaseCurrency = false,
  onChange,
  onTokenSelect,
  onSwitchDirection = () => null,
}: Props) => {
  const pattern = useMemo(() => new RegExp(`^[0-9]+.?[0-9]{0,${selectedToken.decimals}}$`), [selectedToken.decimals])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (!value) {
      onChange(name, value)
      return
    }
    if (!pattern.test(value)) {
      return
    }

    if (selectedToken.parseUnits(value).gt(balance)) {
      e.target.setCustomValidity('Balance is lower')
    } else {
      e.target.setCustomValidity('')
    }
    onChange(name, value)
  }

  const handleBalanceClick = () => {
    if (isBaseCurrency) {
      onChange(name, selectedToken.formatUnits(balance))
    }
  }

  const handleTokenSelect = (token: Token) => {
    if (onTokenSelect) {
      onTokenSelect(token)
    }
  }

  return (
    <div className={styles.paymentWrapper}>
      <div className={styles.inputCard}>
        <p className={styles.title}>{title}</p>
        <div className={styles.inputWrapper}>
          <input
            name={name}
            value={value}
            type={type}
            disabled={disabled}
            autoComplete={autoComplete}
            placeholder={placeholder}
            className={styles.amountInput}
            onChange={handleInputChange}
          />
          {tokens.length === 1 ? (
            <TokenButton token={selectedToken} />
          ) : (
            <TokenSelect selected={selectedToken} tokens={tokens} onSelect={handleTokenSelect} />
          )}
        </div>
        <div
          className={cx(styles.balanceAmount, {[styles.quoteCurrency]: !isBaseCurrency})}
          onClick={handleBalanceClick}
        >
          Balance: {selectedToken.formatUnits(balance)}
        </div>
        {error && <div className={styles.error}>{error}</div>}
      </div>
      {isBaseCurrency && (
        <div className={styles.direction} onClick={onSwitchDirection}>
          <img src={ARROW_DOWN_ICON_URL} alt="" />
        </div>
      )}
    </div>
  )
}

export default PaymentInput
