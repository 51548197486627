import { useNavigate } from 'react-router-dom'

import WalletAuth from 'components/WalletAuth'
import CredsAuth from 'components/CredsAuth'
import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'

import styles from './styles.module.scss'
import BackLinkUi from 'components/UI/BackLinkUi'

const SignIn = () => {
  const navigate = useNavigate()

  const handleForgotPasswordRedirect = () => {
    navigate('/forgot-password')
  }

  const handleSuccess = () => {
    navigate('/dashboard')
  }

  return (
    <MainContainerUi>
      <h1>Sign In</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.formWrapper}>
            <div className={styles.connectBtns}>
              <WalletAuth onSuccess={handleSuccess} />
            </div>
            <div className={styles.separator}>OR</div>
            <CredsAuth onSuccess={handleSuccess} />
            <BackLinkUi onClick={handleForgotPasswordRedirect} text="Forgotten password?" />
          </div>

        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default SignIn
