import CardUi from 'components/UI/CardUi'
import CustodianBarChart from 'components/Charts/CustodianBarChart'

import styles from '../styles.module.scss'

const CustodianBalanceChart = () => {
  return (
    <div className={styles.custodiansWrapper}>
      <CardUi>
        <p className={styles.statHeader}>Custodian Balance</p>
        <CustodianBarChart />
      </CardUi>
    </div>
  )
}

export default CustodianBalanceChart
