import { NavLink } from 'react-router-dom'
import CardUi from 'components/UI/CardUi'
import styles from './styles.module.scss'

interface Props {
  icon?: string;
  title: string;
  description: string;
  url?: string;
}

const Banner = ({ icon, title, description, url }: Props) => {
  return (
    <CardUi>
      <div className={styles.wrapper}>
        <div className={styles.leftSection}>
          {icon && (
            <div className={styles.iconWrapper}>
              <img src={icon} alt="Icon" />
            </div>
          )}
          <div className={styles.textContainer}>
            <p className={styles.title}>{title}</p>
            <p className={styles.description}>{description}</p>
          </div>
        </div>

        {url && (
          <NavLink to={url} className={styles.link}>Start</NavLink>
        )}
      </div>
    </CardUi>
  )
}

export default Banner
