import { createBrowserRouter } from 'react-router-dom'

import Landing from 'pages/Landing'
import SignIn from 'pages/Auth/SignIn'
import Dashboard from 'pages/Dashboard'
import Statistic from 'pages/Statistic'
import Swap from 'pages/Swap'
import Claim from 'pages/Claim'
import Settings from 'pages/Settings'
import Mint from 'pages/Mint'
import Redeem from 'pages/Redeem'
import RedeemRequests from 'pages/RedeemRequests'
import SignUp from 'pages/Auth/SignUp'
import ConnectWallet from 'pages/Auth/ConnectWallet'
import WalletSignIn from 'pages/Auth/WalletSignIn'
import ForgotPassword from 'pages/RecoverPassword/ForgotPassword'
import ResetPassword from 'pages/RecoverPassword/ResetPassword'
import SumsubKyc from 'pages/SumsubKyc'
import OpenExchangeRequests from 'pages/OpenExchangeRequests'
import Testing from 'pages/Testing'

import Layout from 'components/Layout'

import ProtectedAuthRoute from './ProtectedAuthRoute'
import RedirectIfAuthorized from './RedirectIfAuthorized'
import ProtectedKycRoute from './ProtectedKycRoute'

export default createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: 'connect-wallet',
        element: (
          <ConnectWallet />
        ),
      },
      {
        path: 'wallet-sign-in',
        element: (
          <RedirectIfAuthorized>
            <WalletSignIn />
          </RedirectIfAuthorized>
        ),
      },
      {
        path: 'sign-in',
        element: (
          <RedirectIfAuthorized>
            <SignIn />
          </RedirectIfAuthorized>
        ),
      },
      {
        path: 'sign-up',
        element: (
          <SignUp />
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <ForgotPassword />
        ),
      },
      {
        path: 'reset-password',
        element: (
          <ResetPassword />
        ),
      },
      {
        path: 'kyc-verification',
        element: <SumsubKyc />,
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedAuthRoute>
            <Dashboard />
          </ProtectedAuthRoute>
        ),
      },
      {
        path: 'transparency',
        element: <Statistic />,
      },
      {
        path: 'swap',
        element: <Swap />,
      },
      {
        path: 'mint',
        element: (
          <ProtectedAuthRoute>
            <ProtectedKycRoute>
              <Mint />
            </ProtectedKycRoute>
          </ProtectedAuthRoute>
        ),
      },
      {
        path: 'redeem',
        element: (
          <ProtectedKycRoute>
            <Redeem />
          </ProtectedKycRoute>
        ),
      },
      {
        path: 'redeem-requests',
        element: (
          <ProtectedAuthRoute>
            <RedeemRequests />
          </ProtectedAuthRoute>
        ),
      },
      {
        path: 'claim',
        element: (
          <ProtectedAuthRoute>
            <Claim />
          </ProtectedAuthRoute>
        ),
      },
      {
        path: 'settings',
        element: (
          <ProtectedAuthRoute>
            <Settings />
          </ProtectedAuthRoute>
        ),
      },
      {
        path: 'open-exchange-requests',
        element: (
          <ProtectedAuthRoute>
            <OpenExchangeRequests />
          </ProtectedAuthRoute>
        ),
      },
      {
        path: 'testing',
        element: (
          <Testing />
        ),
      },
    ],
  },
])
