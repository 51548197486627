import React, { useState } from 'react'
import useForm from 'hooks/useForm'
import AuthService from 'API/AuthService'
import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import forgotPasswordValidator from 'validators/forgotPasswordValidator'

import styles from './styles.module.scss'
import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'

const ForgotPassword = () => {
  const initialFormState = { email: '' }
  const { values, errors, handleChange, validate } = useForm(initialFormState, forgotPasswordValidator)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const handleSubmit = async () => {
    if (!validate()) return

    setIsLoading(true)

    try {
      const response = await AuthService.forgotPassword({ email: values.email })
      console.log('Forgot Password response:', response)
      setIsSuccess(true)
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <MainContainerUi>
      <h1>Forgotten password?</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.formWrapper}>
            {!isSuccess ? (<>
              <h5>Find Your Account</h5>
              <p className={styles.description}>Please enter your email address to search for your account.</p>
              <InputUi
                label="Email"
                name="email"
                type="email"
                value={values.email}
                error={errors.email}
                onChange={handleChange}
                disabled={isLoading}
              />
              {error && <ErrorBannerUi text={error} />}
              <SubmitBtnUi text="Send Reset Link" isLoading={isLoading} disabled={isLoading} onClick={handleSubmit} />
            </>
            ) :
              (<div>Follow the instructions in your email</div>)
            }

          </div>
        </CardUi>
      </div>
    </MainContainerUi>

  )
}

export default ForgotPassword
