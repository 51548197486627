import { Interface } from '@ethersproject/abi'

import UNISWAP_QUOTER_ABI from './uniswapQuoter.json'

const UNISWAP_QUOTER_INTERFACE = new Interface(UNISWAP_QUOTER_ABI)

export {
  UNISWAP_QUOTER_ABI,
  UNISWAP_QUOTER_INTERFACE,
}
