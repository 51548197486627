import { Connection, ConnectionType } from 'web3/connection'

import styles from './styles.module.scss'

interface Props {
  connection: Connection
  activate: () => void
  pendingConnectionType?: ConnectionType
}

const Option = ({ connection, activate, pendingConnectionType }: Props) => {
  return (
    <div className={styles.walletBtn} onClick={!pendingConnectionType ? activate : undefined}>
      <div className={styles.iconWrapper}><img src={connection.getIcon()} alt={connection.getName()} /></div>
      {connection.getName()}
    </div>
  )
}

export default Option
