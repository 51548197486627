import QRCode from 'qrcode.react'

import SubmitBtnUi from 'components/UI/SubmitBtnUi'

import ANROID_ICON_URL from 'assets/images/icons/android.svg'
import APPLE_ICON_URL from 'assets/images/icons/apple.svg'

import styles from './styles.module.scss'
import Stepper from 'components/UI/Stepper'

interface Props {
  onNextStep: () => void;
}

const Step1 = ({ onNextStep }: Props) => (
  <div className={styles.wrapper}>
    <Stepper totalSteps={4} currentStep={1} />
    <h6>Get the Google Authenticator app</h6>
    <div className={styles.additional}>Download and install:</div>
    <div className={styles.appLinkBtns}>
      <a href='https://apps.apple.com/us/app/google-authenticator' target='_blank' className={styles.appLinkBtn}>
        <div className={styles.iconWrapper}>
          <img src={APPLE_ICON_URL} alt="" />
        </div>
        <p className={styles.text}>App store</p>
      </a>
      <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target='_blank' className={styles.appLinkBtn}>
        <div className={styles.iconWrapper}>
          <img src={ANROID_ICON_URL} alt="" />
        </div>
        <p className={styles.text}>Google Play</p>
      </a>
    </div>
    <SubmitBtnUi onClick={onNextStep} text="Next" />
  </div>
)

export default Step1
