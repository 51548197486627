import { useState, useCallback, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'

import { useActiveChainId } from 'state/network/hooks'

import useInterval from './useInterval'

import { UNISWAP_ROUTER_ADDRESS } from '@constants'
import { Token, Price } from '../entities'

import { useUniswapPool, useUniswapQuoter, useUniswapFactory, useUniswapRouter } from './useContract'
import { computeUniswapPoolAddress } from '../utils/computePoolAddress'

export function useRouterAddress() {
  const chainId = useActiveChainId()

  return useMemo(() => UNISWAP_ROUTER_ADDRESS[chainId], [chainId])
}

export interface PoolInfo {
  token0: string
  token1: string
  fee: number
  liquidity: BigNumber
  slot0: {
    feeProtocol: number
    observationCardinality: number
    observationCardinalityNext: number
    observationIndex: number
    sqrtPriceX96: BigNumber
    tick: number
    unlocked: boolean
  }
  price: Price
}

const emptyPoolInfo: PoolInfo = {
  token0: '',
  token1: '',
  fee: 0,
  liquidity: BigNumber.from(0),
  slot0: {
    feeProtocol: 0,
    observationCardinality: 1,
    observationCardinalityNext: 1,
    observationIndex: 0,
    sqrtPriceX96: BigNumber.from(0),
    tick: 0,
    unlocked: true,
  },
  price: new Price('0'),
}

export function usePoolInfo(tokenA: Token, tokenB: Token) {
  const factory = useUniswapFactory()
  const poolAddress = computeUniswapPoolAddress({
    factoryAddress: factory?.address,
    tokenA,
    tokenB,
    fee: 100,
  })
  const pool = useUniswapPool(poolAddress)
  const [poolInfo, setPoolInfo] = useState<PoolInfo>(emptyPoolInfo)

  const fetchPoolInfo = useCallback(async () => {
    if (!pool) {
      setPoolInfo(emptyPoolInfo)
      return
    }

    try {
      const [token0, token1, fee, liquidity, slot0] = await Promise.all([
        pool.token0(),
        pool.token1(),
        pool.fee(),
        pool.liquidity(),
        pool.slot0(),
      ])

      // sqrtPriceX96 value can be too short. Pad it with zeroes to get meaningful value
      const sqrtPriceX96 = slot0.sqrtPriceX96
      // if (sqrtPriceX96.toString().length < 35) {
      //   sqrtPriceX96 = BigNumber.from(sqrtPriceX96.toString().padEnd(35, '0'))
      // }

      const p = sqrtPriceX96.pow(2).div(BigNumber.from(2).pow(192))
      const price = new Price(p.toString(), Math.abs(tokenA.decimals - tokenB.decimals) || 12)

      setPoolInfo({
        token0,
        token1,
        fee,
        liquidity,
        slot0,
        price,
      })
    } catch (error) {
      setPoolInfo(emptyPoolInfo)
    }
  }, [pool])

  useInterval(fetchPoolInfo, 3000)

  return { poolInfo, fetchPoolInfo }
}

export function useCalculateSwapOutputAmount() {
  const contract = useUniswapQuoter()

  return useCallback(async (tokenA: Token, tokenB: Token, amount: BigNumber) => {
    if (!contract) {
      return BigNumber.from(0)
    }

    const res = await contract.callStatic.quoteExactInputSingle({
      tokenIn: tokenA.address,
      tokenOut: tokenB.address,
      amountIn: amount,
      fee: 100,
      sqrtPriceLimitX96: 0,
    })

    return res.amountOut
  }, [contract])
}

export function useSwap() {
  const { account } = useWeb3React()
  const contract = useUniswapRouter()
  const [processing, setProcessing] = useState(false)

  const onSwap = useCallback(async (tokenIn: Token, tokenOut: Token, amountIn: BigNumber, amountOut: BigNumber) => {
    if (!contract || !account) {
      return
    }

    setProcessing(true)
    try {
      // 0.10% slippage
      const amountOutMinimum = amountOut.mul(BigNumber.from(9990)).div(BigNumber.from(10000))

      const tx = await contract.exactInputSingle({
        tokenIn: tokenIn.address,
        tokenOut: tokenOut.address,
        fee: 100,
        recipient: account,
        amountIn,
        amountOutMinimum,
        sqrtPriceLimitX96: 0,
      })

      await tx.wait()
    } catch (error) {
      console.error(`Error swapping tokens ${error}`)
      throw error
    } finally {
      setProcessing(false)
    }
  }, [contract, account])

  return { processing, onSwap }
}
