export const formatDate = (dateString: string): string => {
  const date = new Date(dateString)

  const time = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  })

  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  })

  return `${time}, ${formattedDate}`
}

export const formatTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)

  const time = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  })

  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  })

  return `${time}, ${formattedDate}`
}
