import { ReactNode } from 'react'

import styles from './styles.module.scss'

interface Props {
  children: ReactNode
}

const CardUi = ({ children }: Props) => {
  return (
    <div className={styles.wrapper}>{children}</div>
  )
}

export default CardUi