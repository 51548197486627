import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SumsubWebSdk from '@sumsub/websdk-react'
import UserService from 'API/UserService'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import MainContainerUi from 'components/UI/MainContainerUi'
import styles from './styles.module.scss'
import LoadingBannerUi from 'components/UI/LoadingBannerUi'
import { useIsUserVerified } from 'state/user/hooks'

const SumsubKyc = () => {
  const isUserVerified = useIsUserVerified()
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (isUserVerified) {
      navigate('/mint')
    }
  }, [isUserVerified, navigate])

  useEffect(() => {
    const fetchAccessToken = async () => {
      setIsLoading(true)
      try {
        const response = await UserService.getSumsubAccessToken()
        setAccessToken(response.access_token)
      } catch (err: any) {
        setError('Failed to get access token')
      } finally {
        setIsLoading(false)
      }
    }

    fetchAccessToken()
  }, [])

  const accessTokenExpirationHandler = async (): Promise<any> => {
    try {
      const response = await UserService.getSumsubAccessToken()
      return response.access_token
    } catch (err: any) {
      setError('Failed to renew access token')
      return null
    }
  }

  const messageHandler = (type: string, payload: any) => {
    console.log('Sumsub message:', type, payload)
  }

  const errorHandler = (error: any) => {
    console.error('Sumsub error:', error)
    setError('An error occurred during KYC process')
  }

  return (
    <MainContainerUi>
      <div className={styles.wrapper}>
        {isLoading && <LoadingBannerUi />}
        {error && <ErrorBannerUi text={error} />}
        {!isLoading && accessToken && (
          <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={accessTokenExpirationHandler as any}
            onMessage={messageHandler}
            onError={errorHandler}
          />
        )}
      </div>
    </MainContainerUi>
  )
}

export default SumsubKyc
