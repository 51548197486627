import styles from './styles.module.scss'

interface Props {
  text: string
  onClick: () => void
}

const Button = ({ text, onClick }: Props) => {
  return (
    <div className={styles.button} onClick={onClick} >
      {text}
    </div>
  )
}

export default Button
