import { Interface } from '@ethersproject/abi'

import AEGIS_MINTING_ABI from './aegisMinting.json'

const AEGIS_MINTING_INTERFACE = new Interface(AEGIS_MINTING_ABI)

export {
  AEGIS_MINTING_ABI,
  AEGIS_MINTING_INTERFACE,
}
