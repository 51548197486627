import React, { useState } from 'react'
import UserService from 'API/UserService'
import styles from './styles.module.scss'

interface Props {
  email: string;
}

const SendNotificationEmailCode = ({ email }: Props) => {
  const [status, setStatus] = useState<'idle' | 'loading' | 'sent' | 'error'>('idle')
  const [error, setError] = useState<string | null>(null)

  const handleClick = async () => {
    if (status === 'loading' || status === 'sent') return

    setStatus('loading')
    setError(null)
    try {
      const response = await UserService.sendNotificationConfirmationCode({ email })
      if (response.status === 'success') {
        setStatus('sent')
      } else {
        setStatus('error')
        setError('Server error')
      }
    } catch (error) {
      console.error(error)
      setStatus('error')
      setError('Error sending code')
    }
  }

  return (
    <>
      <div
        onClick={handleClick}
        className={`${styles.sendCodeButton} ${status === 'loading' || status === 'sent' ? styles.disabled : ''}`}
      >
        {status === 'loading' ? 'Loading...' : status === 'sent' ? 'Code sent' : 'Send verification code'}
      </div>
      {status === 'error' && <div style={{ color: 'red' }}>{error}</div>}
    </>
  )
}

export default SendNotificationEmailCode
