import { useMemo } from 'react'
import { Contract } from 'ethers'
import { useWeb3React } from '@web3-react/core'

import { useActiveChainId } from 'state/network/hooks'

import { getContract } from 'web3/utils'
import { useGetConnection } from 'web3/connection'
import {
  AEGIS_MINTING_ABI,
  HOLDING_REWARDS_ABI,
  ERC20_ABI,
  UNISWAP_FACTORY_ABI,
  UNISWAP_POOL_ABI,
  UNISWAP_ROUTER_ABI,
  UNISWAP_QUOTER_ABI,
  CURVE_FACTORY_ABI,
  CURVE_MATH_ABI,
  CURVE_PLAIN_POOL_ABI,
  CURVE_VIEWS_ABI,
} from 'constants/abis'
import {
  AegisMinting,
  HoldingRewards,
  Erc20,
  UniswapFactory,
  UniswapPool,
  UniswapRouter,
  UniswapQuoter,
  CurveFactory,
  CurveMath,
  CurvePlainPool,
  CurveViews,
} from 'constants/abis/types'

import {
  AEGIS_MINTING_ADDRESS,
  HOLDING_REWARDS_ADDRESS,
  UNISWAP_FACTORY_ADDRESS,
  UNISWAP_ROUTER_ADDRESS,
  UNISWAP_QUOTER_ADDRESS,
  CURVE_FACTORY_ADDRESS,
  CURVE_MATH_ADDRESS,
  CURVE_VIEWS_ADDRESS,
} from '../constants'

export function useContract<T extends Contract = Contract>(address: string | undefined, ABI: any, withSignerIfPossible = true): T | null {
  const { provider, account, chainId } = useWeb3React()
  const getConnection = useGetConnection()

  return useMemo(() => {
    if (!address || !ABI || !provider) {
      return null
    }

    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, provider, withSignerIfPossible, account, chainId, getConnection ]) as T
}

export function useERC20Token(address: string | undefined) {
  return useContract<Erc20>(address, ERC20_ABI)
}

export function useAegisMinting() {
  const chainId = useActiveChainId()
  return useContract<AegisMinting>(AEGIS_MINTING_ADDRESS[chainId], AEGIS_MINTING_ABI)
}

export function useHoldingRewards() {
  const chainId = useActiveChainId()
  return useContract<HoldingRewards>(HOLDING_REWARDS_ADDRESS[chainId], HOLDING_REWARDS_ABI)
}

export function useUniswapFactory() {
  const chainId = useActiveChainId()
  return useContract<UniswapFactory>(UNISWAP_FACTORY_ADDRESS[chainId], UNISWAP_FACTORY_ABI)
}

export function useUniswapRouter() {
  const chainId = useActiveChainId()
  return useContract<UniswapRouter>(UNISWAP_ROUTER_ADDRESS[chainId], UNISWAP_ROUTER_ABI)
}

export function useUniswapPool(address: string) {
  return useContract<UniswapPool>(address, UNISWAP_POOL_ABI)
}

export function useUniswapQuoter() {
  const chainId = useActiveChainId()
  return useContract<UniswapQuoter>(UNISWAP_QUOTER_ADDRESS[chainId], UNISWAP_QUOTER_ABI)
}

export function useCurveFactory() {
  const chainId = useActiveChainId()
  return useContract<CurveFactory>(CURVE_FACTORY_ADDRESS[chainId], CURVE_FACTORY_ABI)
}

export function useCurvePlainPool(address: string) {
  return useContract<CurvePlainPool>(address, CURVE_PLAIN_POOL_ABI)
}

export function useCurveMath() {
  const chainId = useActiveChainId()
  return useContract<CurveMath>(CURVE_MATH_ADDRESS[chainId], CURVE_MATH_ABI)
}

export function useCurveViews() {
  const chainId = useActiveChainId()
  return useContract<CurveViews>(CURVE_VIEWS_ADDRESS[chainId], CURVE_VIEWS_ABI)
}
