import { useState, useEffect } from 'react'

import UserService from 'API/UserService'

import SuccessUi from 'components/UI/SuccessUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'

import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'

import styles from './styles.module.scss'

const Setup2Fa = () => {
  const [step, setStep] = useState(1)
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null)
  const [secret, setSecret] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetch2FaSetup = async () => {
      try {
        setLoading(true)
        const { url, secret } = await UserService.setup2Fa()
        setQrCodeUrl(url)
        setSecret(secret)
      } catch (err) {
        setError(JSON.stringify(err) || 'Failed to load QR code')
      } finally {
        setLoading(false)
      }
    }

    fetch2FaSetup()
  }, [])

  const handleNextStep = () => {
    setStep((prevStep) => Math.min(prevStep + 1, 5))
  }

  const handlePrevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1))
  }

  const renderStep = () => {
    if (loading) {
      return <div>Loading...</div>
    }

    if (error) {
      return <ErrorBannerUi text={error} />
    }

    switch (step) {
    case 1:
      return (
        <Step1 onNextStep={handleNextStep} />
      )
    case 2:
      return (
        <Step2
          secret={secret}
          qrCodeUrl={qrCodeUrl}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
        />
      )
    case 3:
      return (
        <Step3
          secret={secret}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
        />
      )
    case 4:
      return (
        <Step4
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
        />
      )
    case 5:
      return (
        <SuccessUi
          text="2FA added succesfully"
          link="/settings"
          linkText=" "
        />
      )
    default:
      return (
        <Step1 onNextStep={handleNextStep} />
      )
    }
  }

  return <div className={styles.wrapper}>{renderStep()}</div>
}

export default Setup2Fa
