import React, { useState, useEffect, useMemo } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import styles from './styles.module.scss'

HighchartsMore(Highcharts)

interface DataPoint {
  t: number;
  c: string;
}

interface WeightChartProps {
  data: DataPoint[];
}

const WeightChart: React.FC<WeightChartProps> = ({ data }) => {
  const [key, setKey] = useState<number>(0)

  const chartData = useMemo(() => {
    return data.map(item => ({
      x: new Date(item.t * 1000).getTime(),
      y: parseFloat(item.c),
    }))
  }, [data])

  const options = useMemo<Highcharts.Options>(() => ({
    chart: {
      zoomType: 'x',
      backgroundColor: undefined,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          color: '#8B8B8B',
        },
        formatter: function () {
          return Highcharts.dateFormat('%b %e', this.value as any)
        },
      },
      lineColor: '#8B8B8B',
      tickColor: '#8B8B8B',
    },
    yAxis: {
      title: {
        text: 'Funding rate',
        style: {
          color: '#8B8B8B',
        },
      },
      labels: {
        style: {
          color: '#8B8B8B',
        },
      },
      lineColor: '#8B8B8B',
      tickColor: '#8B8B8B',
      gridLineColor: '#8B8B8B',
      plotLines: [{
        value: 0,
        color: '#0071A1',
        width: 1,
        zIndex: 5,
      }],
      gridLineWidth: 0,
    },
    tooltip: {
      shared: true,
      valueDecimals: 6,
      backgroundColor: '#3C404B',
      style: {
        color: '#FFFFFF',
      },
    },
    series: [{
      name: 'Closing Price',
      data: chartData,
      marker: {
        enabled: false,
      },
      lineWidth: 0.8,
      color: '#AD99FF',
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#AD99FF'],
          [1, '#846af700'],
        ],
      },
      type: 'area',
      zIndex: 0,
    }],
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: true,
      barBackgroundColor: '#76849958',
      barBorderColor: '#76849958',
      barBorderWidth: 0.1,
      buttonBorderWidth: 0.1,
      buttonArrowColor: '#FFFFFF',
      trackBackgroundColor: '#76849958',
      trackBorderColor: '#76849958',
      trackBorderWidth: 0.1,
      buttonBackgroundColor: '#76849958',
      buttonBorderColor: '#76849958',
      rifleColor: '#76849958',
      style: {
        color: '#76849958',
        fontSize: '12px',
      },
    },
    rangeSelector: {
      enabled: true,
      buttons: [{
        type: 'day',
        count: 1,
        text: '1d',
      }, {
        type: 'week',
        count: 1,
        text: '1w',
      }, {
        type: 'month',
        count: 1,
        text: '1m',
      }, {
        type: 'all',
        text: 'All',
      }],
      selected: 3,
    },
    navigator: {
      enabled: true,
      xAxis: {
        gridLineColor: '#FF0000',
        gridLineWidth: 0.1,
        tickmarkPlacement: 'on',
        labels: {
          style: {
            color: '#FFFFFF',
          },
        },
      },
      handles: {
        backgroundColor: '#f0f0f016',
        borderColor: '#f0f0f0',
      },
      outlineColor: 'none',
      outlineWidth: 0.1,
      maskFill: '#ad99ff31',
      series: {
        color: '#AD99FF',
        fillOpacity: 0.3,
      },
    },
    legend: {
      enabled: false,
    },
  }), [chartData])

  useEffect(() => {
    setKey(prevKey => prevKey + 1)
  }, [data])

  return (
    <div className={styles.wrapper}>
      <HighchartsReact
        key={key}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default React.memo(WeightChart)
