import { useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import MintingService from 'API/MintingService'

import { useRedeem } from 'hooks/useMinting'
import { useGetTokenMintingAllowance, useApproveTokenMinting } from 'hooks/useToken'
import { useTokenBalance } from 'hooks/useBalance'

import { Token, Price } from '@entities'
import { type RedeemRequest, RedeemRequestStatus } from '@types'

import styles from './styles.module.scss'

interface Props {
  item: RedeemRequest
  collateralToken: Token
  usdaToken: Token
}

const RedeemStats = ({ item, collateralToken, usdaToken }: Props) => {
  const { provider } = useWeb3React()

  const { processing: processingApprove, onApprove } = useApproveTokenMinting(usdaToken)
  const { processing: processingRedeem, onRedeem } = useRedeem()
  const usdaBalance = useTokenBalance(usdaToken)
  const getUSDaTokenMintingAllowance = useGetTokenMintingAllowance(usdaToken)

  const [isLoading, setIsloading] = useState(false)

  const canRedeem = useMemo(() => {
    return item.status === RedeemRequestStatus.DEPOSITED && usdaBalance.gte(item.details.usda_amount) && !(processingApprove || processingRedeem)
  }, [processingRedeem, processingApprove, usdaBalance, item.status, item.details.usda_amount])

  const renderStatus = () => {
    if (item.status === RedeemRequestStatus.WAITING_DEPOSIT) {
      return 'Waiting deposit'
    }

    if (item.status === RedeemRequestStatus.DEPOSITED) {
      return 'Deposited'
    }

    return 'Completed'
  }

  const handleRedeem = async () => {
    if (!provider || !canRedeem) {
      return
    }

    setIsloading(true)

    try {
      const signature = await provider.getSigner().signMessage(item.request_id)

      const resp = await MintingService.redeem(item.request_id, signature)

      const allowance = await getUSDaTokenMintingAllowance()
      if (allowance.lt(resp.data.order.usda_amount)) {
        await onApprove()
      }

      await onRedeem(resp.data.order, resp.data.signature)
    } catch (error) {
      console.error(`Error processing redeem ${error}`)
    } finally {
      setIsloading(false)
    }
  }

  return (
    <div className={styles.item}>
      <div className={styles.status}>
        <span>Status:</span> {renderStatus()}
      </div>
      <div className={styles.details}>
        <div className={styles.dataWrapper}>
          <p className={styles.header}>Request ID</p>
          <div className={styles.value}>
            <small>{item.request_id}</small>
          </div>
        </div>
        <div className={styles.dataWrapper}>
          <p className={styles.header}>Collateral Amount</p>
          <div className={styles.value}>
            {collateralToken.formatUnits(item.details.collateral_amount)} <span className={styles.currency}>{collateralToken.symbol}</span>
          </div>
        </div>
        <div className={styles.dataWrapper}>
          <p className={styles.header}>USDa Amount</p>
          <div className={styles.value}>
            {usdaToken.formatUnits(item.details.usda_amount)} <span className={styles.currency}>USDa</span>
          </div>
        </div>
        <div className={styles.dataWrapper}>
          <p className={styles.header}>USDa Price</p>
          <div className={styles.value}>
            {Price.from(item.details.price).invert().format()} <span className={styles.currency}>{collateralToken.symbol}</span>
          </div>
        </div>
      </div>

      {item.status !== RedeemRequestStatus.COMPLETED ? (
        <button className={styles.button} disabled={!canRedeem} onClick={handleRedeem}>
          { isLoading ? 'Loading...' : 'Redeem'}
        </button>
      ): null}
    </div>
  )
}

export default RedeemStats
