import { useState, useCallback, ChangeEvent } from 'react'

const useForm = (initialValues: any, validators: any) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>({})

  const validate = useCallback(() => {
    const newErrors: any = {}
    Object.keys(validators).forEach((key) => {
      if (validators[key].required && !values[key]) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} cannot be empty.`
      } else if (validators[key].pattern && !validators[key].pattern.test(values[key])) {
        newErrors[key] = validators[key].errorMessage
      } else if (validators[key].minLength && values[key].length < validators[key].minLength) {
        newErrors[key] = validators[key].errorMessage
      } else if (validators[key].custom && !validators[key].custom(values)) {
        newErrors[key] = validators[key].errorMessage
      }
    })

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }, [values, validators])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }))
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: null,
    }))
  }, [])

  return {
    values,
    errors,
    handleChange,
    validate,
    setValues,
  }
}

export default useForm
