import { useState, useCallback, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'

import AuthService from 'API/AuthService'
import { setAuthToken, setUserDetails } from 'state/user/reducer'

import { useSignAuthMessage } from 'hooks/useSignature'

import ConnectButtons from 'components/ConnectButtons'
import LoadingBannerUi from 'components/UI/LoadingBannerUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import TwoFactorVerification from 'components/TwoFactorVerification'

import styles from './styles.module.scss'

interface Props {
  onSuccess: () => void;
}

const WalletAuth = ({ onSuccess }: Props) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const signAuthMessage = useSignAuthMessage()

  const [signature, setSignature] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [twoFaEnabled, setTwoFaEnabled] = useState<boolean>(false)
  const [twoFaCode, setTwoFaCode] = useState<string>('')

  const requestSignature = useCallback(async () => {
    if (!account) {
      return
    }

    setIsLoading(true)
    setError(null)
    try {
      const signature = await signAuthMessage()
      setSignature(signature)
      await sendToServer(account, signature)
    } catch (error: any) {
      if (error.code === 4001 || error.code === 'ACTION_REJECTED') {
        setError('Action rejected')
      } else {
        setError(JSON.stringify(error.message) || 'Failed to sign message')
      }
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [account, signAuthMessage])

  const sendToServer = async (ethereumAddress: string, signature: string) => {
    const params = {
      auth_type: 'ethereum_address',
      ethereum_address: ethereumAddress,
      password: signature,
    }

    try {
      const responsePrecheck = await AuthService.signInPrecheck(params)

      if (responsePrecheck.data.twofa_enabled) {
        setTwoFaEnabled(true)
      } else {
        await signIn(params)
      }
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    }
  }

  const signIn = async (params: { auth_type: string; ethereum_address: string; password: string }, code?: string) => {
    try {
      const responseSignIn = await AuthService.signIn({
        ...params,
        code,
      })
      dispatch(setAuthToken(responseSignIn.data.token))
      dispatch(setUserDetails(responseSignIn.data.user))
      onSuccess()
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    }
  }

  const handleTwoFaSubmit = async () => {
    const params = {
      auth_type: 'ethereum_address',
      ethereum_address: account!,
      password: signature,
    }
    await signIn(params, twoFaCode)
  }

  const handleTwoFaCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTwoFaCode(event.target.value)
  }

  return (
    <div className={styles.wrapper}>
      {account && isLoading && <LoadingBannerUi />}
      {!twoFaEnabled ? (
        <div className={styles.connectBtns}>
          {error && <ErrorBannerUi text={error} />}
          <ConnectButtons onConnect={requestSignature} />
        </div>
      ) : (
        <TwoFactorVerification
          twoFaCode={twoFaCode}
          handleTwoFaCodeChange={handleTwoFaCodeChange}
          handleTwoFaSubmit={handleTwoFaSubmit}
          isLoading={isLoading}
          error={error}
        />
      )}
    </div>
  )
}

export default WalletAuth
