import { ChainId } from 'web3/chains'

import { Token } from 'entities/token'
import { TokenSymbol } from 'entities/types'

import { computeCurvePoolAddressKey } from 'utils/computePoolAddress'

export const AEGIS_MINTING_ADDRESS: { [key in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.SEPOLIA]: '',
  [ChainId.AEGISNET]: '0xF5316e854a3F4c57aaE6beCa232B8b23a67fB3Bd',
  [ChainId.LOCAL]: '0x2fcebBA17F00f909B50CBd2ab9009A4Ff9CaC494',
}

export const HOLDING_REWARDS_ADDRESS: { [key in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.SEPOLIA]: '',
  [ChainId.AEGISNET]: '0xE6565F236E352D18f48F3c80e222333Dc024373e',
  [ChainId.LOCAL]: '0x0e224678d4125970A238b5f0F2DF6100C7fE0a47',
}

export const USDA_TOKEN: { [key in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token('', 18, TokenSymbol.USDA, 'USDa'),
  [ChainId.SEPOLIA]: new Token('', 18, TokenSymbol.USDA, 'USDa'),
  [ChainId.AEGISNET]: new Token('0x62Bd0Eb66F9b8c5cBbd72DC942DDC78FAabEb86F', 18, TokenSymbol.USDA, 'USDa'),
  [ChainId.LOCAL]: new Token('0x6B94B0c3305bcEf95E106Bd4D506936744c108eb', 18, TokenSymbol.USDA, 'USDa'),
}

export const USDT_TOKEN: { [key in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token('', 6, TokenSymbol.USDT, 'Tether USD'),
  [ChainId.SEPOLIA]: new Token('', 6, TokenSymbol.USDT, 'Tether USD'),
  [ChainId.AEGISNET]: new Token('0x4CE6563330166122DBe372Ad7ec175b7783a0FC0', 6, TokenSymbol.USDT, 'Tether USD'),
  [ChainId.LOCAL]: new Token('0x2E0496348836600BBa4616bD94fA5d22725e024C', 6, TokenSymbol.USDT, 'Tether USD'),
}

export const USDC_TOKEN: { [key in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token('', 6, TokenSymbol.USDC, 'USD Coin'),
  [ChainId.SEPOLIA]: new Token('', 6, TokenSymbol.USDC, 'USD Coin'),
  [ChainId.AEGISNET]: new Token('0x03201Dcb4af8Bca7c73a06C32031482Ecdcc2833', 6, TokenSymbol.USDC, 'USD Coin'),
  [ChainId.LOCAL]: new Token('0xc3520fF1142d4688e4d1aAA49e5D54589246E5e3', 6, TokenSymbol.USDC, 'USD Coin'),
}

export const DAI_TOKEN: { [key in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token('', 18, TokenSymbol.DAI, 'Dai Stablecoin'),
  [ChainId.SEPOLIA]: new Token('', 18, TokenSymbol.DAI, 'Dai Stablecoin'),
  [ChainId.AEGISNET]: new Token('0x0DA7C7E1b61c767d379373B4dda9Ff5734f5cf1b', 18, TokenSymbol.DAI, 'Dai Stablecoin'),
  [ChainId.LOCAL]: new Token('0xDd95Ac8a33940AEE9BfDBdD9903A3B0d232366BD', 18, TokenSymbol.DAI, 'Dai Stablecoin'),
}

export const UNISWAP_FACTORY_ADDRESS: { [key in ChainId]: string} = {
  [ChainId.MAINNET]: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
  [ChainId.SEPOLIA]: '0x0227628f3F023bb0B980b67D528571c95c6DaC1c',
  [ChainId.AEGISNET]: '0x034AbBDFF4f45AFf6494b7E2f13729dDA2882D56',
  [ChainId.LOCAL]: '0xb87edf2b7Ef6645af0bD541a1b67a7aEdb5AB6E2',
}

export const UNISWAP_ROUTER_ADDRESS: { [key in ChainId]: string} = {
  [ChainId.MAINNET]: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
  [ChainId.SEPOLIA]: '0x3bFA4769FB09eefC5a80d6E87c3B9C650f7Ae48E',
  [ChainId.AEGISNET]: '0xC88d97F5B623fC4E2D0584fB6dC8AB3c7B597124',
  [ChainId.LOCAL]: '0x9B8Ce15016887CE3C60898e5328ccFA1683e6E35',
}

export const UNISWAP_QUOTER_ADDRESS: { [key in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.SEPOLIA]: '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3',
  [ChainId.AEGISNET]: '0x2E76A415309FcE676206245Ede6A6D9C1fD02598',
  [ChainId.LOCAL]: '0xeC9f8CE711D66946456677D4eEC9267AF6500A9D',
}

// export const UNISWAP_POOL_ADDRESSES: { [key in ChainId]: { [key: string]: string}} = {
//   [ChainId.MAINNET]: {},
//   [ChainId.SEPOLIA]: {
//     ['USDT-USDA']: '0xd8D8BF5d93808863A6E2e7989E4B6573ff9040Fe',
//     ['USDC-USDA']: '',
//     ['DAI-USDA']: '',
//   },
//   [ChainId.AEGISNET]: {
//     ['USDT-USDA']: '0xddff4dfddeae22e35315f465b69273cbed6cabf7',
//     ['USDC-USDA']: '0x4cdffc5705684e96c5122697040f68493ba6a1b9',
//     ['DAI-USDA']: '0x1d3bc086136b138e58a3b031ac063260cfc53248',
//   },
//   [ChainId.LOCAL]: {
//     ['USDT-USDA']: '0xed95e24508b7c8a27b7fb2d42c2cb4d829213e66',
//     ['USDC-USDA']: '0x9060d7c0de15553a609d80111d40b1a3028a9854',
//     ['DAI-USDA']: '0x13b2a72d48693ded23d7fbd1f07cca3077bfa1cc',
//   },
// }

export const CURVE_FACTORY_ADDRESS: { [key in ChainId]: string} = {
  [ChainId.MAINNET]: '0x6A8cbed756804B16E05E741eDaBd5cB544AE21bf',
  [ChainId.SEPOLIA]: '0xfb37b8D939FFa77114005e61CFc2e543d6F49A81',
  [ChainId.AEGISNET]: '0x6290Fd90aD0264b6bEA7E7f76E5d55Caa532A98c',
  [ChainId.LOCAL]: '0x88b6A64f8bB9940358f292eae234Bc0Eb9E0beeF',
}

export const CURVE_PLAIN_POOL_ADDRESS: { [key in ChainId]: { [key: string]: string}} = {
  [ChainId.MAINNET]: {},
  [ChainId.SEPOLIA]: {
    ['USDA-USDT']: '0x40284530173f6021F93dfFe6182597cfe8759A0b',
  },
  [ChainId.AEGISNET]: {
    [computeCurvePoolAddressKey(USDA_TOKEN[ChainId.AEGISNET], USDT_TOKEN[ChainId.AEGISNET])]: '0xFd27a85Aa95f0Ed9FEf4107a6782512CCF92DB7a',
    [computeCurvePoolAddressKey(USDA_TOKEN[ChainId.AEGISNET], USDC_TOKEN[ChainId.AEGISNET])]: '0xE284Bae338B224815c2cA838132a2E6F88bBA0B7',
    [computeCurvePoolAddressKey(USDA_TOKEN[ChainId.AEGISNET], DAI_TOKEN[ChainId.AEGISNET])]: '0x7Ad19A6070F67f05E19369752eA5E6c9AFae6A87',
  },
  [ChainId.LOCAL]: {
    [computeCurvePoolAddressKey(USDA_TOKEN[ChainId.LOCAL], USDT_TOKEN[ChainId.LOCAL])]: '0xdeA1D4599f631F74f1bddAe338779BfD24270f00',
    [computeCurvePoolAddressKey(USDA_TOKEN[ChainId.LOCAL], USDC_TOKEN[ChainId.LOCAL])]: '0x14AB87aa56a05832E1b0109d1c6ae8F964144d42',
    [computeCurvePoolAddressKey(USDA_TOKEN[ChainId.LOCAL], DAI_TOKEN[ChainId.LOCAL])]: '0x57A6c0Ecc9f0F31560f00eBD3ad373CeA4Fc2190',
  },
}

export const CURVE_MATH_ADDRESS: { [key in ChainId]: string} = {
  [ChainId.MAINNET]: '0xc9CBC565A9F4120a2740ec6f64CC24AeB2bB3E5E',
  [ChainId.SEPOLIA]: '0x2cad7b3e78e10bcbf2cc443ddd69ca8bcc09a758',
  [ChainId.AEGISNET]: '0x2b6a1F8a441eAA837F1e018EC28887090da20C71',
  [ChainId.LOCAL]: '0xf1FD7C12d9015a01Cf9B2cEC088bF4D4Ff0206b6',
}

export const CURVE_VIEWS_ADDRESS: { [key in ChainId]: string} = {
  [ChainId.MAINNET]: '0x13526206545e2DC7CcfBaF28dC88F440ce7AD3e0',
  [ChainId.SEPOLIA]: '0x9d3975070768580f755D405527862ee126d0eA08',
  [ChainId.AEGISNET]: '0xD8E9caB56BEF7841A2287DC051d4f2425FA3B437',
  [ChainId.LOCAL]: '0xfb9c5a0F9169e54Fb883A168c231c878f73BBA22',
}
