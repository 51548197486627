import React, { FormEvent } from 'react'
import styles from './styles.module.scss'

interface Props {
  text: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick: (e: FormEvent) => void;
}

const SubmitBtnUi = ({ text, isLoading = false, disabled = false, onClick }: Props) => {
  return (
    <button
      className={styles.submitButton}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? 'Loading...' : text}
    </button>
  )
}

export default SubmitBtnUi
