import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useIsUserAuthorized } from 'state/user/hooks'

interface Props {
  children: ReactNode;
}

const ProtectedAuthRoute = ({ children }: Props) => {
  const isUserAuthorized = useIsUserAuthorized()

  if (!isUserAuthorized) {
    return (
      <Navigate
        to="/wallet-sign-in"
        state={{ error: 'Please log in to continue' }}
        replace
      />
    )
  }

  return <>{children}</>
}

export default ProtectedAuthRoute
