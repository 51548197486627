const changeEmailValidator = {
  newEmail: {
    required: true,
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: 'Invalid email format',
  },
  newEmailCode: {
    required: true,
    errorMessage: 'New email verification code is required',
  },
  currentEmailCode: {
    required: true,
    errorMessage: 'Current email verification code is required',
  },
}

export default changeEmailValidator
