import { useState, useCallback, ChangeEvent } from 'react'
import { useUserDetails } from 'state/user/hooks'

import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import SuccessUi from 'components/UI/SuccessUi'

import useForm from 'hooks/useForm'
import UserService from 'API/UserService'
import changePasswordValidator from 'validators/changePasswordValidator'

const ChangePassword = () => {
  const userDetails = useUserDetails()
  const initialFormState = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    code: '',
  }

  const { values, errors, handleChange, validate } = useForm(initialFormState, changePasswordValidator)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e)
    setError(null)
  }, [handleChange])

  const validateForm = () => {
    const isValid = validate()
    if (!isValid) {
      setError('Please fix the errors in the form.')
    }
    return isValid
  }

  const handleSubmit = async () => {
    if (!validateForm()) return

    setIsLoading(true)

    try {
      const { oldPassword, newPassword, code } = values
      const response = await UserService.changePassword({
        currentPassword: oldPassword,
        newPassword,
        code: userDetails.twofa_enabled ? code : undefined,
      })

      if (response.status === 'success') {
        setIsSuccess(true)
      } else {
        setError('Failed to change password')
      }
    } catch (err) {
      console.error(err)
      setError(JSON.stringify(err) || 'Failed to send data to server')
    } finally {
      setIsLoading(false)
    }
  }

  if (isSuccess) {
    return (
      <SuccessUi
        text="Your password has been successfully changed."
        link="/dashboard"
        linkText=" "
      />
    )
  }

  return (
    <div>
      <InputUi
        label="Old Password"
        name="oldPassword"
        value={values.oldPassword}
        type="password"
        placeholder="Please enter your current password"
        onChange={handleInputChange}
        error={errors.oldPassword}
      />
      <InputUi
        label="New Password"
        name="newPassword"
        value={values.newPassword}
        type="password"
        placeholder="Please enter a new password"
        onChange={handleInputChange}
        error={errors.newPassword}
      />
      <InputUi
        label="Confirm New Password"
        name="confirmNewPassword"
        value={values.confirmNewPassword}
        type="password"
        placeholder="Please enter your new password again"
        onChange={handleInputChange}
        error={errors.confirmNewPassword}
      />
      {userDetails.twofa_enabled && (
        <InputUi
          label="Google 2FA Code"
          name="code"
          value={values.code}
          type="text"
          placeholder="Google 2FA code"
          onChange={handleInputChange}
          error={errors.code}
        />
      )}
      {error && <ErrorBannerUi text={error} />}
      <SubmitBtnUi
        text="Submit"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={handleSubmit}
      />
    </div>
  )
}

export default ChangePassword
