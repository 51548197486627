import { useExchangeRates } from 'hooks/useStats'

import CardUi from 'components/UI/CardUi'
import ExchangeRatesChart from 'components/Charts/ExchangeRatesChart'

import styles from '../styles.module.scss'

const BTCFundingRateChart = () => {
  const data = useExchangeRates()

  return (
    <div className={styles.highchartsWrapper}>
      <CardUi>
        <div className={styles.headerWrapper}>
          <p className={styles.statHeader}>BTC Funding Rate History Chart</p>
        </div>

        <ExchangeRatesChart data={data} />
      </CardUi>
    </div>
  )
}

export default BTCFundingRateChart
