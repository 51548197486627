import api from './api'

const CommonService = {
  fetchProjectStats: async () => {
    const { data } = await api.get('project-stats')

    return data.data
  },
  generateExchangeDataRequest: async (exchange: string, endpoint: string) => {
    const { data } = await api.post('generate-exchange-data-request', { exchange, endpoint })

    return data.data
  },
}

export default CommonService
