
const signUpValidator = {
  email: {
    required: true,
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: 'Invalid email format',
  },
  password: {
    required: true,
    errorMessage: 'Password cannot be empty',
  },
  confirmPassword: {
    required: true,
    custom: (values: any) => values.password === values.confirmPassword,
    errorMessage: 'Passwords do not match',
  },
}

export default signUpValidator
