import { useState, useCallback } from 'react'

import { useHoldingRewards } from './useContract'
import { ethers } from 'ethers'

export function useClaimRewards() {
  const contract = useHoldingRewards()
  const [processing, setProcessing] = useState(false)

  const onClaimRewards = useCallback(async (snapshotIds: string[], amounts: string[], signature: string) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.claimRewards(
        snapshotIds.map(id => ethers.utils.formatBytes32String(id)),
        amounts,
        signature,
      )
      await tx.wait()
    } catch (error) {
      console.error(`Error sending claimRewards transaction ${error}`)
    } finally {
      setProcessing(false)
    }
  }, [contract])

  return { processing, onClaimRewards }
}