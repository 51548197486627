import { useState } from 'react'

import TOOLTIP_ICON_URL from 'assets/images/icons/tooltip.svg'

import styles from './styles.module.scss'

interface Props {
  text: string;
}

const TooltipUi = ({ text }: Props) => {
  const [visible, setVisible] = useState(false)

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className={styles.tooltipCircle}>
        <img src={TOOLTIP_ICON_URL} alt="" />
      </div>
      {visible && <div className={styles.tooltipText}>{text}</div>}
    </div>
  )
}

export default TooltipUi
