import CardUi from 'components/UI/CardUi'
import Chart from 'components/Charts/OpenPositionChart'

import styles from '../styles.module.scss'

const OpenPositionChart = () => {
  return (
    <div className={styles.openPositionWrapper}>
      <CardUi>
        <p className={styles.statHeader}>Open Position</p>
        <Chart />
      </CardUi>
    </div>
  )
}

export default OpenPositionChart
