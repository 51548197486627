import api from './api'

interface SignInPrecheck {
  auth_type: string;
  email?: string;
  ethereum_address?: string;
  password: string;
}

interface SignUpParams {
  email: string;
  confirmationCode: string
  password: string;
  ethereumAddress: string;
  signature: string;
}

interface SignInParams {
  auth_type: string;
  email?: string;
  ethereum_address?: string;
  password: string;
  code?: string
}

interface ForgotPasswordParams {
  email: string
}

interface RecoveryPasswordParams {
  code: string
  password: string
}

const AuthService = {
  signUp: async ({ email, confirmationCode, password, ethereumAddress, signature }: SignUpParams): Promise<any> => {
    const response = await api.post('/register', {
      email: email.toLowerCase(),
      confirmation_code: confirmationCode,
      password,
      ethereum_address: ethereumAddress,
      signature: signature,
    })
    return response.data
  },
  signIn: async ({ auth_type, email = '', ethereum_address = '', password, code = '' }: SignInParams): Promise<any> => {
    const response = await api.post('/login', {
      auth_type,
      email: email.toLowerCase(),
      ethereum_address,
      password,
      twofa_code: code,
    })

    return response.data
  },
  signInPrecheck: async ({ auth_type, email = '', ethereum_address = '', password }: SignInPrecheck): Promise<any> => {
    const response = await api.post('/login/precheck', {
      auth_type,
      email: email.toLowerCase(),
      password,
      ethereum_address,
    })

    return response.data
  },
  forgotPassword: async ({ email }: ForgotPasswordParams): Promise<any> => {
    const response = await api.post('/forgot-password', {
      email,
    })

    return response
  },
  resetPassword: async ({ code, password }: RecoveryPasswordParams): Promise<any> => {
    const response = await api.post('/reset-password', {
      code,
      password,
    })

    return response
  },
}

export default AuthService
