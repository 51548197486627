import { useState, useCallback } from 'react'

import { useAegisMinting } from './useContract'

export function useMint() {
  const contract = useAegisMinting()
  const [processing, setProcessing] = useState(false)

  const onMint = useCallback(async (order: any, signature: string) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.mint({
        orderType: order.order_type,
        benefactor: order.benefactor,
        beneficiary: order.beneficiary,
        collateralAmount: order.collateral_amount,
        collateralAsset: order.collateral_asset,
        expiry: order.expiry,
        usdaAmount: order.usda_amount,
        additionalData: order.additional_data,
      }, signature)
      const receipt = await tx.wait()
      return receipt
    } catch (error) {
      console.log(`Error minting ${error}`)
      throw error
    } finally {
      setProcessing(false)
    }
  }, [contract])

  return { processing, onMint }
}

export function useRedeem() {
  const contract = useAegisMinting()
  const [processing, setProcessing] = useState(false)

  const onRedeem = useCallback(async (order: any, signature: string) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.redeem({
        orderType: order.order_type,
        benefactor: order.benefactor,
        beneficiary: order.beneficiary,
        collateralAmount: order.collateral_amount,
        collateralAsset: order.collateral_asset,
        expiry: order.expiry,
        usdaAmount: order.usda_amount,
        additionalData: order.additional_data,
      }, signature)
      const receipt = await tx.wait()
      return receipt
    } catch (error) {
      console.log(`Error redeeming ${error}`)
      throw error
    } finally {
      setProcessing(false)
    }
  }, [contract])

  return { processing, onRedeem }
}
