import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import store, { persistor } from 'state'
import Updater from 'state/updater'

import Web3Provider from 'components/Web3Provider'

import App from './App'

import 'assets/styles/index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3Provider>
          <Updater />
          <App />
        </Web3Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
