import { useState, useEffect } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

import ClaimingService from 'API/ClaimingService'

import { useApr } from 'state/stats/hooks'
import { useUserHoldingSnapshotsTotals, useUserHoldingSnapshots } from 'hooks/useUser'
import { useUSDAToken } from 'hooks/useToken'
import { useTokenBalance } from 'hooks/useBalance'
import { useClaimRewards } from 'hooks/useClaimRewards'
import { useSignClaimRewardsRequestMessage } from 'hooks/useSignature'

import { SNAPSHOT_ITEMS_PER_PAGE } from '@constants'

import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import CardUi from 'components/UI/CardUi'

import { formatUSDa } from 'utils/formatters'

import SnapshotsList from './SnapshotsList'

import styles from './styles.module.scss'

const CanClaim = () => {
  const signClaimRewardsRequestMessage = useSignClaimRewardsRequestMessage()
  const { onClaimRewards } = useClaimRewards()

  const usdaToken = useUSDAToken()
  const usdaBalance = useTokenBalance(usdaToken)
  const snapshotsTotals = useUserHoldingSnapshotsTotals()
  const {
    totalSnapshotsCount,
    snapshots,
    fetchUserHoldingSnapshots,
  } = useUserHoldingSnapshots()
  const apr = useApr()

  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const totalPages = Math.ceil(totalSnapshotsCount/SNAPSHOT_ITEMS_PER_PAGE)

  useEffect(() => {
    fetchUserHoldingSnapshots(SNAPSHOT_ITEMS_PER_PAGE, (currentPage*SNAPSHOT_ITEMS_PER_PAGE)-SNAPSHOT_ITEMS_PER_PAGE)
  }, [currentPage])

  const handleNextPage = () => {
    const nextPage = currentPage+1
    setCurrentPage(nextPage > totalPages ? totalPages : nextPage)
  }

  const handlePrevPage = () => {
    const prevPage = currentPage-1
    setCurrentPage(prevPage == 0 ? 1 : prevPage)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const signature = await signClaimRewardsRequestMessage()

      const resp = await ClaimingService.claimRewards(signature)

      console.log(resp)


      await onClaimRewards(resp.data.snapshot_ids, resp.data.amounts, resp.data.signature)
    } catch (err: any) {
      console.error(`Error claiming rewards ${err}`)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <CardUi>
          <div className={styles.infoWrapper}>
            <div className={styles.stats}>
              <div className={styles.stat}>
                <p className={styles.title}>APR</p>
                <p className={styles.value}>{`${apr}%`}</p>
              </div>
              <div className={styles.stat}>
                <p className={styles.title}>Current USDa Balance</p>
                <p className={styles.value}>
                  {`${formatUSDa(usdaBalance)} USDa`}
                </p>
              </div>
              <div className={styles.stat}>
                <p className={styles.title}>USDa Claimed</p>
                <p className={styles.value}>
                  {`${formatUSDa(snapshotsTotals.total_claimed)} USDa`}
                </p>
              </div>
            </div>

            <SubmitBtnUi
              text={`Claim ${formatUSDa(snapshotsTotals.total_unclaimed)} USDa`}
              isLoading={isLoading}
              disabled={!!error || BigNumber.from(snapshotsTotals.total_unclaimed).eq(0)}
              onClick={handleSubmit}
            />
          </div>
        </CardUi>

      </div>

      <SnapshotsList
        totalPages={totalPages}
        currentPage={currentPage}
        snapshots={snapshots}
        onPrevPage={handlePrevPage}
        onNextPage={handleNextPage}
      />
    </div>
  )
}

export default CanClaim