import api from './api'

const ClaimingService = {
  claimRewards: async (signature: string) => {
    const { data } = await api.post('/claiming/claim', { signature })

    return data
  },
}

export default ClaimingService
