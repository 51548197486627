import { Interface } from '@ethersproject/abi'

import UNISWAP_POOL_ABI from './uniswapPool.json'

const UNISWAP_POOL_INTERFACE = new Interface(UNISWAP_POOL_ABI)

export {
  UNISWAP_POOL_ABI,
  UNISWAP_POOL_INTERFACE,
}
