import { useCallback, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'

import { useAppDispatch, useAppSelector } from '../../state'
import {
  setBalanceNative,
  setUserDetails,
} from './reducer'

import UserService from '../../API/UserService'

export function useUpdateBalanceNative() {
  const dispatch = useAppDispatch()
  const { account, provider } = useWeb3React()

  return useCallback(async () => {
    if (!provider || !account) {
      dispatch(setBalanceNative('0'))
      return
    }

    const val = await provider.getBalance(account)
    dispatch(setBalanceNative(val.toString()))
  }, [account, provider, dispatch])
}

export function useUserBalanceNative() {
  const balance = useAppSelector(state => state.user.balanceNative)
  return useMemo(() => BigNumber.from(balance), [balance])
}

export function useUpdateUserDetails() {
  const dispatch = useAppDispatch()
  const isUserAuthorized = useIsUserAuthorized()

  return useCallback(async () => {
    if (!isUserAuthorized) {
      dispatch(setUserDetails(null))
      dispatch(setBalanceNative('0'))
      return
    }
    try {
      const details = await UserService.getCurrentUser()
      dispatch(setUserDetails(details))
    } catch (error) {
      console.error(`Failed to fetch user details: ${error}`)
    }
  }, [isUserAuthorized, dispatch])
}

export function useUserDetails() {
  return useAppSelector(state => state.user.details)
}

export function useIsUserAuthorized() {
  return !!useAppSelector(state => state.user.authToken)
}

export function useIsCorrectAddress() {
  const { account } = useWeb3React()
  const userDetails = useUserDetails()

  return account?.toLowerCase() === userDetails?.ethereum_address.toLowerCase()
}

export function useIsWalletConnected() {
  const { account } = useWeb3React()
  return !!account
}

export function useIsUserMinter() {
  const userDetails = useUserDetails()

  return useMemo(() => userDetails?.type === 'minter', [userDetails])
}

export function useIsUserHolder() {
  const userDetails = useUserDetails()
  return useMemo(() => userDetails?.type === 'holder', [userDetails])
}

export function useIsUserVerified() {
  const userDetails = useUserDetails()

  return useMemo(() => {
    return userDetails?.type === 'minter' && userDetails?.status === 'verified'
  }, [userDetails])
}