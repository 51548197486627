import { useWeb3React } from '@web3-react/core'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import { useDisconnectWallet } from 'state/wallet/hooks'
import { useIsCorrectAddress, useIsUserAuthorized, useUserDetails } from 'state/user/hooks'

import { shortenAddress } from 'utils/formatters'

import WALLET_ICON_URL from 'assets/images/icons/header-wallet.svg'
import LOGOUT_ICON_URL from 'assets/images/icons/header-logout.svg'
import SETTINGS_ICON_URL from 'assets/images/icons/header-settings.svg'

import styles from './styles.module.scss'

const ConnectButton = () => {
  const { account } = useWeb3React()
  const userDetails = useUserDetails()
  const disconnectWallet = useDisconnectWallet()
  const isCorrectAddress = useIsCorrectAddress()
  const isUserAuthorized = useIsUserAuthorized()

  const connected = !!account

  const renderConnectButton = () => {
    if (!userDetails) {
      return null
    }

    if (connected) {
      return (
        <>
          <div className={cx(styles.connectLink, {[styles.wrongAddress]: !isCorrectAddress})}>
            <div className={styles.iconWrapper}>
              <img src={WALLET_ICON_URL} alt="" />
            </div>
            <p className={styles.text}>{shortenAddress(account)}</p>
          </div>
        </>
      )
    }

    return (
      <NavLink to="/connect-wallet">
        <div className={styles.connectLink}>
          <div className={styles.iconWrapper}>
            <img src={WALLET_ICON_URL} alt="" />
          </div>
          <p className={styles.text}>Connect Wallet</p>
        </div>
      </NavLink>
    )
  }

  if (isUserAuthorized) {
    return (
      <div className={styles.wrapper}>
        {renderConnectButton()}

        <NavLink className={`${styles.headerBtn} ${styles.desktopOnly}`} to="/settings">
          <div className={styles.iconWrapper}>
            <img src={SETTINGS_ICON_URL} alt="" />
          </div>
        </NavLink>

        <div onClick={disconnectWallet} className={styles.headerBtn}>
          <div className={styles.iconWrapper}>
            <img src={LOGOUT_ICON_URL} alt="" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <NavLink to="/wallet-sign-in">
      <div className={styles.connectLink}>
        <div className={styles.iconWrapper}>
          <img src={WALLET_ICON_URL} alt="" />
        </div>
        <p className={styles.text}>Sign in</p>
      </div>
    </NavLink>
  )
}

export default ConnectButton
