import React from 'react'
import Button from '../Button'
import styles from './styles.module.scss'

interface Props {
  title: string
  description: string
  email?: string
  buttonText: string
  onClick?: () => void
  isDisabled?: boolean
}

const Option = ({ title, description, email, buttonText, onClick, isDisabled = false }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSection}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
        {email && (
          <p className={styles.email}>
            <span className={styles.content}>{email}</span>
          </p>
        )}
      </div>

      {isDisabled ? (
        <div className={styles.disabledBtn}>{buttonText}</div>
      ) : (
        <Button text={buttonText} onClick={onClick || (() => {})} />
      )}
    </div>
  )
}

export default Option