import { useSupportedTokens } from 'hooks/useToken'

import MainContainerUi from 'components/UI/MainContainerUi'

import GetTestETH from './GetTestETH'
import MintToken from './MintToken'
// import RedeemRequests from './RedeemRequests'
// import HoldingSnapshots from './HoldingSnapshots'

import styles from './styles.module.scss'

const Testing = () => {
  const tokens = useSupportedTokens()

  return (
    <MainContainerUi>
      <div className={styles.wrapper}>
        <div className={styles.mintTokens}>
          <GetTestETH />
          {tokens.filter(t => !!t).map(token => (
            <MintToken key={token.symbol} token={token} />
          ))}
        </div>
        {/* <RedeemRequests />
        <HoldingSnapshots /> */}
      </div>
    </MainContainerUi>
  )
}

export default Testing
