import { useState, ChangeEvent } from 'react'

import UserService from 'API/UserService'

import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'

interface Props {
  onNextStep: () => void;
}

const Step1 = ({ onNextStep }: Props) => {
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
    setError(null)
  }

  const handleSubmit = async () => {
    if (!code) {
      setError('Code cannot be empty')
      return
    }

    setIsLoading(true)

    try {
      const response = await UserService.disable2Fa({ code })

      if (response.status === 'success') {
        onNextStep()
      } else {
        setError('Invalid code')
      }
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <form>
        <InputUi
          label="Google 2FA Code"
          name="code"
          value={code}
          type="text"
          placeholder="Enter Authenticator verification code"
          error={error}
          onChange={handleCodeChange}
        />
        <SubmitBtnUi onClick={handleSubmit} text="Next" isLoading={isLoading} disabled={isLoading} />
      </form>
    </div>
  )
}

export default Step1
