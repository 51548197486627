import { useState } from 'react'
import Chart from 'react-apexcharts'
import styles from './styles.module.scss'

const CustodianBarChart = () => {
  const dataValues = [
    7.2, 7.1, 5.8, 4.1, 3.9, 3.5, 3.4, 3.4, 3.1, 2.9, 2.7, 0.3,
  ]

  const categories = [
    'Binance', 'BitGet', 'ByBit', 'OKX', 'Huobi', 'Derbit', 'BitFinex', 'Bitmex', 'Kraken', 'DYDX', 'KWENTA', 'AEVO',
  ]

  const colors = [
    { fillColor: '#FF5733', strokeColor: '#C70039' },
    { fillColor: '#33FF57', strokeColor: '#39C700' },
    { fillColor: '#3357FF', strokeColor: '#0039C7' },
    { fillColor: '#FF33A1', strokeColor: '#C70085' },
    { fillColor: '#A133FF', strokeColor: '#7C00C7' },
    { fillColor: '#33FFA8', strokeColor: '#00C779' },
    { fillColor: '#FFBD33', strokeColor: '#C78B00' },
    { fillColor: '#FF33FF', strokeColor: '#C700C7' },
    { fillColor: '#33FFEC', strokeColor: '#00C7C7' },
    { fillColor: '#FFC733', strokeColor: '#C7A100' },
    { fillColor: '#FF5733', strokeColor: '#C70039' },
    { fillColor: '#FF33A1', strokeColor: '#C70085' },
  ]

  const formattedData = dataValues.map((value, index) => ({
    x: categories[index],
    y: value,
    fillColor: colors[index].fillColor,
    strokeColor: colors[index].strokeColor,
  }))

  const [series] = useState([
    {
      name: 'Balance',
      data: formattedData,
    },
  ])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      show: true,
      borderColor: '#414243',
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      crosshairs: {
        show: false,
      },
      categories: [
        'Binance', 'BitGet', 'ByBit', 'OKX', 'Huobi', 'Derbit', 'BitFinex', 'Bitmex', 'Kraken', 'DYDX', 'KWENTA', 'AEVO',
      ],
      position: 'bottom',
      labels: {
        show: true,
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
        formatter: function (val) {
          return '$' + val + 'B'
        },
      },
    },
    title: {
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#ffffff',
      },
    },
  }

  return (
    <div className={styles.wrapper}>
      <Chart options={options} series={series} type="bar" height={380} />
    </div>
  )
}

export default CustodianBarChart