import { useState, useEffect, useMemo } from 'react'
import { parseUnits, formatUnits } from 'ethers/lib/utils'

import { usePoolAddress, useCalculateSwapOutputAmount, useExchangeCurve } from 'hooks/Curve'
import { useTokenApprovalRequired, useApproveToken } from 'hooks/useToken'
import { useTokenBalance } from 'hooks/useBalance'

import { Token, Price } from '../../../entities'

import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import PaymentInput from 'components/PaymentInput'
import SuccessUi from 'components/UI/SuccessUi'
import LoadingBannerUi from 'components/UI/LoadingBannerUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'

import { truncateFloatString } from 'utils/formatters'

import styles from './styles.module.scss'

interface Props {
  usdaToken: Token
  supportedTokens: Token[]
}

const CurveForm = ({ usdaToken, supportedTokens }: Props) => {
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  const [inputToken, setInputToken] = useState(supportedTokens[0])
  const [outputToken, setOutputToken] = useState(usdaToken)
  const [inputAmount, setInputAmount] = useState('')
  const [outputAmount, setOutputAmount] = useState('')
  const [price, setPrice] = useState(new Price('0'))
  const [lastInputAmount, setLastInputAmount] = useState('')

  const inputBalance = useTokenBalance(inputToken)
  const outputBalance = useTokenBalance(outputToken)

  const poolAddress = usePoolAddress(inputToken, outputToken)
  const calculateSwapOutputAmount = useCalculateSwapOutputAmount(poolAddress)
  const { approvalRequired, updateApprovalRequired } = useTokenApprovalRequired(inputToken, poolAddress, inputAmount)
  const { processing: processingApproval, onApproveToken } = useApproveToken(inputToken)
  const { processing: processingExchange, onExchange } = useExchangeCurve(poolAddress)

  const [inputTokens, outputTokens] = useMemo(() => {
    if (inputToken.address.toLowerCase() === usdaToken.address.toLowerCase()) {
      return [[usdaToken], supportedTokens]
    }

    return [supportedTokens, [usdaToken]]
  }, [inputToken.address, usdaToken.address, JSON.stringify(supportedTokens)])

  const canSwap = useMemo(() => {
    return !error && inputAmount && outputAmount && inputAmount != '0.0' && outputAmount != '0.0' && !processingExchange
  }, [error, inputAmount, outputAmount, processingExchange])

  useEffect(() => {
    setInputToken(supportedTokens[0])
    setOutputToken(usdaToken)
  }, [usdaToken.address, JSON.stringify(supportedTokens)])

  useEffect(() => {
    if (!inputAmount || inputToken.parseUnits(inputAmount).lte(inputBalance)) {
      setError(null)
    } else {
      setError('Insufficient funds')
    }
  }, [inputAmount, inputToken.address, inputBalance.toString()])

  useEffect(() => {
    async function updatePrice() {
      const p = await calculateSwapOutputAmount(inputToken, parseUnits('1', outputToken.decimals))
      setPrice(new Price(p.toString(), inputToken.decimals))
    }

    updatePrice()
  }, [inputToken.address, outputToken.address, calculateSwapOutputAmount])

  const handleInputAmountChange = async (name: string, value: string) => {
    setInputAmount(truncateFloatString(value, inputToken.decimals))
    setLastInputAmount(value)
    if (!value) {
      setOutputAmount('')
      return
    }
    const amount = await calculateSwapOutputAmount(outputToken, parseUnits(value, inputToken.decimals))
    setOutputAmount(formatUnits(amount, outputToken.decimals))
  }

  const handleOutputAmountChange = async (name: string, value: string) => {
    setOutputAmount(truncateFloatString(value, outputToken.decimals))
    setLastInputAmount(value)
    if (!value) {
      setInputAmount('')
      return
    }
    const amount = await calculateSwapOutputAmount(inputToken, parseUnits(value, outputToken.decimals))
    setInputAmount(formatUnits(amount, inputToken.decimals))
  }

  const handleSwitchDirection = async () => {
    let inAmount = inputAmount
    let outAmount = outputAmount
    if (lastInputAmount) {
      if (inputAmount === lastInputAmount) {
        outAmount = lastInputAmount
        const amount = await calculateSwapOutputAmount(outputToken, parseUnits(lastInputAmount, inputToken.decimals))
        inAmount = formatUnits(amount, outputToken.decimals)
      } else if (outputAmount === lastInputAmount) {
        inAmount = lastInputAmount
        const amount = await calculateSwapOutputAmount(inputToken, parseUnits(lastInputAmount, outputToken.decimals))
        outAmount = formatUnits(amount, inputToken.decimals)
      }
    }

    setInputToken(outputToken)
    setInputAmount(inAmount)
    setOutputToken(inputToken)
    setOutputAmount(outAmount)
  }

  const handleApproveToken = async () => {
    if (processingApproval || !approvalRequired) {
      return
    }

    setLoadingText('Requesting approval for token swap...')
    await onApproveToken(poolAddress)
    await updateApprovalRequired()
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      setLoadingText('Follow the instructions in your wallet...')
      await onExchange(inputToken, parseUnits(inputAmount, inputToken.decimals), parseUnits(outputAmount, outputToken.decimals))
      setIsSuccess(true)
      setInputAmount('')
      setOutputAmount('')
    } catch (error: any) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      {isLoading && <LoadingBannerUi text={loadingText} />}
      {isSuccess ?
        <SuccessUi
          text="The swap transaction was successful."
          link='/dashboard'
          linkText='Back to dashboard'
        />
        :
        <>
          <PaymentInput
            name="inputAmount"
            value={inputAmount}
            title="You pay"
            selectedToken={inputToken}
            tokens={inputTokens}
            balance={inputBalance}
            placeholder="0"
            isBaseCurrency={true}
            onChange={handleInputAmountChange}
            onTokenSelect={setInputToken}
            onSwitchDirection={handleSwitchDirection}
          />
          <PaymentInput
            name="outputAmount"
            value={outputAmount}
            title="You receive"
            selectedToken={outputToken}
            tokens={outputTokens}
            balance={outputBalance}
            placeholder="0"
            onChange={handleOutputAmountChange}
            onTokenSelect={setOutputToken}
          />
          <div className={styles.paymentInfo}>
            <div className={styles.price}>
          1 <span className={styles.currency}>{outputToken.symbol}</span>
              {` = ${truncateFloatString(price.format(), 6)} `}
              <span className={styles.currency}>{inputToken.symbol}</span>
            </div>
            <div className={styles.blockchainInfo}>
              <div className={styles.blockchainInfoItem}>
                <div className={styles.title}>Max. slippage</div>
                <div className={styles.value}>0.10%</div>
              </div>
              <div className={styles.blockchainInfoItem}>
                <div className={styles.title}>Network Fee</div>
                <div className={styles.value}>0.003 ETH</div>
              </div>
            </div>
          </div>
          {error && <ErrorBannerUi text={error} />}
          {(inputAmount && approvalRequired) ? (
            <SubmitBtnUi
              text="Approve"
              isLoading={processingApproval}
              disabled={processingApproval}
              onClick={handleApproveToken}
            />
          ) : (
            <SubmitBtnUi
              text="Swap"
              isLoading={isLoading}
              disabled={!canSwap}
              onClick={handleSubmit}
            />
          )}
        </>
      }
    </div>
  )
}

export default CurveForm
