import { formatEther } from 'ethers/lib/utils'

import { useUserDetails } from 'state/user/hooks'
import { useNativeBalance } from 'hooks/useBalance'
import { useGetTestETH } from 'hooks/useTesting'

import styles from './styles.module.scss'

const GetTestETH = () => {
  const { processing, onGetTestETH } = useGetTestETH()
  const balance = useNativeBalance()
  const userDetails = useUserDetails()

  const handleGetTestETH = async () => {
    if (!userDetails?.ethereum_address) {
      return
    }

    await onGetTestETH(userDetails.ethereum_address)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Get test ETH
      </div>
      <div className={styles.balance}>
        <span>Balance</span>
        <span>{formatEther(balance)}</span>
      </div>
      <div className={styles.inputBox}>
        <button disabled={processing} onClick={handleGetTestETH}>
          Get 1 ETH
        </button>
      </div>
    </div>
  )
}

export default GetTestETH
