import { Token } from 'entities/token'

import ARROW_DOWN_ICON_URL from 'assets/images/icons/arrow_down.svg'

import styles from './styles.module.scss'

interface Props {
  token: Token
  selectable?: boolean
}

const TokenButton = ({ token, selectable = false }: Props) => {
  return (
    <div className={styles.button}>
      <div className={styles.currencyWrapper}>
        <div className={styles.logoWrapper}>
          <img src={`/images/icons/coins/${token.symbol}.svg`} alt="" />
        </div>
        <div className={styles.name}>
          {token.symbol}
        </div>
      </div>
      {selectable ? (
        <div className={styles.arrowIcon}>
          <img src={ARROW_DOWN_ICON_URL} />
        </div>
      ) : null}
    </div>
  )
}

export default TokenButton
