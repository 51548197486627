export * from './statistics'

export type Interval = '4h' | '6h' | '8h' | '12h' | '1d' | '1w'

export enum RedeemRequestStatus {
  WAITING_DEPOSIT = 'waiting_deposit',
  DEPOSITED = 'deposited',
  COMPLETED = 'completed',
  DECLINED = 'declined',
}

export interface RedeemRequest {
  id: string
  request_id: string
  details: {
    collateral_asset: string
    collateral_amount: string
    usda_amount: string
    price: string
  }
  deposit_details?: {
    amount: string
  }
  complete_details?: {
    collateral_amount: string
    usda_amount: string
  }
  status: RedeemRequestStatus
  created_at: number
  updated_at: number
}

export interface HoldingSnapshot {
  id: string
  status: string
  start_time: string
  end_time: string
  total_rewards: string
  total_addresses: number
}

export interface UserHoldingSnapshotTotals {
  total_claimed: string
  total_unclaimed: string
}

export interface UserDaySnapshot {
  avg_balance: string
  reward: string
}

export interface UserHoldingSnapshot {
  snapshot_id: string
  start_time: string
  end_time: string
  next_snapshot_at: string
  total_reward_amount: string
  claim_reward_status: string
  apr: string
  status: 'calculating' | 'calculated' | 'finalized'
  day_snapshots: { [key: number]: UserDaySnapshot }
}
