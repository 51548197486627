import { FormEvent } from 'react'

import styles from './styles.module.scss'

interface Props {
  text: string
  onClick: (e: FormEvent) => void
}

const BackLinkUi = ({ text, onClick }: Props) => {
  return (
    <div
      className={styles.wrapper}
      onClick={onClick}
    >
      {text}
    </div>
  )
}

export default BackLinkUi
