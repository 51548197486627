import api from './api'

import type {
  Interval,
  SeriesData,
  ExchangeFundingRateChartData,
  APRChartData,
  USDaChartData,
  BTCMarginStat,
  BTCFundingRateStats,
} from '@types'

const StatsService = {
  fetchBTCFundingRateStatistics: async (): Promise<BTCFundingRateStats> => {
    const { data } = await api.get('/statistics/btc-funding-rate')

    return {
      oiFundingRate: data.data.oi_funding_rate,
      volumeFundingRate: data.data.volume_funding_rate,
    }
  },

  fetchBTCMarginStatistics: async (): Promise<Array<BTCMarginStat>> => {
    const { data } = await api.get('/statistics/btc-margin')

    return data.data
  },

  fetchUSDaSupplyChartData: async (): Promise<Array<USDaChartData>> => {
    const { data } = await api.get('/charts/usda-supply')

    return data.data
  },

  fetchUSDaPriceChartData: async (): Promise<Array<USDaChartData>> => {
    const { data } = await api.get('/charts/usda-price')

    return data.data
  },

  fetchReserveFundChartData: async (): Promise<Array<USDaChartData>> => {
    const { data } = await api.get('/charts/reserve-fund')

    return data.data
  },

  fetchAprChartData: async (): Promise<Array<APRChartData>> => {
    const { data } = await api.get('/charts/apr')

    return data.data
  },

  fetchOiWeightChartData: async (interval: Interval = '4h'): Promise<Array<SeriesData>> => {
    const { data } = await api.get('/charts/oi-funding-rate', { params: { interval } })

    return data.data
  },

  fetchVolumeWeightChartData: async (interval: Interval = '4h'): Promise<Array<SeriesData>> => {
    const { data } = await api.get('/charts/volume-funding-rate', { params: { interval } })

    return data.data
  },

  fetchExchangeRatesChartData: async (): Promise<ExchangeFundingRateChartData> => {
    const { data } = await api.get('/charts/exchange-funding-rates')

    return data.data
  },
}

export default StatsService
