import RedeemRequests from 'pages/RedeemRequests'

import Banner from 'components/Banner'
import StatsCard from 'components/StatsCard'
import MainContainerUi from 'components/UI/MainContainerUi'
import RewardCard from 'components/RewardCard'
import Tabs from 'components/Tabs'
import CardUi from 'components/UI/CardUi'

import { useApr } from 'state/stats/hooks'
import { useIsUserMinter, useIsUserVerified } from 'state/user/hooks'
import { useTokenBalance } from 'hooks/useBalance'
import { useUSDAToken } from 'hooks/useToken'

import BANNER_ICON_URL from 'assets/images/icons/banner-icon.svg'
import STATS_ICON_URL from 'assets/images/icons/aprpercent.svg'
import AEGIS_ICON_URL from 'assets/images/icons/logo-outlined.svg'
import USDA_ICON_URL from 'assets/images/icons/usda-logo-white.svg'

import styles from './styles.module.scss'
import { formatUSDa } from 'utils/formatters'
import { useTransactions } from 'hooks/useUser'
import { Component, useMemo, useState } from 'react'
import TransactionTableUi from 'components/UI/TransactionTableUi'

const Dashboard = () => {
  const isUserVerified = useIsUserVerified()
  const isUserMinter = useIsUserMinter()
  const usdaToken = useUSDAToken()

  const usdaBalance = useTokenBalance(usdaToken)
  const apr = useApr()

  const [offset, setOffset] = useState(0)
  const limit = 25
  const [activeTab, setActiveTab] = useState(0)

  const allOptions = useMemo(() => ({ limit, offset }), [limit, offset])

  const mintOptions = useMemo(() => ({ limit, offset, type: 'mint_usda' }), [limit, offset])

  const claimOptions = useMemo(() => ({ limit, offset, type: 'claim_rewards' }), [limit, offset])

  const { transactions: allTransactions, totalCount: allCount } = useTransactions(allOptions)
  const { transactions: mintTransactions, totalCount: mintCount } = useTransactions(mintOptions)
  const { transactions: claimTransactions, totalCount: claimCount } = useTransactions(claimOptions)

  const handleTabChange = (index: number) => {
    setActiveTab(index)
    setOffset(0)
  }

  const minterTabData = [
    {
      title: 'All',
      Component: TransactionTableUi,
      props: {
        items: allTransactions,
        totalCount: allCount,
        onPageChange: setOffset,
        limit,
      },
    },
    {
      title: 'Mint',
      Component: TransactionTableUi,
      props: {
        items: mintTransactions,
        totalCount: mintCount,
        onPageChange: setOffset,
        limit,
      },
    },
    {
      title: 'Redeem',
      Component: RedeemRequests,
      props: {},
    },
    {
      title: 'Claim',
      Component: TransactionTableUi,
      props: {
        items: claimTransactions,
        totalCount: claimCount,
        onPageChange: setOffset,
        limit,
      },
    },
  ]

  const holderTabData = [
    {
      title: 'All',
      Component: TransactionTableUi,
      props: {
        items: allTransactions,
        totalCount: allCount,
        onPageChange: setOffset,
        limit,
      },
    },
    {
      title: 'Claim',
      Component: TransactionTableUi,
      props: {
        items: claimTransactions,
        totalCount: claimCount,
        onPageChange: setOffset,
        limit,
      },
    },
  ]

  return (
    <MainContainerUi>
      <div className={styles.wrapper}>
        {!isUserVerified && isUserMinter &&
          <Banner
            icon={BANNER_ICON_URL}
            title="Complete Verification"
            description="To access the Mint and Redeem features, complete the verification process. This will unlock all functionalities, ensure secure transactions, and provide you with full access to our platform's capabilities, enabling a seamless and efficient experience."
            url="/kyc-verification"
          />
        }

        <div className={styles.statsWrapper}>
          <StatsCard
            icon={AEGIS_ICON_URL}
            title="AEG BALANCE"
            amount={0}
            currencyName="AEG"
          />
          <StatsCard
            icon={USDA_ICON_URL}
            title="USDa BALANCE"
            amount={usdaToken ? formatUSDa(usdaBalance) : '0'}
            currencyName=""
          />
          <StatsCard
            icon={STATS_ICON_URL}
            title="APR"
            amount={`${apr}%`}
          />
          <RewardCard
            hintText="Hint: This is a hint text"
            title="Your Rewards"
            description="Add your wallet to receive rewards every seven days"
          />
        </div>
      </div>
      <CardUi>
        <Tabs activeTab={activeTab} onTabChange={handleTabChange} tabs={isUserMinter ? minterTabData : holderTabData} isExportButtons={true} />
      </CardUi>

    </MainContainerUi>


  )
}

export default Dashboard