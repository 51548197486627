import { Interface } from '@ethersproject/abi'

import CURVE_VIEWS_ABI from './curveViews.json'

const CURVE_VIEWS_INTERFACE = new Interface(CURVE_VIEWS_ABI)

export {
  CURVE_VIEWS_ABI,
  CURVE_VIEWS_INTERFACE,
}
