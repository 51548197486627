import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

export function useSignAuthMessage() {
  const { provider, account } = useWeb3React()

  return useCallback(async () => {
    if (!provider || !account) {
      return ''
    }

    const signer = provider.getSigner()
    const message = `Authenticating on Aegis platform using address ${account}`
    return signer.signMessage(message)
  }, [account, provider])
}

export function useSignClaimRewardsRequestMessage() {
  const { provider, account } = useWeb3React()

  return useCallback(async () => {
    if (!provider || !account) {
      return ''
    }

    const signer = provider.getSigner()
    const message = 'Requesting USDa rewards claiming'
    return signer.signMessage(message)
  }, [account, provider])
}
