import React from 'react'
import { NavLink } from 'react-router-dom'

import SUCCESS_ICON_URL from 'assets/images/icons/success.svg'

import styles from './styles.module.scss'

interface Props {
  text: string;
  link: string;
  linkText: string;
}

const SuccessUi = ({ text, link, linkText }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.stepWrapper}>
        <div className={styles.imgWrapper}>
          <img src={SUCCESS_ICON_URL} alt="Success Icon" />
        </div>
        <p className={styles.header}>Success</p>
        <p className={styles.description}>{text}</p>
        <NavLink className={styles.navLink} to={link}>{linkText}</NavLink>
      </div>
    </div>
  )
}

export default SuccessUi
