import { ReactNode } from 'react'

import styles from './styles.module.scss'

interface Props {
  children: ReactNode
}

const MainContainerUi = ({ children }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default MainContainerUi
