import { useUSDAToken } from 'hooks/useToken'
import { useSupportedTokens } from 'hooks/useToken'
import { useUserRedeemRequests } from 'hooks/useUser'
import { useLocation } from 'react-router-dom'

import Banner from 'components/Banner'
import IsRedeemed from './IsRedeemed'

const RedeemBanner = () => {
  const usdaToken = useUSDAToken()
  const tokens = useSupportedTokens()
  const redeemRequests = useUserRedeemRequests()
  const location = useLocation()

  // Проверка текущего пути
  if (location.pathname === '/redeem') {
    return null
  }

  const waitingDepositRequest = redeemRequests.find(
    (redeemRequests) => redeemRequests.status === 'waiting_deposit',
  )

  const depositedRequest = redeemRequests.find(
    (request) => request.status === 'deposited',
  )

  if (waitingDepositRequest) {
    return (
      <Banner
        title="Redeem Status: Awaiting Deposit"
        description={`Your request for redeem #${waitingDepositRequest.request_id} is currently being processed. Please wait while the administration finalizes the necessary steps.`}
      />
    )
  }

  if (depositedRequest) {
    const collateralToken = tokens.filter(t => !!t).find(t =>
      t.address.toLowerCase() === depositedRequest.details.collateral_asset.toLowerCase(),
    )

    if (!collateralToken) {
      return null
    }

    return (
      <IsRedeemed
        item={depositedRequest}
        collateralToken={collateralToken}
        usdaToken={usdaToken}
      />
    )
  }

  return null
}

export default RedeemBanner
