import { useState, useCallback, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import AuthService from 'API/AuthService'

import { setAuthToken, setUserDetails } from 'state/user/reducer'

import { useSignAuthMessage } from 'hooks/useSignature'

import ErrorBannerUi from 'components/UI/ErrorBannerUi'
import InputUi from 'components/UI/InputUi'
import BackLinkUi from 'components/UI/BackLinkUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'

import type { FormValues } from '../../types'

import StepWrapper from '../StepWrapper'

interface Props {
  values: FormValues
  onNextStep: () => void
  onPrevStep: () => void
  inputChangeHandler: (input: string) => (e: ChangeEvent<HTMLInputElement>) => void
}

const Step3 = ({ values, onNextStep, onPrevStep, inputChangeHandler }: Props) => {
  const navigate = useNavigate()
  const signAuthMessage = useSignAuthMessage()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const dispatch = useDispatch()

  const signMessage = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const signature = await signAuthMessage()
      inputChangeHandler('signature')({ target: { value: signature } } as ChangeEvent<HTMLInputElement>)
      await sendToServer(signature)
    } catch (error) {
      setError(JSON.stringify(error) || 'Failed to sign message')
    } finally {
      setIsLoading(false)
    }
  }, [JSON.stringify(values), signAuthMessage, navigate])

  const sendToServer = async (signature: string) => {
    try {
      const response = await AuthService.signUp({
        email: values.email,
        confirmationCode: values.confirmationCode,
        password: values.password,
        ethereumAddress: values.ethereumAddress,
        signature: signature,
      })

      navigate('/sign-up', { replace: true })
      dispatch(setAuthToken(response.data.token))
      dispatch(setUserDetails(response.data.user))
      onNextStep()
    } catch (error: any) {
      console.error(error)
      setError(JSON.stringify(error) || 'Failed to send data to server')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <StepWrapper isLoading={isLoading} title="Add your wallet" description="Sign the message to complete registration." totalSteps={3} currentStep={3}>
      <ErrorBannerUi text="Please note that this wallet will be linked to your account. You cannot change it manually. All financial transactions in your account will only be permitted using this wallet." />
      <InputUi
        label="Your wallet"
        name="ethereum_address"
        type="text"
        value={values.ethereumAddress}
        onChange={() => {}}
        disabled={true}
      />
      {error && <ErrorBannerUi text={error} />}
      <SubmitBtnUi text="Sign Message" onClick={signMessage} isLoading={isLoading} disabled={isLoading} />
      <BackLinkUi text="Back to previous step" onClick={onPrevStep} />
    </StepWrapper>
  )
}

export default Step3
