import { BigNumberish } from '@ethersproject/bignumber'
import { formatUnits } from 'ethers/lib/utils'

export function shortenAddress(address: string): string {
  if (!address.startsWith('0x') || address.length !== 42) {
    throw new Error('Invalid Ethereum address')
  }

  const start = address.slice(0, 6)
  const end = address.slice(-4)

  return `${start}...${end}`
}

export function truncateFloatString(value: string, decimals: number) {
  const [int, frac] = value.split('.')
  if (decimals === 0 || frac === undefined) {
    return int
  }

  return `${int}.${frac.slice(0, decimals)}`
}

export function formatNumber(input: BigNumberish, digits = 2, units = 6) {
  let number
  if (typeof input === 'number') {
    number = input
  } else {
    number = parseFloat(formatUnits(input, units))
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(number)
}

export function formatUSDa(input: BigNumberish) {
  return formatNumber(input, 2, 18)
}
