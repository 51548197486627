import { useBTCMarginRateStats } from 'hooks/useStats'

import CardUi from 'components/UI/CardUi'

import styles from './styles.module.scss'

const BTCFundingRateStats = () => {
  const data = useBTCMarginRateStats()

  return (
    <div className={styles.wrapper}>
      <CardUi>
        <div className={styles.innerCard}>
          <p className={styles.rateValue}>{`${data.oiFundingRate}%`}</p>
          <p className={styles.rateTitle}>BTC OI-Weighted Funding Rate</p>
        </div>
      </CardUi>
      <CardUi>
        <div className={styles.innerCard}>
          <p className={styles.rateValue}>{`${data.volumeFundingRate}%`}</p>
          <p className={styles.rateTitle}>BTC Volume-Weighted Funding Rate</p>
        </div>
      </CardUi>
    </div>
  )
}

export default BTCFundingRateStats