const flattenObject = (obj: any, parentKey = '', result: any = {}) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey ? `${parentKey}_${key}` : key
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        flattenObject(obj[key], newKey, result)
      } else {
        result[newKey] = obj[key]
      }
    }
  }
  return result
}


const convertToCSV = (array: any[]) => {
  const flatArray = array.map(item => flattenObject(item))
  const headers = Object.keys(flatArray[0]).join(',')
  const rows = flatArray.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')).join('\n')
  return `${headers}\n${rows}`
}

export const downloadCSV = (data: any[], fileName: string) => {
  const csv = convertToCSV(data)
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)
  link.setAttribute('download', `${fileName}.csv`)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
