import { useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'

import { Token } from '@entities'

import useInterval from './useInterval'
import { useERC20Token } from './useContract'

export function useNativeBalance() {
  const { account, provider } = useWeb3React()
  const [balance, setBalance] = useState(BigNumber.from(0))

  const fetchBalance = useCallback(async () => {
    if (!account || !provider) {
      setBalance(BigNumber.from(0))
      return
    }

    const val = await provider.getBalance(account)
    setBalance(val)
  }, [account, provider])

  useInterval(fetchBalance, 3000)

  return balance
}

export function useTokenBalance(token?: Token) {
  const { account } = useWeb3React()
  const contract = useERC20Token(token?.address)
  const [balance, setBalance] = useState(BigNumber.from(0))

  const fetchBalance = useCallback(async () => {
    if (!account || !contract) {
      setBalance(BigNumber.from(0))
      return
    }

    try {
      const val = await contract.balanceOf(account)
      setBalance(val)
    } catch (error) {
      setBalance(BigNumber.from(0))
    }
  }, [token?.address, account, contract])

  useInterval(fetchBalance, 3000)

  return balance
}