import { forwardRef } from 'react'

import { ChainInfo  } from 'web3/chains'

import styles from './styles.module.scss'

interface Props {
  network: ChainInfo
  activate: () => void
}

const Option = forwardRef(({ network, activate }: Props, ref: any) => (
  <div
    ref={ref}
    className={styles.option}
    onClick={activate}
  >
    <div>
      {network.name}
    </div>
  </div>
))

export default Option
