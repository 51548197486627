import styles from './styles.module.scss'

interface Props {
  name: string
  iconUrl: string
}

const SocialIcon = ({ name, iconUrl }: Props) => {
  return (
    <div className={styles.socialIcon}>
      <img src={iconUrl} alt={name} />
    </div>
  )
}

export default SocialIcon
