import { useBTCMarginStats } from 'hooks/useStats'

import CardUi from 'components/UI/CardUi'
import TokenMarginedTable from 'components/TokenMarginedTable'

import styles from '../styles.module.scss'

const BTCMarginStats = () => {
  const data = useBTCMarginStats()

  if (!data) {
    return null
  }

  return (
    <div className={styles.tokenMarginedWrapper}>
      <CardUi>
        <p className={styles.statHeader}>Bitcoin Token Margined</p>
        <TokenMarginedTable data={data} />
      </CardUi>
    </div>
  )
}

export default BTCMarginStats
