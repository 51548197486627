import CardUi from 'components/UI/CardUi'

import styles from './styles.module.scss'

interface Props {
  icon: string
  title: string
  amount: number | string
  currencyName?: string
}

const StatsCard = ({ icon, title, amount, currencyName }: Props) => {
  return (
    <CardUi>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <img src={icon} alt="Icon" />
        </div>
        <div className={styles.textContainer}>
          <p className={styles.amount}>{amount} <span className={styles.currency}>{currencyName && currencyName}</span></p>
          <p className={styles.title}>{title}</p>
        </div>
      </div>
    </CardUi>
  )
}

export default StatsCard
