import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import { useIsUserAuthorized } from 'state/user/hooks'
import { useDisconnectWallet } from 'state/wallet/hooks'

import SuccessUi from 'components/UI/SuccessUi'
import type { FormValues } from './types'

import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'

const SignUp = () => {
  const { account, isActive } = useWeb3React()
  const [searchParams] = useSearchParams()

  const isAuthorized = useIsUserAuthorized()
  const disconnectWallet = useDisconnectWallet()

  const [step, setStep] = useState(1)
  const [shouldDisconnect, setShouldDisconnect] = useState(true)
  const [formValues, setFormValues] = useState<FormValues>({
    email: '',
    confirmationCode: '',
    password: '',
    confirmPassword: '',
    ethereumAddress: '',
    signature: '',
  })

  useEffect(() => {
    if (account && account.toLowerCase() !== formValues.ethereumAddress.toLowerCase()) {
      setFormValues((prevValues) => ({ ...prevValues, ethereumAddress: account }))
    }
  }, [account, formValues.ethereumAddress])

  useEffect(() => {
    async function handleDisconnect() {
      if (!shouldDisconnect) {
        return
      }

      const rawEmail = searchParams.get('email')
      const email = rawEmail ? rawEmail.replace(/ /g, '+') : ''
      const confirmationCode = searchParams.get('code') ? decodeURIComponent(searchParams.get('code') || '') : ''

      if (!isAuthorized || !isActive || !account || !email || !confirmationCode) {
        return
      }

      await disconnectWallet()
      setShouldDisconnect(false)
    }

    handleDisconnect()
  }, [shouldDisconnect, isAuthorized, isActive, account, searchParams, disconnectWallet, setShouldDisconnect])

  useEffect(() => {
    if (!account) {
      setStep(1)
      return
    }

    if (!formValues.ethereumAddress) {
      setFormValues((prevValues) => ({ ...prevValues, ethereumAddress: account }))
      setStep(2)
      return
    }

    if (formValues.ethereumAddress && (!formValues.email || !formValues.password || !formValues.confirmPassword)) {
      setStep(2)
      return
    }

  }, [account, formValues.ethereumAddress, formValues.email, formValues.password, formValues.confirmPassword])

  const inputChangeHandler = useCallback((input: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevValues) => ({ ...prevValues, [input]: e.target.value }))
  }, [setFormValues])

  const handleNextStep = () => {
    setStep((prevStep) => Math.min(prevStep + 1, 4))
  }

  const handlePrevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1))
  }

  const renderStep = () => {
    switch (step) {
    case 1:
      return (
        <Step1
          onNextStep={handleNextStep}
        />
      )
    case 2:
      return (
        <Step2
          values={formValues}
          onNextStep={handleNextStep}
          inputChangeHandler={inputChangeHandler}
        />
      )
    case 3:
      return (
        <Step3
          values={formValues}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
          inputChangeHandler={inputChangeHandler}
        />
      )
    case 4:
      return (
        <SuccessUi
          text="You have successfully completed registration. Welcome to the project!"
          link="/dashboard"
          linkText="Go to dashboard page"
        />)
    default:
      return null
    }
  }

  return (
    <div>
      {renderStep()}
    </div>
  )
}

export default SignUp
