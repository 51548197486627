const signInValidator = {
  email: {
    required: true,
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    errorMessage: 'Email must be in email format',
  },
  password: {
    required: true,
    minLength: 3,
    errorMessage: 'Password must be at least 3 characters long',
  },
}

export default signInValidator
