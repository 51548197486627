import { useState, useEffect, useCallback } from 'react'
import { formatDate } from 'utils/formatDate'

import UserService from 'API/UserService'

import { useUserDetails } from 'state/user/hooks'
import type { RedeemRequest, UserHoldingSnapshotTotals, UserHoldingSnapshot } from '@types'

import useInterval from './useInterval'

interface TransactionDataParams {
  id: string;
  hash: string;
  type: string;
  status: string;
  created_at: string;
  updated_at: string;
  data: {
    [key: string]: any;
  };
}

interface TransactionsRequestParams {
  limit?: number;
  offset?: number;
  type?: string;
}

export function useApplyForRewards() {
  const [processing, setProcessing] = useState(false)

  const onApplyForRewards = async () => {
    setProcessing(true)
    try {
      await UserService.applyForRewards()
    } catch (error) {
      console.error(`Error applying for rewards ${error}`)
    } finally {
      setProcessing(false)
    }
  }

  return { processing, onApplyForRewards }
}

export function useUserRedeemRequests() {
  const userDetails = useUserDetails()
  const [requests, setRequests] = useState<RedeemRequest[]>([])

  const fetchRedeemRequests = useCallback(async () => {
    if (userDetails?.type !== 'minter') {
      setRequests([])
      return
    }

    try {
      const res = await UserService.fetchRedeemRequests()

      setRequests(res.data)
    } catch (error) {
      setRequests([])
    }
  }, [userDetails?.type])

  useInterval(fetchRedeemRequests, 10000)

  return requests
}

export function useUserHoldingSnapshotsTotals() {
  const [snapshotsData, setSnapshotData] = useState<UserHoldingSnapshotTotals>({
    total_claimed: '0',
    total_unclaimed: '0',
  })

  const fetchUserHoldingSnapshotTotals = useCallback(async () => {
    const res = await UserService.fetchHoldingSnapshotsTotals()
    setSnapshotData(res.data)
  }, [])

  useInterval(fetchUserHoldingSnapshotTotals, 10000)

  return snapshotsData
}

export function useUserHoldingSnapshots() {
  const [totalSnapshotsCount, setTotalSnapshotsCount] = useState(0)
  const [snapshots, setSnapshots] = useState<UserHoldingSnapshot[]>([])

  const fetchUserHoldingSnapshots = useCallback(async (limit?: number, offset?: number) => {
    const res = await UserService.fetchHoldingSnapshots(limit, offset)
    setTotalSnapshotsCount(res.data.total_count)
    setSnapshots(res.data.items)
  }, [])

  return {
    totalSnapshotsCount,
    snapshots,
    fetchUserHoldingSnapshots,
  }
}

export const useTransactions = (options?: TransactionsRequestParams) => {
  const [transactions, setTransactions] = useState<TransactionDataParams[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [totalCount, setTotalCount] = useState<number>(0)

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true)
      setError(null)

      try {
        const data = await UserService.getTransactions(options)

        const formattedTransactions: TransactionDataParams[] = data.items.map((transaction: TransactionDataParams) => ({
          ...transaction,
          created_at: formatDate(transaction.created_at),
          updated_at: formatDate(transaction.updated_at),
        }))
        setTransactions(formattedTransactions)
        setTotalCount(data.total_count)
      } catch (err) {
        setError('Failed to load transactions.')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchTransactions()
  }, [options])

  return { transactions, loading, error, totalCount }
}