import { useReserveFundChartData } from 'hooks/useStats'

import CardUi from 'components/UI/CardUi'
import FundChart from 'components/Charts/FundChart'

import styles from '../styles.module.scss'

const ReserveFundChart = () => {
  const data = useReserveFundChartData()

  return (
    <div className={styles.fundWrapper}>
      <CardUi>
        <p className={styles.statHeader}>Reserve Fund</p>
        <FundChart data={data} />
      </CardUi>
    </div>
  )
}

export default ReserveFundChart
