import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import APRChart from './APRChart'
import BTCFundingRateStats from 'pages/Statistic/BTCFundingRateStats'
import BTCMarginStats from './BTCMarginStats'
import BTCOIFundingRateChart from './BTCOIFundingRateChart'
import BTCVolumeFundingRateChart from './BTCVolumeFundingRateChart'
import BTCFundingRateChart from './BTCFundingRateChart'
import CustodianBalanceChart from './CustodianBalanceChart'
import USDaSupplyChart from './USDaSupplyChart'
import USDaPriceChart from './USDaPriceChart'
import ReserveFundChart from './ReserveFundChart'
import OpenPositionChart from './OpenPositionChart'

import MainContainerUi from 'components/UI/MainContainerUi'

import styles from './styles.module.scss'

const Statistic = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [location])

  return (
    <MainContainerUi>
      <p id="proof-of-reserves" className={styles.headerSeparator}>Proof-of-Reserves</p>
      <CustodianBalanceChart />
      <USDaSupplyChart />
      {/* <USDaPriceChart /> */}
      <ReserveFundChart />
      <OpenPositionChart />
      <div className={styles.topSection}>
        <BTCFundingRateStats />
        <APRChart />
      </div>

      <BTCMarginStats />

      <BTCOIFundingRateChart />
      <BTCVolumeFundingRateChart />
      <BTCFundingRateChart />

    </MainContainerUi>
  )
}

export default Statistic
