import React from 'react'

import type { Interval } from '@types'

import styles from './styles.module.scss'

type IntervalSelectorProps = {
  intervals: Interval[],
  selectedInterval: Interval,
  onIntervalChange: (interval: Interval) => void
}

const ChartIntervalSelector: React.FC<IntervalSelectorProps> = ({ intervals, selectedInterval, onIntervalChange }) => {
  return (
    <div className={styles.intervalSelector}>
      {intervals.map((interval) => (
        <div
          key={interval}
          onClick={() => onIntervalChange(interval)}
          className={`${styles.interval} ${selectedInterval === interval ? styles.active : ''}`}
        >
          {interval}
        </div>
      ))}
    </div>
  )
}

export default ChartIntervalSelector
