import { ReactNode } from 'react'

import MainContainerUi from 'components/UI/MainContainerUi'
import CardUi from 'components/UI/CardUi'
import LoadingBannerUi from 'components/UI/LoadingBannerUi'
import Stepper from 'components/UI/Stepper'

import styles from './styles.module.scss'

interface Props {
  title: string
  description: string
  children: ReactNode
  isLoading?: boolean;
  totalSteps: number;
  currentStep: number;
}

const StepWrapper = ({ title, description, isLoading = false, totalSteps, currentStep, children }: Props) => {
  return (
    <MainContainerUi>
      <h1>Sign Up</h1>
      <div className={styles.wrapper}>
        {isLoading && <LoadingBannerUi />}
        <CardUi>
          <div className={styles.stepWrapper}>
            <Stepper totalSteps={totalSteps} currentStep={currentStep} />
            <h5>{title}</h5>
            <p className={styles.description}>{description}</p>
            <div className={styles.content}>
              {children}
            </div>
          </div>
        </CardUi>
      </div>
    </MainContainerUi>
  )
}

export default StepWrapper