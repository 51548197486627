import ChangeNotificationEmail from 'pages/Settings/Forms/ChangeNotificationEmail'
import api from './api'

interface Enable2FaParams {
  code: string;
}

interface Disable2FaParams {
  code: string;
}

interface ChangePasswordParams {
  currentPassword: string;
  newPassword: string;
  code?: string
}

interface ChangeEmailParams {
  newEmail: string;
  currentEmailConfirmationCode: string;
  newEmailConfirmationCode: string;
  code?: string
}

interface ChangeNotificationEmailParams {
  email: string;
  confirmationCode: string;
  code?: string
}


interface SendNewConfirmationCodeParams {
  email: string
}

interface TransactionsRequestParams {
  limit?: number;
  offset?: number;
  type?: string;
}

const UserService = {
  getCurrentUser: async () => {
    const { data } = await api.get('/user')

    return data.data
  },
  setup2Fa: async () => {
    const { data } = await api.post('/user/setup-2fa')
    return data.data
  },
  enable2Fa: async ({ code }: Enable2FaParams): Promise<{ status: string }> => {
    const { data } = await api.post('/user/enable-2fa', {
      code: code,
    })
    return data
  },
  disable2Fa: async ({ code }: Disable2FaParams): Promise<{ status: string }> => {
    const { data } = await api.post('/user/disable-2fa', {
      code: code,
    })
    return data
  },
  changePassword: async ({ currentPassword, newPassword, code='' }: ChangePasswordParams): Promise<{ status: string }> => {
    const { data } = await api.post('/user/change-password', {
      current_password: currentPassword,
      new_password: newPassword,
      twofa_code: code,
    })
    return data
  },
  sendNewConfirmationCode: async ({ email }: SendNewConfirmationCodeParams): Promise<{ status: string }> => {
    const { data } = await api.post('/user/change-email/send-new-confirmation', {
      email: email,
    })
    return data
  },
  sendCurrentConfirmationCode: async (): Promise<{ status: string }> => {
    const { data } = await api.post('/user/change-email/send-current-confirmation')
    return data
  },
  sendNotificationConfirmationCode: async ({ email }: SendNewConfirmationCodeParams): Promise<{ status: string }> => {
    const { data } = await api.post('/user/change-notification-email/send-confirmation', {
      email: email,
    })
    return data
  },
  changeEmail: async ({ currentEmailConfirmationCode, newEmailConfirmationCode, newEmail, code='' }: ChangeEmailParams): Promise<{ status: string }> => {
    const { data } = await api.post('/user/change-email', {
      email: newEmail,
      current_email_confirmation_code: currentEmailConfirmationCode,
      new_email_confirmation_code: newEmailConfirmationCode,
      twofa_code: code,
    })
    return data
  },
  changeNotificationEmail: async ({ email, confirmationCode, code='' }: ChangeNotificationEmailParams): Promise<{ status: string }> => {
    const { data } = await api.post('/user/change-notification-email', {
      email,
      confirmation_code: confirmationCode,
      twofa_code: code,
    })
    return data
  },
  getSumsubAccessToken: async () => {
    const { data } = await api.post('/user/kyc/access-token')
    return data.data
  },
  fetchRedeemRequests: async () => {
    const { data } = await api.get('/user/redeem-requests')

    return data
  },
  applyForRewards: async () => {
    const { data } = await api.post('/user/apply-for-rewards')

    return data
  },
  fetchHoldingSnapshotsTotals: async () => {
    const { data } = await api.get('/user/holding-snapshots/totals')

    return data
  },
  fetchHoldingSnapshots: async (limit?: number, offset?: number) => {
    const { data } = await api.get('/user/holding-snapshots', { params: { limit, offset } })

    return data
  },
  getTransactions: async (params?: TransactionsRequestParams) => {
    const { data } = await api.get('/user/transactions', { params })

    return data.data
  },
}

export default UserService
