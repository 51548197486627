import React, { useState } from 'react'
import Step1 from './Steps/Step1'
import SuccessUi from 'components/UI/SuccessUi'

const Setup2Fa = () => {
  const [step, setStep] = useState(1)


  const handleNextStep = () => {
    setStep((prevStep) => Math.min(prevStep + 1, 5))
  }

  const renderStep = () => {
    switch (step) {
    case 1:
      return (
        <Step1 onNextStep={handleNextStep} />
      )
    case 2:
      return (
        <SuccessUi
          text="2FA disabled succesfully"
          link="/settings"
          linkText=" "
        />
      )
    default:
      return (
        <Step1 onNextStep={handleNextStep} />
      )
    }
  }

  return <div>{renderStep()}</div>
}

export default Setup2Fa
