import { useState, useMemo, useEffect } from 'react'
import { parseUnits, formatUnits } from 'ethers/lib/utils'

import { usePoolInfo, useCalculateSwapOutputAmount, useRouterAddress, useSwap } from 'hooks/Uniswap'
import { useTokenApprovalRequired, useApproveToken } from 'hooks/useToken'
import { useTokenBalance } from 'hooks/useBalance'

import { Token } from 'entities/token'

import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import PaymentInput from 'components/PaymentInput'
import SuccessUi from 'components/UI/SuccessUi'
import LoadingBannerUi from 'components/UI/LoadingBannerUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'

import { truncateFloatString } from 'utils/formatters'

import styles from './styles.module.scss'

interface Props {
  usdaToken: Token
  supportedTokens: Token[]
}

const UniswapForm = ({ usdaToken, supportedTokens }: Props) => {
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const calculateSwapOutputAmount = useCalculateSwapOutputAmount()

  const [inputToken, setInputToken] = useState(supportedTokens[0])
  const [outputToken, setOutputToken] = useState(usdaToken)
  const [inputAmount, setInputAmount] = useState('')
  const [outputAmount, setOutputAmount] = useState('')
  const [lastInputAmount, setLastInputAmount] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  const inputBalance = useTokenBalance(inputToken)
  const outputBalance = useTokenBalance(outputToken)

  const routerAddress = useRouterAddress()
  const { approvalRequired, updateApprovalRequired } = useTokenApprovalRequired(inputToken, routerAddress, inputAmount)
  const { processing: processingApproval, onApproveToken } = useApproveToken(inputToken)
  const { processing: processingSwap, onSwap } = useSwap()

  useEffect(() => {
    setInputToken(supportedTokens[0])
    setOutputToken(usdaToken)
  }, [usdaToken.address, JSON.stringify(supportedTokens)])

  useEffect(() => {
    if (!inputAmount || inputToken.parseUnits(inputAmount).lte(inputBalance)) {
      setError(null)
    } else {
      setError('Insufficient funds')
    }
  }, [inputAmount, inputToken.address, inputBalance.toString()])

  const { poolInfo } = usePoolInfo(inputToken, outputToken)
  const price = useMemo(() => {
    if (poolInfo.token0.toLowerCase() === inputToken.address.toLowerCase()) {
      return poolInfo.price.invert()
    }

    return poolInfo.price
  }, [poolInfo.token0, poolInfo.price, inputToken.address])

  const [inputTokens, outputTokens] = useMemo(() => {
    if (inputToken.address.toLowerCase() === usdaToken.address.toLowerCase()) {
      return [[usdaToken], supportedTokens]
    }

    return [supportedTokens, [usdaToken]]
  }, [inputToken.address, usdaToken.address, JSON.stringify(supportedTokens)])

  const canSwap = useMemo(() => {
    return !error && inputAmount && outputAmount && inputAmount != '0.0' && outputAmount != '0.0' && !processingSwap
  }, [error, inputAmount, outputAmount, processingSwap])

  const handleInputAmountChange = async (name: string, value: string) => {
    setInputAmount(truncateFloatString(value, inputToken.decimals))
    setLastInputAmount(value)
    if (!value) {
      setOutputAmount('')
      return
    }
    const amount = await calculateSwapOutputAmount(inputToken, outputToken, parseUnits(value, inputToken.decimals))
    setOutputAmount(formatUnits(amount, outputToken.decimals))
  }

  const handleOutputAmountChange = async (name: string, value: string) => {
    setOutputAmount(truncateFloatString(value, outputToken.decimals))
    setLastInputAmount(value)
    if (!value) {
      setInputAmount('')
      return
    }
    const amount = await calculateSwapOutputAmount(outputToken, inputToken, parseUnits(value, outputToken.decimals))
    setInputAmount(formatUnits(amount, inputToken.decimals))
  }

  const handleSwitchDirection = async () => {
    let inAmount = inputAmount
    let outAmount = outputAmount
    if (lastInputAmount) {
      if (inputAmount === lastInputAmount) {
        outAmount = lastInputAmount
        const amount = await calculateSwapOutputAmount(inputToken, outputToken, parseUnits(lastInputAmount, inputToken.decimals))
        inAmount = formatUnits(amount, outputToken.decimals)
      } else if (outputAmount === lastInputAmount) {
        inAmount = lastInputAmount
        const amount = await calculateSwapOutputAmount(outputToken, inputToken, parseUnits(lastInputAmount, outputToken.decimals))
        outAmount = formatUnits(amount, inputToken.decimals)
      }
    }

    setInputToken(outputToken)
    setInputAmount(inAmount)
    setOutputToken(inputToken)
    setOutputAmount(outAmount)
  }

  const handleApproveToken = async () => {
    if (processingApproval || !approvalRequired) {
      return
    }

    setLoadingText('Requesting approval for token swap...')
    await onApproveToken(routerAddress)
    await updateApprovalRequired()
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      setLoadingText('Follow the instructions in your wallet...')
      await onSwap(inputToken, outputToken, parseUnits(inputAmount, inputToken.decimals), parseUnits(outputAmount, outputToken.decimals))
      setIsSuccess(true)
      setInputAmount('')
      setOutputAmount('')
    } catch (error: any) {
      (error.code === 'ACTION_REJECTED') ? setError('User rejected the request') : setError('Wallet error')
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      {isLoading && <LoadingBannerUi text={loadingText} />}
      {isSuccess ?
        <SuccessUi
          text="The swap transaction was successful."
          link='/dashboard'
          linkText='Back to dashboard'
        />
        :
        <>
          <PaymentInput
            name="inputAmount"
            value={inputAmount}
            title="You pay"
            selectedToken={inputToken}
            tokens={inputTokens}
            balance={inputBalance}
            placeholder="0"
            isBaseCurrency
            // error={error}
            onChange={handleInputAmountChange}
            onTokenSelect={setInputToken}
            onSwitchDirection={handleSwitchDirection}
          />
          <PaymentInput
            name="outputAmount"
            value={outputAmount}
            title="You receive"
            selectedToken={outputToken}
            tokens={outputTokens}
            balance={outputBalance}
            placeholder="0"
            onChange={handleOutputAmountChange}
            onTokenSelect={setOutputToken}
          />
          <div className={styles.paymentInfo}>
            <div className={styles.price}>
              1 <span className={styles.currency}>{outputToken.symbol}</span>
              {` = ${truncateFloatString(price.format(), 6)} `}
              <span className={styles.currency}>{inputToken.symbol}</span>
            </div>
            <div className={styles.blockchainInfo}>
              <div className={styles.blockchainInfoItem}>
                <div className={styles.title}>Max. slippage</div>
                <div className={styles.value}>0.10%</div>
              </div>
              <div className={styles.blockchainInfoItem}>
                <div className={styles.title}>Network Fee</div>
                <div className={styles.value}>0.003 ETH</div>
              </div>
            </div>
          </div>
          {error && <ErrorBannerUi text={error} />}
          {(inputAmount && approvalRequired) ? (
            <SubmitBtnUi
              text="Approve"
              isLoading={processingApproval}
              disabled={processingApproval}
              onClick={handleApproveToken}
            />
          ) : (
            <SubmitBtnUi
              text="Swap"
              isLoading={isLoading}
              disabled={!canSwap}
              onClick={handleSubmit}
            />
          )}
        </>
      }
    </div>
  )
}

export default UniswapForm
