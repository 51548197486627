import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useUserDetails, useIsUserVerified } from 'state/user/hooks'

interface Props {
  children: ReactNode;
}

const ProtectedKycRoute = ({ children }: Props) => {
  const userDetails = useUserDetails()
  const isUserVerified = useIsUserVerified()

  if (!userDetails) {
    return null
  }

  if (!isUserVerified) {
    return (
      <Navigate
        to="/kyc-verification"
        state={{ error: 'Please log in to continue' }}
        replace
      />
    )
  }

  return <>{children}</>
}

export default ProtectedKycRoute
