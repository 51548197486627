import styles from './styles.module.scss'

interface Props {
  icon: string
  text: string
  disabled?: boolean
  isLoading?: boolean
  onClick?: () => void
}

const ButtonUi = ({ icon, text, disabled = false, isLoading = false, onClick }: Props) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  return (
    <div
      className={`${styles.wrapper} ${disabled ? styles.disabled : ''}`}
      onClick={handleClick}
    >
      <div className={styles.iconWrapper}>
        <img src={icon} alt="Icon" />
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default ButtonUi
