import { Interface } from '@ethersproject/abi'

import UNISWAP_ROUTER_ABI from './uniswapRouter.json'

const UNISWAP_ROUTER_INTERFACE = new Interface(UNISWAP_ROUTER_ABI)

export {
  UNISWAP_ROUTER_ABI,
  UNISWAP_ROUTER_INTERFACE,
}
