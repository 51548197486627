export default {
  binance: {
    name: 'Binance',
    endpoints: [
      '/fapi/v3/account',
    ],
  },
  bybit: {
    name: 'ByBit',
    endpoints: [
      '/v5/account/wallet-balance',
      '/v5/position/list',
    ],
  },
  deribit: {
    name: 'Deribit',
    endpoints: [
      '/private/get_positions',
      '/private/get_account_summaries',
    ],
  },
  okx: {
    name: 'Okx',
    endpoints: [
      '/api/v5/account/account-position-risk',
    ],
  },
} as {[key: string]: { name: string; endpoints: string[] }}