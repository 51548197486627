import { useState, useMemo, ChangeEvent } from 'react'

import { useTokenBalance } from 'hooks/useBalance'
import { useMintTestToken } from 'hooks/useTesting'

import { Token } from '@entities'

import styles from './styles.module.scss'

interface Props {
  token: Token
}

const MintToken = ({ token }: Props) => {
  const balance = useTokenBalance(token)
  const { processing, onMintTestToken } = useMintTestToken(token)
  const [amount, setAmount] = useState('')
  const pattern = useMemo(() => new RegExp(`^[0-9]*.?[0-9]{0,${token.decimals}}$`), [token.symbol])

  const canMint = useMemo(() => {
    return !processing && amount && amount != '0'
  }, [processing, amount])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!pattern.test(e.target.value)) {
      return
    }

    setAmount(e.target.value)
  }

  const handleMint = async () => {
    await onMintTestToken(amount)
    setAmount('')
  }

  return (
    <div className={styles.mintToken}>
      <div className={styles.title}>
        Mint {token.symbol}
      </div>
      <div className={styles.balance}>
        <span>Balance</span>
        <span>{token.formatUnits(balance)}</span>
      </div>
      <div className={styles.inputBox}>
        <input
          value={amount}
          onChange={handleInputChange}
        />
        <button disabled={!canMint} onClick={handleMint}>
          Mint
        </button>
      </div>
    </div>
  )
}

export default MintToken
