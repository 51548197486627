import { useCallback } from 'react'

import useInterval from '../../hooks/useInterval'
import {
  useUpdateStats,
} from './hooks'

export default function Updater() {
  const updateStats = useUpdateStats()

  const updateData = useCallback(async () => {
    await updateStats()
  }, [
    updateStats,
  ])

  useInterval(updateData, 10000)

  return null
}
