import { useEffect, ReactNode, MouseEvent, useRef } from 'react'

import CardUi from 'components/UI/CardUi'

import styles from './styles.module.scss'

interface Props {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
}

const ModalUi = ({ title, isOpen, children, onClose }: Props) => {
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const mouseDownTarget = useRef<EventTarget | null>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  if (!isOpen) {
    return null
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    mouseDownTarget.current = event.target
  }

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    if (mouseDownTarget.current === overlayRef.current && event.target === overlayRef.current) {
      onClose()
    }
  }

  return (
    <div
      ref={overlayRef}
      className={styles.overlay}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className={styles.modal}>
        <CardUi>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <p className={styles.title}>{title}</p>
              <div className={styles.closeButton} onClick={onClose}>
                &times;
              </div>
            </div>
            <div className={styles.content}>
              {children}
            </div>
          </div>
        </CardUi>
      </div>
    </div>
  )
}

export default ModalUi
