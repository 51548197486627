import { Interface } from '@ethersproject/abi'

import HOLDING_REWARDS_ABI from './holdingRewards.json'

const HOLDING_REWARDS_INTERFACE = new Interface(HOLDING_REWARDS_ABI)

export {
  HOLDING_REWARDS_ABI,
  HOLDING_REWARDS_INTERFACE,
}
