export const formatString = (str: string, startChars: number, endChars: number): string => {
  if (str.length <= startChars + endChars) {
    return str
  }

  const start = str.slice(0, startChars)
  const end = str.slice(-endChars)
  return `${start}...${end}`
}

export const replaceUnderscoresWithSpaces = (str: string): string => {
  return str.replace(/_/g, ' ').replace(/usda/gi, '').trim()
}
