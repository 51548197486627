import { NavLink } from 'react-router-dom'

import MainContainerUi from 'components/UI/MainContainerUi'
import SocialIcon from 'components/UI/SocialIcon'

import SOCIALS from 'constants/socials'

import LOGO_ICON_URL from 'assets/images/icons/logo.svg'

import styles from './styles.module.scss'

const Footer = () => {
  return (
    <MainContainerUi>
      <div className={styles.desktopWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.logoSection}>
            <div className={styles.logoWrapper}>
              <NavLink to="/"><img src={LOGO_ICON_URL} alt="" /></NavLink>
            </div>
          </div>
          <div className={styles.column}>
            <p className={styles.title}>Aegis</p>
            <a target='_blank' href="https://foundation.aegis.im">Company</a>
            <a target='_blank' href="https://test.aegis.im/transparency">Transparency</a>
            <a target='_blank' href="https://test.aegis.im/transparency#proof-of-reserves">Proof-of-Reserves</a>
            <a target='_blank' href="/#">Compliance</a>
            <a target='_blank' href="https://docs.aegis.im/">Docs</a>
          </div>
          <div className={styles.column}>
            <p className={styles.title}>App</p>
            <NavLink to="/mint">Mint</NavLink>
            <NavLink to="/">Earn</NavLink>
            <NavLink to="/swap">Swap</NavLink>
            <NavLink to="/">Govern</NavLink>
          </div>
          <div className={styles.columnSection}>
            <div className={styles.column}>
              <p className={styles.title}>Discord</p>
              <NavLink to="/">Join the discussion</NavLink>
            </div>
            <div className={styles.column}>
              <p className={styles.title}>Forum</p>
              <NavLink to="/">Visit our forum</NavLink>
            </div>
          </div>
          <div className={styles.socialSection}>
            <p className={styles.title}>Follow us</p>
            <div className={styles.socialList}>
              {SOCIALS.map(({ name, iconUrl, url}) => (
                <a target="_blank" key={name} href={url}>
                  <SocialIcon name={name} iconUrl={iconUrl} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mobileWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.logoSection}>
            <div className={styles.logoWrapper}>
              <NavLink to="/"><img src={LOGO_ICON_URL} alt="" /></NavLink>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <p className={styles.title}>Aegis</p>
              <a target='_blank' href="https://foundation.aegis.im">Company</a>
              <a target='_blank' href="https://test.aegis.im/transparency">Transparency</a>
              <a target='_blank' href="https://test.aegis.im/transparency#proof-of-reserves">Proof-of-Reserves</a>
              <a target='_blank' href="/#">Compliance</a>
              <a target='_blank' href="https://docs.aegis.im/">Docs</a>
            </div>
            <div className={styles.column}>
              <p className={styles.title}>App</p>
              <NavLink to="/mint">Mint</NavLink>
              <NavLink to="/">Earn</NavLink>
              <NavLink to="/swap">Swap</NavLink>
              <NavLink to="/">Govern</NavLink>
            </div>
          </div>

          <div className={styles.columnSection}>
            <div className={styles.column}>
              <p className={styles.title}>Discord</p>
              <NavLink to="/">Join the discussion</NavLink>
            </div>
            <div className={styles.column}>
              <p className={styles.title}>Forum</p>
              <NavLink to="/">Visit our forum</NavLink>
            </div>
          </div>
          <div className={styles.socialSection}>
            <p className={styles.title}>Follow us</p>
            <div className={styles.socialList}>
              {SOCIALS.map(({ name, iconUrl, url}) => (
                <a target="_blank" key={name} href={url}>
                  <SocialIcon name={name} iconUrl={iconUrl} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

    </MainContainerUi>
  )
}

export default Footer