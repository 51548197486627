import BackLinkUi from 'components/UI/BackLinkUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import CopierUi from 'components/UI/CopierUi'

import LOCK_ICON_URL from 'assets/images/icons/lockicon.svg'

import styles from './styles.module.scss'
import Stepper from 'components/UI/Stepper'

interface Props {
  secret: string | null;
  onNextStep: () => void;
  onPrevStep: () => void;
}

const Step3 = ({ onPrevStep, onNextStep, secret }: Props) => (
  <div className={styles.wrapper}>
    <Stepper totalSteps={4} currentStep={3} />
    <h6>Save this Backup Key in a secure location</h6>
    <div className={styles.iconWrapper}>
      <img src={LOCK_ICON_URL} alt="" />
    </div>
    {secret && <CopierUi text={secret} />}
    <p className={styles.description}>This Key will allow you to recover your Authenticator if you lose your phone. Otherwise, resetting Google Authenticator will take at least 7 days.</p>
    <SubmitBtnUi onClick={onNextStep} text="Next" />
    <BackLinkUi onClick={onPrevStep} text='Back' />
  </div>
)

export default Step3
