import styles from './styles.module.scss'
import Transaction from './Transaction'
import { useState } from 'react'

interface TransactionItem {
  id: string;
  hash: string;
  type: string;
  status: string;
  created_at: string;
  updated_at: string;
  data: {
    [key: string]: any;
  };
}

interface Props {
  items: TransactionItem[];
  totalCount: number;
  onPageChange: (newOffset: number) => void;
  limit: number;
}

const TransactionTableUi = ({ items, totalCount, onPageChange, limit }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)

  const totalPages = Math.ceil(totalCount / limit)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    onPageChange((page - 1) * limit)
  }

  if (items.length === 0) {
    return <div className={styles.noData}>No transactions</div>
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.desktopView}>
        <div className={styles.header}>
          <div className={styles.title}>TX Hash</div>
          <div className={styles.title}>Type</div>
          <div className={styles.title}>From</div>
          <div className={styles.title}>To</div>
          <div className={styles.title}>Value</div>
          <div className={`${styles.title} ${styles.right}`}>Date</div>
        </div>
        <div className={styles.tableWrapper}>
          {items.map((item) => (
            <div className={styles.items} key={item.id}>
              <Transaction transaction={item} />
            </div>
          ))}
        </div>
      </div>

      {totalPages > 1 && (
        <div className={styles.pagination}>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? styles.activePage : ''}
            >
              {page}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default TransactionTableUi
