import React from 'react'
import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'

interface Props {
  twoFaCode: string;
  handleTwoFaCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTwoFaSubmit: () => void;
  isLoading: boolean;
  error: string | null;
}

const TwoFactorVerification = ({
  twoFaCode,
  handleTwoFaCodeChange,
  handleTwoFaSubmit,
  isLoading,
  error,
}: Props) => {
  return (
    <>
      <InputUi
        label="Google 2FA Code"
        name="code"
        value={twoFaCode}
        type="text"
        placeholder="Enter Authenticator verification code"
        error={error}
        onChange={handleTwoFaCodeChange}
      />
      <SubmitBtnUi onClick={handleTwoFaSubmit} text="Next" isLoading={isLoading} disabled={isLoading} />
    </>
  )
}

export default TwoFactorVerification
