import { ChangeEvent } from 'react'

import styles from './styles.module.scss'

interface Props {
  label: string
  name: string
  value: string
  type?: string
  disabled?: boolean
  autoComplete?: string
  placeholder?: string
  error?: string | null
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const InputUi = ({
  label,
  name,
  value,
  type = 'text',
  disabled = false,
  autoComplete = 'one-time-code',
  placeholder = '',
  error = null,
  onChange,
}: Props) => {
  return (
    <div className={styles.inputWrapper}>
      <p className={styles.label}>{label}</p>
      <input
        type={type}
        name={name}
        className={styles.inputStyles}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        placeholder={placeholder}
      />
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  )
}

export default InputUi
