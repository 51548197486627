import { useState, useMemo } from 'react'
import moment from 'moment'
import { capitalize } from 'lodash'

import type { UserHoldingSnapshot } from '@types'

import { formatUSDa } from 'utils/formatters'

import styles from './styles.module.scss'

export interface Props {
  snap: UserHoldingSnapshot
}

const SnapshotWeekItem = ({ snap }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const startTime = moment(snap.start_time).utc()
  const lastUpdated = useMemo(() => {
    const lastSnapshotAt = moment(snap.next_snapshot_at).utc().subtract(8, 'hours')
    if (lastSnapshotAt.isSame(startTime)) {
      return '---'
    }
    return lastSnapshotAt.format('HH:mm, DD MMM, YYYY')
  }, [snap.start_time, snap.next_snapshot_at])

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const renderDaySnapshot = (day: number) => {
    const daySnapshot = snap.day_snapshots[day]

    return (
      <div key={day} className={styles.day}>
        <div className={styles.date}>
          {startTime.clone().add(day, 'days').format('DD MMM, YYYY')}
        </div>
        <div className={styles.dayInfo}>
          <div className={styles.balance}>
            <p className={styles.title}>AVG USDa Balance</p>
            <p className={styles.value}>
              {`${formatUSDa(daySnapshot.avg_balance)} USDa`}
            </p>
          </div>
          <div className={styles.reward}>
            <p className={styles.title}>Claim reward</p>
            <p className={styles.value}>
              {`${formatUSDa(daySnapshot.reward)} USDa`}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.summary} onClick={toggleExpand}>
        <div className={styles.leftSection}>
          <div className={styles.datesWrapper}>
            <div className={styles.status}>
              <span>{snap.status}</span>
            </div>
            <div className={styles.dates}>
              {startTime.format('DD MMM, YYYY')} - {moment(snap.end_time).utc().format('DD MMM, YYYY')}
            </div>
          </div>

          <div className={styles.amount}>
            <p className={styles.title}>Reward amount</p>
            <p className={styles.value}>
              {`${formatUSDa(snap.total_reward_amount)} USDa`}
            </p>
          </div>
          {/* <div className={styles.apr}>
            <p className={styles.title}>APR</p>
            <p className={styles.value}>
              {snap.apr}%
            </p>
          </div> */}
        </div>
        <div className={styles.arrow}>
          {expanded ? '▲' : '▼'}
        </div>
      </div>
      {expanded && (
        <div className={styles.details}>
          <div className={styles.leftSection}>
            {Object.keys(snap.day_snapshots).map((day: any) => renderDaySnapshot(day as number))}
          </div>
          <div className={styles.rightSection}>
            {/* <div className={styles.stat}>
              <p className={styles.title}>APR</p>
              <p className={styles.value}>
                {snap.apr}
              </p>
            </div> */}
            <div className={styles.stat}>
              <p className={styles.title}>Reward amount</p>
              <p className={styles.value}>
                {formatUSDa(snap.total_reward_amount)}
              </p>
            </div>
            <div className={styles.stat}>
              <p className={styles.title}>Status</p>
              <p className={styles.value}>
                {capitalize(snap.claim_reward_status)}
              </p>
            </div>
            <div className={styles.stat}>
              <p className={styles.title}>Last updated</p>
              <p className={styles.value}>
                {lastUpdated}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SnapshotWeekItem
