import { NavLink } from 'react-router-dom'
import MainContainerUi from 'components/UI/MainContainerUi'
import styles from './styles.module.scss'
import CardUi from 'components/UI/CardUi'

import SWAP_ICON_URL from 'assets/images/icons/swap.svg'
import EARN_ICON_URL from 'assets/images/icons/earn.svg'
import DASHBOARD_ICON_URL from 'assets/images/icons/dashboard.svg'
import GOVERNANCE_ICON_URL from 'assets/images/icons/governance.svg'
import { formatUSDa } from 'utils/formatters'
import { useApr } from 'state/stats/hooks'
import { useUSDAToken } from 'hooks/useToken'
import { useTokenBalance } from 'hooks/useBalance'


const Landing = () => {

  const apr = useApr()
  const usdaToken = useUSDAToken()
  const usdaBalance = useTokenBalance(usdaToken)

  return (
    <MainContainerUi>
      <div className={styles.aprValue}>{`${apr}%`}</div>
      <div className={styles.aprTitle}>USDa APR</div>
      <div className={styles.wrapper}>
        <CardUi>
          <div className={styles.mainSection}>
            <div className={styles.balanceWrapper}>
              <div className={styles.title}>Your Balance</div>
              <div className={styles.amountWrapper}>
                <p className={styles.value}>{usdaToken ? formatUSDa(usdaBalance) : '0'} <span className={styles.currency}>USDa</span></p>
              </div>
            </div>
            <div className={styles.linksWrapper}>
              <NavLink to='/dashboard' className={styles.cardLink}>
                <div className={styles.imgWrapper}>
                  <img src={DASHBOARD_ICON_URL} alt="" />
                </div>
                <p className={styles.name}>Dashboard</p>
              </NavLink>
              <NavLink to='/swap' className={styles.cardLink}>
                <div className={styles.imgWrapper}>
                  <img src={SWAP_ICON_URL} alt="" />
                </div>
                <p className={styles.name}>Swap</p>
              </NavLink>
              <NavLink to='/' className={styles.cardLink}>
                <div className={styles.imgWrapper}>
                  <img src={EARN_ICON_URL} alt="" />
                </div>
                <p className={styles.name}>Earn</p>
              </NavLink>
              <NavLink to='/' className={styles.cardLink}>
                <div className={styles.imgWrapper}>
                  <img src={GOVERNANCE_ICON_URL} alt="" />
                </div>
                <p className={styles.name}>Governance</p>
              </NavLink>
            </div>
            <div className={styles.additionalWrapper}>
              <p className={styles.header}>Change the way you own money</p>
              <div className={styles.linksTitle}>
                <p>Have a question? Visit our forum or ask the community</p>
              </div>
            </div>
          </div>
        </CardUi>
      </div>

    </MainContainerUi>
  )
}

export default Landing