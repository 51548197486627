import { useMemo } from 'react'
import moment from 'moment'
import Chart from 'react-apexcharts'

import type { APRChartData } from '@types'

import styles from './styles.module.scss'

interface Props {
  data: APRChartData[]
}

const ApyChart = ({ data }: Props) => {
  const series = useMemo(() => {
    let s: number[] = []
    for (let i = 0; i < 12; i++) {
      const t = moment().utc().startOf('year').add(i, 'months').valueOf()
      const d = data.find(({ time }) => time === t)
      s = [...s, d ? d.value : 0]
    }

    return [{
      name: 'APR',
      data: s,
    }]
  }, [JSON.stringify(data)])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      position: 'back',
      show: true,
      borderColor: '#414243',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#4a525e'],
    xaxis: {
      crosshairs: {
        show: false,
      },
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
      position: 'bottom',
      labels: {
        show: true,
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
        formatter: function (val) {
          return val + '%'
        },
      },
    },
    title: {
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444',
      },
    },
  }

  return (
    <div id="aprChart" className={styles.wrapper}>
      <Chart options={options} series={series} type="bar" height={380} />
    </div>
  )
}

export default ApyChart
