import { useState, ReactNode } from 'react'
import { useUserDetails, useIsUserMinter, useIsUserVerified, useIsUserHolder } from 'state/user/hooks'
import { useNavigate } from 'react-router-dom'

import CardUi from 'components/UI/CardUi'
import MainContainerUi from 'components/UI/MainContainerUi'
import ModalUi from 'components/UI/ModalUi'

import ChangeEmail from './Forms/ChangeEmail'
import ChangePassword from './Forms/ChangePassword'
import Option from './Option'
import Setup2Fa from './Forms/Setup2Fa'
import Disable2Fa from './Forms/Disable2Fa'

import styles from './styles.module.scss'
import ChangeNotificationEmail from './Forms/ChangeNotificationEmail'

const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ReactNode>(null)
  const [modalTitle, setModalTitle] = useState('')

  const userDetails = useUserDetails()
  const isUserMinter = useIsUserMinter()
  const isUserHolder = useIsUserHolder()
  const isUserVerified = useIsUserVerified()

  const navigate = useNavigate()

  const handleOpenModal = (title: string, content: ReactNode) => {
    setModalTitle(title)
    setModalContent(content)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setModalContent(null)
    setModalTitle('')
  }

  const handleKycVerification = () => {
    navigate('/kyc-verification')
  }

  return (
    <MainContainerUi>
      <h1>Settings</h1>
      <div className={styles.wrapper}>
        <CardUi>
          <h4>Account Information</h4>
          {isUserMinter && <Option
            title="E-mail address"
            description="Add a new email address to receive notifications about activity and system updates."
            email={userDetails?.email}
            buttonText="Change Email"
            onClick={() => handleOpenModal('Change Email', <ChangeEmail />)}
          />}

          {isUserMinter && <Option
            title="Password"
            description="Manage your password to secure your account and ensure safe access to functionalities."
            buttonText="Change Password"
            onClick={() => handleOpenModal('Change Password', <ChangePassword />)}
          />}

          {isUserHolder && <Option
            title="Notification email"
            email={userDetails?.notification_email}
            description="Add an email address to receive notifications about your account activity and security alerts."
            buttonText={userDetails.notification_email ? 'Change email' : 'Add email'}
            onClick={() => { handleOpenModal(userDetails.notification_email ? 'Change Email' : 'Add email', <ChangeNotificationEmail />) }}
          />}

          {userDetails?.twofa_enabled ? (
            <Option
              title="Remove Google Authenticator"
              description="Use the Authenticator to get verification codes for better security"
              buttonText="Disable 2FA"
              onClick={() => handleOpenModal('Remove Google Authenticator', <Disable2Fa />)}
            />
          ) : (
            <Option
              title="Google Authenticator (2FA)"
              description="Enable two-factor authentication to add an extra layer of security."
              buttonText="Manage 2FA"
              onClick={() => handleOpenModal('Google Authenticator (2FA)', <Setup2Fa />)}
            />
          )}
          {isUserMinter && !isUserVerified && <Option
            title="KYC"
            description="Please complete the verification process to unlock mint and redeem features and ensure compliance with platform regulations."
            buttonText="Verify"
            onClick={handleKycVerification}
          />}

          {isUserMinter && isUserVerified && <Option
            title="KYC"
            description="Your identity has been successfully verified. You are now eligible to use mint and redeem features."
            buttonText="Successfully verified"
            isDisabled={true}
          />}

          {/* <Option
            title="Data Portability"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
            buttonText="Request access"
            onClick={() => handleOpenModal('Request Data Portability', <div>Data Portability Form Component</div>)}
          /> */}
          <Option
            title="Data Privacy Portal"
            description="Learn more about how your data is used and protected."
            buttonText="Visit"
            onClick={() => console.log('click')}
          />
        </CardUi>
      </div>
      <ModalUi
        title={modalTitle}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        {modalContent}
      </ModalUi>
    </MainContainerUi>
  )
}

export default Settings
