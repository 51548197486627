import { useCallback  } from 'react'
import { useWeb3React } from '@web3-react/core'

import { useAppSelector, useAppDispatch } from 'state'
import { setAuthToken, setUserDetails, setBalanceNative } from 'state/user/reducer'

import { updateSelectedWallet } from './reducer'

export function useDisconnectWallet() {
  const dispatch = useAppDispatch()
  const { connector } = useWeb3React()

  return useCallback(async () => {
    if (connector && connector.deactivate) {
      await connector.deactivate()
    }

    await connector.resetState()
    dispatch(updateSelectedWallet(undefined))
    dispatch(setAuthToken(null))
    dispatch(setUserDetails(null))

    dispatch(setBalanceNative('0'))
  }, [connector, dispatch])
}

export function useSelectedWallet() {
  return useAppSelector(state => state.wallet.selectedWallet)
}
