import { useMemo } from 'react'
import Chart from 'react-apexcharts'
import moment from 'moment'
import { formatUnits } from 'ethers/lib/utils'

import type { USDaChartData } from '@types'

import styles from './styles.module.scss'

interface Props {
  data: USDaChartData[]
}

const generateFundData = () => {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ]
  const data = []
  let value = 0

  for (let i = 0; i < months.length; i++) {
    value += Math.random() * (5000000000 / months.length)
    data.push({
      x: months[i],
      y: value,
    })
  }

  return data
}

const FundChart = ({ data }: Props) => {
  const series = useMemo(() => {
    let s: {x: any, y: any}[] = []
    for (let i = 0; i < 12; i++) {
      const t = moment().utc().startOf('year').add(i, 'months')
      const d = data.find(({ time }) => time === t.valueOf())
      s = [...s, {
        x: t.format('MMM'),
        y: d ? formatUnits(d.value, 6) : 0,
      }]
    }

    return [
      {
        name: 'Fund',
        data: s,
      },
    ]
  }, [JSON.stringify(data)])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'stepline',
      colors: ['#725ad7'],
    },
    grid: {
      show: true,
      borderColor: '#414243',
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8'],
        },
        formatter: function (val) {
          return (val / 1_000_000).toFixed(2) + 'М'
        },
      },
      title: {
        text: 'Fund Value (in Billions)',
        style: {
          color: '#fff',
        },
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
      },
    },
    tooltip: {
      marker: {
        show: false,
      },
      shared: false,
      y: {
        formatter: function (val) {
          return (val / 1_000_000).toFixed(2) + 'M'
        },
      },
    },
  }

  return (
    <div className={styles.wrapper}>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  )
}

export default FundChart
