import { useMemo } from 'react'
import Chart from 'react-apexcharts'
import dayjs from 'dayjs'
import { formatUnits } from 'ethers/lib/utils'

import type { USDaChartData } from '@types'

import styles from './styles.module.scss'

interface Props {
  data: USDaChartData[]
}

const SupplyChart = ({ data }: Props) => {
  const series = useMemo(() => {
    return [{
      name: 'Supply',
      data: data.map(d => ({
        x: d.time,
        y: formatUnits(d.value, 18),
      })),
    }]
  }, [JSON.stringify(data)])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      colors: ['#725ad7'],
    },
    grid: {
      show: true,
      borderColor: '#414243',
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
        formatter: function (val) {
          return (val / 1_000_000).toFixed(2) + 'M'
        },
      },
      title: {
        text: 'Supply (in Billions)',
        style: {
          color: '#fff',
        },
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      type: 'datetime',
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,
        style: {
          fontSize: '11.5px',
          colors: ['#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8', '#B8B8B8'],
        },
      },
    },
    tooltip: {
      shared: false,
      marker: {
        show: false,
      },
      y: {
        formatter: function (val) {
          return (val / 1_000_000).toFixed(2) + 'M'
        },
      },
    },
  }

  return (
    <div className={styles.wrapper}>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  )
}

export default SupplyChart
