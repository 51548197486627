import { useState, useCallback, useEffect, useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

import { useActiveChainId } from 'state/network/hooks'

import { Token } from '../entities'
import { CURVE_PLAIN_POOL_ADDRESS } from '../constants'

import { computeCurvePoolAddressKey } from 'utils/computePoolAddress'

import { useCurvePlainPool } from './useContract'

export function usePoolAddress(tokenA: Token, tokenB: Token) {
  const key = computeCurvePoolAddressKey(tokenA, tokenB)
  const chainId = useActiveChainId()

  return useMemo(() => {
    return CURVE_PLAIN_POOL_ADDRESS[chainId][key]
  }, [chainId, key])
}

export function useCalculateSwapOutputAmount(address: string) {
  const contract = useCurvePlainPool(address)

  return useCallback(async (inputToken: Token, amount: BigNumber) => {
    if (!contract) {
      return BigNumber.from(0)
    }

    // Call may fail with very big amount
    try {
      const coin0 = await contract.coins(0)
      const [i, j] = coin0.toLowerCase() === inputToken.address.toLowerCase() ? [1, 0] : [0, 1]

      return await contract.get_dy(i, j, amount)
    } catch (error) {
      return BigNumber.from(0)
    }
  }, [contract])
}

export function useExchangeCurve(address: string) {
  const contract = useCurvePlainPool(address)
  const [processing, setProcessing] = useState(false)

  const onExchange = useCallback(async (tokenIn: Token, inputAmout: BigNumber, outputAmount: BigNumber) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const coin0 = await contract.coins(0)
      // Slippage 0.10%
      const amountOutMin = outputAmount.mul(9990).div(10000)
      const [i, j] = coin0.toLowerCase() === tokenIn.address.toLowerCase() ? [0, 1] : [1, 0]
      const tx = await contract['exchange(int128,int128,uint256,uint256)'](i, j, inputAmout, amountOutMin)
      await tx.wait()
    } catch (err) {
      console.error('Error exchanging', err)
    } finally {
      setProcessing(false)
    }
  }, [contract])

  return { processing, onExchange }
}

export function useDynamicFee(address: string, tokenA: Token, tokenB: Token) {
  const contract = useCurvePlainPool(address)
  const [fee, setFee] = useState(BigNumber.from(0))

  const updateFee = useCallback(async () => {
    if (!contract) {
      setFee(BigNumber.from(0))
      return
    }

    const coin0 = await contract.coins(0)
    const [i, j] = coin0.toLowerCase() === tokenA.address.toLowerCase() ? [0, 1] : [1, 0]
    const f = await contract.dynamic_fee(i, j)
    setFee(f)
  }, [contract, tokenA, tokenB])

  useEffect(() => {
    updateFee()
  }, [updateFee])

  return fee
}
