import React from 'react'
import SPINNER_ICON_URL from 'assets/images/icons/spinner.svg'

import styles from './styles.module.scss'

interface Props {
  text?: string;
}

const LoadingBannerUi = ({ text = 'Follow the instructions in your wallet' }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgWrapper}>
        <img src={SPINNER_ICON_URL} alt="Loading spinner" />
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default LoadingBannerUi
