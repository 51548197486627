import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useNavigate } from 'react-router-dom'

import { Connection, getConnections } from 'web3/connection'
import { ErrorCode } from 'web3/connection/utils'

import { useAppDispatch } from 'state'
import { updateSelectedWallet } from 'state/wallet/reducer'

import { didUserWalletReject } from 'utils/web3'

import Option from './Option'

import styles from './styles.module.scss'

interface Props {
  onConnect?: () => void
}

const ConnectButtons = ({ onConnect }: Props) => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const connected = !!account

  const [pendingConnection, setPendingConnection] = useState<Connection | undefined>()
  const [, setPendingError] = useState<any>()
  const [didConnect, setDidConnect] = useState(false)

  const connections = getConnections()

  useEffect(() => {
    return () => {
      setPendingError(undefined)
    }
  }, [])

  useEffect(() => {
    if (didConnect && onConnect) {
      onConnect()
      setDidConnect(false)
    }
  }, [didConnect, onConnect])

  const tryActivation = useCallback(
    async (connection: Connection) => {
      if (connection.overrideActivate?.()) return

      try {
        setPendingConnection(connection)
        setPendingError(undefined)

        await connection.connector.activate()
        dispatch(updateSelectedWallet(connection.type))

        setPendingConnection(undefined)
        setDidConnect(true)
      } catch (error: any) {
        if (didUserWalletReject(connection, error)) {
          setPendingConnection(undefined)
        } else if (error?.code !== ErrorCode.MM_ALREADY_PENDING) {
          console.debug(`web3-react connection error: ${error}`)
          setPendingError(error.message)
        }
      }
    },
    [dispatch, navigate],
  )

  return (
    <div className={styles.walletsGrid}>
      {pendingConnection?.type && <div className={styles.gridOverlay}>Follow the instructions in your wallet...</div>}
      {connections
        .filter((c) => c.shouldDisplay())
        .map((connection) => (
          <Option
            key={connection.getName()}
            connection={connection}
            activate={() => tryActivation(connection)}
            pendingConnectionType={pendingConnection?.type}
          />
        ))}
    </div>
  )
}

export default ConnectButtons
