import { ChainId } from 'web3/chains'
import { formatString, replaceUnderscoresWithSpaces } from 'utils/formatString'
import { getMinterAddress, getRewardAddress, getTokenDecimalsByAddress, getTokenSymbolByAddress } from 'utils/tokenInfo'
import styles from './styles.module.scss'
import { formatUSDa } from 'utils/formatters'
import { formatNumber } from 'utils/formatNumber'

interface TransactionProps {
  transaction: {
    id: string;
    hash: string;
    type: string;
    status: string;
    created_at: string;
    updated_at: string;
    data: {
      [key: string]: any;
    };
  };
}

const Transaction = ({ transaction }: TransactionProps) => {
  const { type, hash, created_at, data } = transaction

  const chainId = ChainId.AEGISNET
  const minterAddress = getMinterAddress(chainId) ?? ''
  const rewardAddress = getRewardAddress(chainId) ?? ''

  const assetName = getTokenSymbolByAddress(data.asset, chainId)
  const assetDecimals = getTokenDecimalsByAddress(data.asset, chainId)

  const renderTransactionDetails = () => {
    switch (type) {
    case 'transfer_usda':
      return (
        <>
          <div className={styles.desktopRowWrapper}>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
            <div><span className={styles.type}>TRANSFER USDa</span></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.from}`}>{formatString(data.from, 6, 8)}</a></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.to}`}>{formatString(data.to, 6, 8)}</a></div>
            <div className={styles.amount}>{formatUSDa(data.amount)} <span className={styles.currency}>USDa</span></div>
            <div className={styles.date}>{created_at}</div>
          </div>
          <div className={styles.mobileRowWrapper}>
            <div className={styles.rowList}>
              <p className={styles.title}>TX Hash</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Type</p>
              <div className={styles.content}>
                <div><span className={styles.type}>TRANSFER USDa</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>From</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.from}`}>{formatString(data.from, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>To</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.to}`}>{formatString(data.to, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Value</p>
              <div className={styles.content}>
                <div className={styles.amount}>{formatUSDa(data.amount)} <span className={styles.currency}>USDa</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Date</p>
              <div className={styles.content}>
                <div className={styles.date}>{created_at}</div>
              </div>
            </div>
          </div>
        </>
      )
    case 'transfer_supported_asset':
      return (
        <>
          <div className={styles.desktopRowWrapper}>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
            <div><span className={styles.type}>TRANSFER ASSET</span></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.from}`}>{formatString(data.from, 6, 8)}</a></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.to}`}>{formatString(data.to, 6, 8)}</a></div>
            <div className={styles.amount}>{formatNumber(data.amount, assetDecimals, 2)} <span className={styles.currency}>{assetName}</span></div>
            <div className={styles.date}>{created_at}</div>
          </div>
          <div className={styles.mobileRowWrapper}>
            <div className={styles.rowList}>
              <p className={styles.title}>TX Hash</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Type</p>
              <div className={styles.content}>
                <div><span className={styles.type}>TRANSFER ASSET</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>From</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.from}`}>{formatString(data.from, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>To</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.to}`}>{formatString(data.to, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Value</p>
              <div className={styles.content}>
                <div className={styles.amount}>{formatNumber(data.amount, assetDecimals, 2)} <span className={styles.currency}>{assetName}</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Date</p>
              <div className={styles.content}>
                <div className={styles.date}>{created_at}</div>
              </div>
            </div>
          </div>
        </>
      )
    case 'mint_usda':
      return (
        <>
          <div className={styles.desktopRowWrapper}>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
            <div><span className={styles.type}>MINT</span></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.minter}`}>{formatString(data.minter, 6, 8)}</a></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${minterAddress}`}>{formatString(minterAddress, 6, 8)}</a></div>
            <div className={styles.amount}>{formatUSDa(data.usda_amount)} <span className={styles.currency}>USDa</span></div>
            <div className={styles.date}>{created_at}</div>
          </div>
          <div className={styles.mobileRowWrapper}>
            <div className={styles.rowList}>
              <p className={styles.title}>TX Hash</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Type</p>
              <div className={styles.content}>
                <div><span className={styles.type}>MINT</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>From</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.minter}`}>{formatString(data.minter, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>To</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${minterAddress}`}>{formatString(minterAddress, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Value</p>
              <div className={styles.content}>
                <div className={styles.amount}>{formatUSDa(data.usda_amount)} <span className={styles.currency}>USDa</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Date</p>
              <div className={styles.content}>
                <div className={styles.date}>{created_at}</div>
              </div>
            </div>
          </div>
        </>
      )
    case 'redeem_usda':
      return (
        <>
          <div className={styles.desktopRowWrapper}>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
            <div><span className={styles.type}>REDEEM</span></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.redeemer}`}>{formatString(data.redeemer, 6, 8)}</a></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${minterAddress}`}>{formatString(minterAddress, 6, 8)}</a></div>
            <div className={styles.amount}>{formatUSDa(data.usda_amount)} <span className={styles.currency}>USDa</span></div>
            <div className={styles.date}>{created_at}</div>
          </div>
          <div className={styles.mobileRowWrapper}>
            <div className={styles.rowList}>
              <p className={styles.title}>TX Hash</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Type</p>
              <div className={styles.content}>
                <div><span className={styles.type}>REDEEM</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>From</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.redeemer}`}>{formatString(data.redeemer, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>To</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${minterAddress}`}>{formatString(minterAddress, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Value</p>
              <div className={styles.content}>
                <div className={styles.amount}>{formatUSDa(data.usda_amount)} <span className={styles.currency}>USDa</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Date</p>
              <div className={styles.content}>
                <div className={styles.date}>{created_at}</div>
              </div>
            </div>
          </div>
        </>
      )
    case 'claim_rewards':
      return (
        <>
          <div className={styles.desktopRowWrapper}>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
            <div><span className={styles.type}>CLAIM</span></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.wallet}`}>{formatString(data.wallet, 6, 8)}</a></div>
            <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${rewardAddress}`}>{formatString(rewardAddress, 6, 8)}</a></div>
            <div className={styles.amount}>{formatUSDa(data.total_amount)} <span className={styles.currency}>USDa</span></div>
            <div className={styles.date}>{created_at}</div>
          </div>
          <div className={styles.mobileRowWrapper}>
            <div className={styles.rowList}>
              <p className={styles.title}>TX Hash</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${hash}`}>{formatString(hash, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Type</p>
              <div className={styles.content}>
                <div><span className={styles.type}>CLAIM</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>From</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${data.wallet}`}>{formatString(data.wallet, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>To</p>
              <div className={styles.content}>
                <div><a className={styles.link} target='_blank' href={`https://etherscan.io/tx/${rewardAddress}`}>{formatString(rewardAddress, 6, 8)}</a></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Value</p>
              <div className={styles.content}>
                <div className={styles.amount}>{formatUSDa(data.total_amount)} <span className={styles.currency}>USDa</span></div>
              </div>
            </div>
            <div className={styles.rowList}>
              <p className={styles.title}>Date</p>
              <div className={styles.content}>
                <div className={styles.date}>{created_at}</div>
              </div>
            </div>
          </div>
        </>
      )
    default:
      return (
        <div>Unknown transaction type</div>
      )
    }
  }

  return (
    renderTransactionDetails()
  )
}

export default Transaction
