import React, { useState } from 'react'
import styles from './styles.module.scss'

interface SelectUiProps<T> {
  items: T[];
  onSelect: (item: T) => void;
  renderItem: (item: T) => React.ReactNode;
  placeholder?: string;
  error?: string | null;
}

const SelectUi = <T,>({ items, onSelect, renderItem, placeholder, error }: SelectUiProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<T | null>(null)

  const handleSelect = (item: T) => {
    setSelectedItem(item)
    onSelect(item)
    setIsOpen(false)
  }

  return (
    <div className={`${styles.selectWrapper} ${isOpen ? styles.isOpen : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <div className={styles.selectHeader}>
        {selectedItem ? renderItem(selectedItem) : <span className={styles.placeholder}>{placeholder}</span>}
      </div>
      {isOpen && (
        <ul className={styles.selectList}>
          {items.map((item, index) => (
            <li
              key={index}
              className={styles.selectListItem}
              onClick={() => handleSelect(item)}
            >
              {renderItem(item)}
            </li>
          ))}
        </ul>
      )}
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  )
}

export default SelectUi
