const changePasswordValidator = {
  oldPassword: {
    required: true,
    errorMessage: 'Old password is required',
  },
  newPassword: {
    required: true,
    minLength: 6,
    errorMessage: 'New password must be at least 6 characters long',
  },
  confirmNewPassword: {
    required: true,
    custom: (values: any) => values.newPassword === values.confirmNewPassword,
    errorMessage: 'New password and confirm new password do not match',
  },
}

export default changePasswordValidator
