import { Interface } from '@ethersproject/abi'

import UNISWAP_FACTORY_ABI from './uniswapFactory.json'

const UNISWAP_FACTORY_INTERFACE = new Interface(UNISWAP_FACTORY_ABI)

export {
  UNISWAP_FACTORY_ABI,
  UNISWAP_FACTORY_INTERFACE,
}
