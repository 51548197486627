import { useCallback } from 'react'

import useInterval from '../../hooks/useInterval'
import {
  useUpdateUserDetails,
  useUpdateBalanceNative,
} from './hooks'

export default function Updater() {
  const updateUserDetails = useUpdateUserDetails()
  const updateBalanceNative = useUpdateBalanceNative()

  const updateData = useCallback(async () => {
    await updateUserDetails()
    await updateBalanceNative()
  }, [
    updateUserDetails,
    updateBalanceNative,
  ])

  useInterval(updateData, 10000)

  return null
}
