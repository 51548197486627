import { useAPRChartData } from 'hooks/useStats'

import CardUi from 'components/UI/CardUi'
import ApyChart from 'components/Charts/ApyChart'

import styles from '../styles.module.scss'

const APRChart = () => {
  const data = useAPRChartData()

  return (
    <div className={styles.apyWrapper}>
      <CardUi>
        <p className={styles.statHeader}>APY Panel</p>
        <ApyChart data={data} />
      </CardUi>
    </div>
  )
}

export default APRChart
