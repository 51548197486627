import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from 'ethers/lib/utils'

export class Price {
  #value: string
  #decimals: number

  constructor(value: string, decimals?: number) {
    this.#value = value
    if (decimals !== undefined) {
      this.#decimals = decimals
    } else {
      this.#decimals = 6
    }
  }

  get decimals() {
    return this.#decimals
  }

  get asBigNumber() {
    return BigNumber.from(this.#value)
  }

  invert() {
    if (this.#value === '0') {
      return this
    }
    return new Price(BigNumber.from(10).pow(this.decimals*2).div(this.asBigNumber).toString(), this.#decimals)
  }

  format() {
    return formatUnits(this.#value, this.#decimals)
  }

  static from(value: string) {
    return new Price(value)
  }
}

export const ZeroPrice = Price.from('0')