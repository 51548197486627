import React, { useState, useMemo } from 'react'
import ButtonUi from 'components/UI/ButtonUi'
import { useTransactions, useUserRedeemRequests } from 'hooks/useUser'
import { useUSDAToken, useSupportedTokens } from 'hooks/useToken'
import DOWNLOAD_ICON_URL from 'assets/images/icons/download.svg'
import { getMinterAddress, getRewardAddress, getTokenDecimalsByAddress, getTokenSymbolByAddress } from 'utils/tokenInfo'
import { formatUSDa } from 'utils/formatters'
import { formatNumber } from 'utils/formatNumber'
import { ChainId } from 'web3/chains'
import { downloadCSV } from 'utils/exportCSV'
import { Price } from '@entities'
import { formatTimestamp } from 'utils/formatDate'

interface DownloadButtonProps {
  exportType: 'all' | 'mint' | 'redeem' | 'claim'
  fileName?: string
}

const DownloadButton = ({ exportType, fileName }: DownloadButtonProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const limit = 1000
  const offset = 0

  const allOptions = useMemo(() => ({ limit, offset }), [limit, offset])
  const mintOptions = useMemo(() => ({ limit, offset, type: 'mint_usda' }), [limit, offset])
  const claimOptions = useMemo(() => ({ limit, offset, type: 'claim_rewards' }), [limit, offset])

  const { transactions: allTransactions } = useTransactions(allOptions)
  const { transactions: mintTransactions } = useTransactions(mintOptions)
  const { transactions: claimTransactions } = useTransactions(claimOptions)

  const redeemRequests = useUserRedeemRequests()
  const usdaToken = useUSDAToken()
  const tokens = useSupportedTokens()

  const chainId = ChainId.AEGISNET

  const formatTransaction = (transaction: any) => {
    const { type, hash, created_at, data } = transaction

    const baseDetails = {
      hash,
      date: created_at,
      type,
    }

    switch (type) {
    case 'transfer_usda':
      return {
        ...baseDetails,
        from: data.from || '',
        to: data.to || '',
        amount: formatUSDa(data.amount) || '',
        asset: 'USDA',
      }
    case 'transfer_supported_asset': {
      const assetName = getTokenSymbolByAddress(data.asset, chainId)
      const assetDecimals = getTokenDecimalsByAddress(data.asset, chainId)
      return {
        ...baseDetails,
        from: data.from || '',
        to: data.to || '',
        amount: formatNumber(data.amount, assetDecimals, 6) || '',
        asset: assetName || '',
      }
    }
    case 'mint_usda': {
      const minterAddress = getMinterAddress(chainId) ?? ''
      return {
        ...baseDetails,
        from: data.minter || '',
        to: minterAddress || '',
        amount: formatUSDa(data.usda_amount) || '',
        asset: 'USDA',
      }
    }
    case 'redeem_usda': {
      const minterAddress = getMinterAddress(chainId) ?? ''
      return {
        ...baseDetails,
        from: data.redeemer || '',
        to: minterAddress || '',
        amount: formatUSDa(data.usda_amount) || '',
        asset: 'USDA',
      }
    }
    case 'claim_rewards': {
      const rewardAddress = getRewardAddress(chainId) ?? ''
      return {
        ...baseDetails,
        from: data.wallet || '',
        to: rewardAddress || '',
        amount: formatUSDa(data.total_amount) || '',
        asset: 'USDA',
      }
    }
    default:
      return baseDetails
    }
  }
  const formatRedeemRequests = (requests: any[], usdaToken: any, tokens: any[]) => {
    return requests.map(req => {
      const collateralToken = tokens.find(t => t.address.toLowerCase() === req.details.collateral_asset.toLowerCase())
      if (!collateralToken) return null

      return {
        'Request ID': req.request_id,
        'Collateral Amount': `${collateralToken.formatUnits(req.details.collateral_amount)}`,
        'Collateral asset': collateralToken.symbol,
        'USDa Amount': `${usdaToken.formatUnits(req.details.usda_amount)}`,
        'USDa Price': `${Price.from(req.details.price).invert().format()}`,
        'Status': req.status,
        'Created at': formatTimestamp(req.created_at),
        'Updated at': formatTimestamp(req.updated_at),
      }
    }).filter(req => req !== null) as Record<string, any>[]
  }

  const handleDownload = async () => {
    setIsLoading(true)

    let formattedData: Record<string, any>[] = []

    if (exportType === 'redeem') {
      formattedData = formatRedeemRequests(redeemRequests, usdaToken, tokens)
    } else {
      const transactions = exportType === 'mint' ? mintTransactions :
        exportType === 'claim' ? claimTransactions :
          allTransactions

      if (transactions && transactions.length > 0) {
        formattedData = transactions.map(formatTransaction)
      }
    }

    if (formattedData.length > 0) {
      const csvFileName = fileName || `${exportType}_transactions`
      downloadCSV(formattedData, csvFileName)
    }

    setIsLoading(false)
  }

  return (
    <ButtonUi
      icon={DOWNLOAD_ICON_URL}
      text={`Download ${exportType} data`}
      onClick={handleDownload}
      disabled={isLoading}
    />
  )
}

export default DownloadButton
