import { useEffect, ChangeEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import useForm from 'hooks/useForm'

import InputUi from 'components/UI/InputUi'
import SubmitBtnUi from 'components/UI/SubmitBtnUi'
import ErrorBannerUi from 'components/UI/ErrorBannerUi'

import signUpValidator from 'validators/signUpValidators'

import type { FormValues } from '../../types'

import StepWrapper from '../StepWrapper'

interface Props {
  values: FormValues
  onNextStep: () => void;
  inputChangeHandler: (input: string) => (e: ChangeEvent<HTMLInputElement>) => void;
}

const Step2 = ({ values, onNextStep, inputChangeHandler }: Props) => {
  const [error, setError] = useState<string>('')
  const [searchParams] = useSearchParams()

  const { values: formValues, errors, validate, setValues } = useForm(values, signUpValidator)

  useEffect(() => {
    const rawEmail = searchParams.get('email')
    const email = rawEmail ? rawEmail.replace(/ /g, '+') : ''
    const confirmationCode = searchParams.get('code') ? decodeURIComponent(searchParams.get('code') || '') : ''

    if (email && confirmationCode) {
      const emailValidator = signUpValidator.email
      if (emailValidator.pattern && !emailValidator.pattern.test(email)) {
        setError(emailValidator.errorMessage)
      } else {
        inputChangeHandler('email')({ target: { value: email } } as ChangeEvent<HTMLInputElement>)
        inputChangeHandler('confirmationCode')({ target: { value: confirmationCode } } as ChangeEvent<HTMLInputElement>)
      }
    } else {
      setError('Invalid url!')
    }
  }, [searchParams, inputChangeHandler])



  useEffect(() => {
    setValues(values)
  }, [values, setValues])

  const handleNextStep = () => {
    if (validate()) {
      onNextStep()
    }
  }

  return (
    <StepWrapper title="Create a password" description="This password will be used when logging in via email and password" totalSteps={3} currentStep={2}>
      {error && <ErrorBannerUi text={error} />}
      <div>
        <InputUi
          label="Email"
          name="email"
          type="email"
          value={formValues.email}
          onChange={inputChangeHandler('email')}
          disabled={true}
          error={errors.email}
        />
        <InputUi
          label="Password"
          name="password"
          type="password"
          value={formValues.password}
          onChange={inputChangeHandler('password')}
          error={errors.password}
        />
        <InputUi
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={formValues.confirmPassword}
          onChange={inputChangeHandler('confirmPassword')}
          error={errors.confirmPassword}
        />
        <SubmitBtnUi text="Next" onClick={handleNextStep} isLoading={false} disabled={false} />
      </div>
    </StepWrapper>
  )
}

export default Step2
