import { useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

import TestingService from 'API/TestingService'

import { Token } from '@entities'
import type { RedeemRequest, HoldingSnapshot } from '@types'

import useInterval from './useInterval'
import { useERC20Token, useAegisMinting, useHoldingRewards } from './useContract'
import { ethers } from 'ethers'

export function useMintTestToken(token: Token) {
  const { account } = useWeb3React()
  const contract = useERC20Token(token.address)
  const [processing, setProcessing] = useState(false)

  const onMintTestToken = useCallback(async (amount: string) => {
    if (!contract || !account) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.mint(account, token.parseUnits(amount))
      await tx.wait()
    } catch (error) {
      console.error(`Error minting test tokens ${error}`)
    } finally {
      setProcessing(false)
    }
  }, [account, contract])

  return { processing, onMintTestToken }
}

export function useRedeemRequests() {
  const [requests, setRequests] = useState<RedeemRequest[]>([])

  const fetchRedeemRequests = useCallback(async () => {
    const res = await TestingService.fetchRedeemRequests()

    setRequests(res.data)
  }, [])

  useInterval(fetchRedeemRequests, 10000)

  return requests
}

export function useDepositRedeemRequest() {
  const contract = useAegisMinting()
  const [processing, setProcessing] = useState(false)

  const onDepositRedeem = useCallback(async (requestId: string, asset: string, amount: string) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.depositRedeem(requestId, asset, amount)
      await tx.wait()
    } catch (error) {
      console.error(`Error depositing redeem request ${error}`)
    } finally {
      setProcessing(false)
    }
  }, [contract])

  return { processing, onDepositRedeem }
}

export function useGetTestETH() {
  const [processing, setProcessing] = useState(false)

  const onGetTestETH = useCallback(async (address: string) => {
    setProcessing(true)

    try {
      await TestingService.getTestETH(address)
    } catch (error) {
      console.error(`Error getting test ETH ${error}`)
    } finally {
      setProcessing(false)
    }
  }, [])

  return { processing, onGetTestETH }
}

export function useHoldingSnapshots() {
  const [requests, setRequests] = useState<HoldingSnapshot[]>([])

  const fetchHoldingSnapshots = useCallback(async () => {
    const res = await TestingService.fetchHoldingSnapshots()

    setRequests(res.data)
  }, [])

  useInterval(fetchHoldingSnapshots, 10000)

  return requests
}

export function useDepositIncome() {
  const contract = useAegisMinting()
  const [processing, setProcessing] = useState(false)

  const onDepositIncome = useCallback(async (order: any, signature: string) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.depositIncome({
        orderType: order.order_type,
        benefactor: order.benefactor,
        beneficiary: order.beneficiary,
        collateralAmount: order.collateral_amount,
        collateralAsset: order.collateral_asset,
        expiry: order.expiry,
        usdaAmount: order.usda_amount,
        additionalData: order.additional_data,
      }, signature)
      await tx.wait()
    } catch (error) {
      console.error(`Error depositing income ${error}`)
    } finally {
      setProcessing(false)
    }
  }, [contract])

  return { processing, onDepositIncome }
}

export function useFinalizeRewards() {
  const contract = useHoldingRewards()
  const [processing, setProcessing] = useState(false)

  const onFinalizeRewards = useCallback(async (id: string) => {
    if (!contract) {
      return
    }

    setProcessing(true)
    try {
      const tx = await contract.finalizeRewards(ethers.utils.formatBytes32String(id), 0)
      await tx.wait()
    } catch (error) {
      console.error(`Error finalizing holding snapshot ${error}`)
    } finally {
      setProcessing(true)
    }
  }, [contract])

  return { processing, onFinalizeRewards }
}
